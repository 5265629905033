import { Route, Routes } from "react-router-dom";
import { Page as ExperimentalPage } from "components/experimental/page";
import { ManageUsers } from "components/users";
import { useIsAdmin } from "hooks/settings";
import { VideoLabellingPage } from "components/videoLabelling/page";

export const AdminRoutes = () => {
  const isAdmin = useIsAdmin();
  return isAdmin ? (
    <Routes>
      <Route path="/users" element={<ManageUsers />} />
      <Route path="/experimental" element={<ExperimentalPage />} />
      <Route path="/video-labelling" element={<VideoLabellingPage />} />
    </Routes>
  ) : null;
};
