import { ArrowForward } from "@mui/icons-material";
import { Button, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";
import { ProductGroup, SteelGrade } from "src/store/api/generatedApi";

import {
  MaterialMass,
  CandidateBasket,
  CandidateMix,
  useCandidateMixes,
} from "./useCandidateMixes";
import { MassCell } from "./MassCell";
import { useMiscParams, useProductionSchedule } from "contexts/search/provider";
import { sorted } from "helpers";
import { Period } from "hooks/periodIndex";
import { PriceCell } from "./PriceCell";

type MixRow = {
  variant: "mix";
  period: Period;
  id: string;
  mixId: number;
  mixName: string;
  productGroup: ProductGroup;
  steelGrades: SteelGrade[];
  basket: CandidateBasket;
  basketCount: number;
  numberOfHeats: number;
  totalCost: number;
  pricePerTonne: number;
  dri: CandidateBasket;
};

type BasketRow = {
  variant: "basket";
  id: string;
  position: number;
  basket: CandidateBasket;
};

type TotalRow = {
  variant: "total";
  id: string;
  total: CandidateBasket;
};

type Row = MixRow | BasketRow | TotalRow;

const deployedMixToMixRow = (deployedMix: CandidateMix): MixRow => {
  return {
    variant: "mix",
    numberOfHeats: deployedMix.numberOfHeats,
    period: deployedMix.period,
    id: `mix.${deployedMix.mixId}`,
    mixId: deployedMix.mixId,
    mixName: deployedMix.mixName,
    steelGrades: deployedMix.steelGrades,
    productGroup: deployedMix.productGroup,
    basket: deployedMix.baskets[0]!,
    basketCount: deployedMix.baskets.length,
    pricePerTonne: deployedMix.pricePerTonne,
    totalCost:
      Number(deployedMix.pricePerTonne.toFixed(2)) *
      deployedMix.targetTappedMass *
      deployedMix.numberOfHeats,
    dri: deployedMix.dri,
  };
};

const deployedMixToTotalRow = (deployedMix: CandidateMix): TotalRow => {
  return {
    variant: "total",
    id: `total.${deployedMix.mixId}`,
    total: deployedMix.totals,
  };
};

const deployedMixToBasketRows = (deployedMix: CandidateMix): BasketRow[] => {
  return deployedMix.baskets
    .map((basket, index) => ({
      variant: "basket" as BasketRow["variant"],
      id: `${deployedMix.mixId}.${index}`,
      position: index + 1,
      basket,
    }))
    .slice(1);
};

const flattenMixToRows = (
  deployedMix: CandidateMix
): [MixRow, ...BasketRow[], TotalRow] => {
  return [
    deployedMixToMixRow(deployedMix),
    ...deployedMixToBasketRows(deployedMix),
    deployedMixToTotalRow(deployedMix),
  ];
};

const buildRows = (deployedMixes: CandidateMix[]): Row[] =>
  deployedMixes
    .filter((deployedMix) => deployedMix.baskets.length > 0)
    .flatMap(flattenMixToRows);

const getAllDRIMaterials = (deployedMixes: CandidateMix[]): MaterialMass[] => {
  return sorted(
    deployedMixes
      .flatMap(({ dri }) => Object.values(dri.materialMasses))
      .filter(
        (material, index, all) =>
          all.findIndex(({ id }) => material.id === id) === index
      ),
    (item) => item.name
  );
};

const getAllMaterials = (deployedMixes: CandidateMix[]): MaterialMass[] => {
  return sorted(
    deployedMixes
      .flatMap(({ baskets }) => baskets)
      .flatMap(({ materialMasses }) => Object.values(materialMasses))
      .filter(
        (material, index, all) =>
          all.findIndex(({ id }) => material.id === id) === index
      ),
    (item) => item.name
  );
};

type ContentProps = {
  toNextState: () => void;
  candidateMixes: CandidateMix[];
  showSteelGrades: boolean;
  decimalPlaces: number;
};

const basketWidth = 75;

const useRowHeaders = (
  showSteelGrades: boolean
): [number, GridColDef<Row>[]] => {
  const steelGradesWidth = 150;
  const productGroupWidth = 150;
  const { t } = useTenantTranslation();

  const rowHeaderWidth = showSteelGrades
    ? steelGradesWidth + productGroupWidth
    : productGroupWidth;

  const steelGradesColumn: GridColDef<Row> = {
    field: "steelGrades",
    headerClassName: "spanRowHeader",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1">{t("steelGrades")}</Typography>
    ),
    cellClassName: "rowBottom rowHeader",
    width: steelGradesWidth,
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { steelGrades } = row;
          return (
            <Tooltip title={steelGrades.map((s) => s.name).join(", ")}>
              <Typography variant="body1">
                {steelGrades.map((s) => s.name).join(", ")}
              </Typography>
            </Tooltip>
          );
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const productGroupHeaderClassName = showSteelGrades
    ? "span0"
    : "spanRowHeader";

  const productGroupColumn: GridColDef<Row> = {
    field: "productGroup",
    display: "flex",
    headerClassName: productGroupHeaderClassName,
    width: productGroupWidth,
    cellClassName: "rowBottom rowHeader",
    renderHeader: () => (
      <Typography variant="body1">{t("productGroup")}</Typography>
    ),
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const {
            productGroup: { name },
          } = row;
          return (
            <Tooltip title={name}>
              <Typography variant="body1">{name}</Typography>
            </Tooltip>
          );
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const initialRowHeaders = showSteelGrades
    ? [steelGradesColumn, productGroupColumn]
    : [productGroupColumn];

  return [rowHeaderWidth, [...initialRowHeaders]];
};

export const ApprovePlanContent = ({
  toNextState,
  candidateMixes,
  showSteelGrades,
  decimalPlaces,
}: ContentProps) => {
  const { t } = useTenantTranslation();

  const theme = useTheme();
  const gridApi = useGridApiRef();
  const unit = useUnitsFormatter(true);

  const periodColumn: GridColDef<Row> = {
    field: "period",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("period")}
      </Typography>
    ),
    width: 75,
    cellClassName: "rowBottom rowHeader",
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { period } = row;
          return <Typography variant="body1">{period}</Typography>;
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const mixColumn: GridColDef<Row> = {
    field: "mix",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("mix")}
      </Typography>
    ),
    width: 50,
    cellClassName: "rowBottom rowHeader",
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { mixName } = row;
          return <Typography variant="body1">{mixName}</Typography>;
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const basketColumn: GridColDef<Row> = {
    field: "basket",
    width: basketWidth,
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("basket")}
      </Typography>
    ),
    cellClassName: ({ row: { variant } }) => {
      switch (variant) {
        case "basket":
        case "mix": {
          return "rowHeader";
        }
        case "total": {
          return "rowHeader rowBottom rowHeaderBold";
        }
      }
    },
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          return <>{t("basket")} 1</>;
        }
        case "basket": {
          const { position } = row;
          return (
            <>
              {t("basket")} {position}
            </>
          );
        }
        case "total": {
          return <>{t("total")}</>;
        }
      }
    },
  };

  const pricePerTonne: GridColDef<Row> = {
    field: "pricePerTonne",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("tappedPrice")}
        {unit("specific_cost")}
      </Typography>
    ),
    width: 125,
    cellClassName: ({ row: { variant } }) => {
      switch (variant) {
        case "mix": {
          return "rowBottom cellBold rowHeader";
        }
        case "total":
        case "basket": {
          return "";
        }
      }
    },
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { pricePerTonne } = row;
          return <PriceCell value={pricePerTonne} priceType="specific_cost" />;
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const numberOfHeats: GridColDef<Row> = {
    field: "numberOfHeats",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("numberOfHeats")}
      </Typography>
    ),
    width: 125,
    cellClassName: ({ row: { variant } }) => {
      switch (variant) {
        case "mix": {
          return "rowBottom cellBold rowHeader";
        }
        case "total":
        case "basket": {
          return "";
        }
      }
    },
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { numberOfHeats } = row;
          return (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", width: "100%" }}
            >
              {numberOfHeats}
            </Typography>
          );
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const totalCost: GridColDef<Row> = {
    field: "totalCost",
    display: "flex",
    renderHeader: () => (
      <Typography variant="body1" sx={{ alignText: "center" }}>
        {t("totalCost")}
      </Typography>
    ),
    width: 125,
    cellClassName: ({ row: { variant } }) => {
      switch (variant) {
        case "mix": {
          return "rowBottom cellBold rowHeader";
        }
        case "total":
        case "basket": {
          return "";
        }
      }
    },
    renderCell: ({ row }) => {
      const { variant } = row;
      switch (variant) {
        case "mix": {
          const { totalCost } = row;
          return <PriceCell value={totalCost} priceType="cost" />;
        }
        case "basket":
        case "total": {
          return null;
        }
      }
    },
  };

  const [rowHeaderWidth, rowHeaders] = useRowHeaders(showSteelGrades);
  const rows = buildRows(candidateMixes);

  const columns: GridColDef<Row>[] = [
    periodColumn,
    mixColumn,
    ...rowHeaders,
    numberOfHeats,
    pricePerTonne,
    totalCost,
    ...getAllDRIMaterials(candidateMixes).map(
      ({ id, name }): GridColDef<Row> => {
        return {
          field: id.toString(),
          display: "flex",
          renderHeader: () => <Typography variant="body1">{name}</Typography>,
          width: 125,
          cellClassName: ({ row: { variant } }) => {
            switch (variant) {
              case "mix": {
                return "rowBottom cellBold rowHeader";
              }
              case "total":
              case "basket": {
                return "";
              }
            }
          },
          renderCell: ({ row }) => {
            const { variant } = row;
            switch (variant) {
              case "mix": {
                const {
                  dri: { materialMasses },
                } = row;
                const material = materialMasses[id];
                if (material !== undefined) {
                  return (
                    <MassCell
                      value={material.mass}
                      decimalPlaces={decimalPlaces}
                      fontWeight={900}
                    />
                  );
                } else {
                  return null;
                }
              }
              default: {
                return null;
              }
            }
          },
        };
      }
    ),
    basketColumn,
    {
      field: "basketWeight",
      width: 125,
      display: "flex",
      renderHeader: () => (
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightBold}
        >
          {t("basketWeight")}
        </Typography>
      ),
      cellClassName: ({ row: { variant } }) => {
        switch (variant) {
          case "total": {
            return "rowBottom cellBold rowHeader";
          }
          case "mix":
          case "basket": {
            return "";
          }
        }
      },
      renderCell: ({ row }) => {
        const { variant } = row;
        const value = (() => {
          switch (variant) {
            case "basket":
            case "mix": {
              const {
                basket: { total },
              } = row;
              return total;
            }
            case "total": {
              const {
                total: { total },
              } = row;
              return total;
            }
          }
        })();
        return (
          <MassCell
            value={value}
            decimalPlaces={decimalPlaces}
            fontWeight={theme.typography.fontWeightRegular}
          />
        );
      },
    },
    ...getAllMaterials(candidateMixes).map(
      ({ id, name }): GridColDef<Row> => ({
        field: id.toString(),
        display: "flex",
        renderHeader: () => <Typography variant="body1">{name}</Typography>,
        cellClassName: ({ row: { variant } }) => {
          switch (variant) {
            case "total": {
              return "rowBottom rowHeader";
            }
            case "mix":
            case "basket": {
              return "";
            }
          }
        },
        renderCell: ({ row }) => {
          const { variant } = row;
          const materialMasses = (() => {
            switch (variant) {
              case "basket":
              case "mix": {
                const {
                  basket: { materialMasses },
                } = row;
                return materialMasses;
              }
              case "total": {
                const {
                  total: { materialMasses },
                } = row;
                return materialMasses;
              }
            }
          })();
          const material = materialMasses[id];
          if (material !== undefined) {
            return (
              <MassCell
                value={material.mass}
                decimalPlaces={decimalPlaces}
                fontWeight={
                  variant === "total"
                    ? theme.typography.fontWeightBold
                    : theme.typography.fontWeightRegular
                }
              />
            );
          } else {
            return null;
          }
        },
      })
    ),
  ];

  const driFields = getAllDRIMaterials(candidateMixes).map((mix) =>
    mix.id.toString()
  );

  const pinnedColumns = {
    left: [
      "period",
      "mix",
      "steelGrades",
      "productGroup",
      "numberOfHeats",
      "totalCost",
      "pricePerTonne",
      ...driFields,
      "basket",
    ],
  };

  return (
    <Stack sx={{ minHeight: 0, display: "flex", gap: 1 }}>
      <Button
        sx={{ alignSelf: "end" }}
        onClick={toNextState}
        endIcon={<ArrowForward />}
      >
        {t("approveAndNext")}
      </Button>
      <DataGridPremium
        apiRef={gridApi}
        pinnedColumns={pinnedColumns}
        sx={{
          "& .MuiDataGrid-cell": {
            paddingLeft: 0,
            paddingRight: 0,
          },

          "& .MuiDataGrid-columnHeader": {
            paddingLeft: 1,
            paddingRight: 1,
          },

          "& .spanRowHeader": {
            width: `${rowHeaderWidth}px !important`,
            maxWidth: `${rowHeaderWidth}px !important`,
          },

          "& .span0": {
            width: "0px !important",
            minWidth: "0px !important",
            padding: 0,
            border: "unset !important",
          },

          "& .rowHeader": {
            backgroundColor: `${theme.palette.grey[50]} !important`,
            px: 1,
          },

          "& .rowHeaderBold": {
            fontWeight: theme.typography.fontWeightBold,
          },

          "& .rowBottom": {
            borderBottomColor: theme.palette.grey[200],
            borderBottomWidth: `3px`,
          },
        }}
        columns={columns}
        rows={rows}
        rowHeight={28}
      />
    </Stack>
  );
};

type Props = {
  toNextState: () => void;
};

export const ApprovePlan = ({ toNextState }: Props) => {
  const [productionSchedule] = useProductionSchedule();
  const [miscParams] = useMiscParams();
  const candidateMixes = useCandidateMixes();

  if (
    productionSchedule.status === "success" &&
    miscParams.status === "success" &&
    candidateMixes.status === "success"
  ) {
    const showSteelGrades = productionSchedule.data.steel_grade_items !== null;
    return (
      <ApprovePlanContent
        decimalPlaces={1}
        candidateMixes={candidateMixes.data}
        toNextState={toNextState}
        showSteelGrades={showSteelGrades}
      />
    );
  } else {
    return null;
  }
};
