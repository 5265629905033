import React from "react";
import equal from "fast-deep-equal";
import {
  DataGridPremium,
  GridApi,
  GridCellParams,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  Alert,
  Box,
  Chip,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import {
  MaterialRead,
  NamedPlanMix,
  NamedPlanMixes,
  SteelGrade,
  useGetContextForPlanQuery,
  useGetParametersForPlanQuery,
  useGetPlanMixesQuery,
  useListAllMaterialsQuery,
} from "src/store/api/generatedApi";

import { useTenant } from "hooks/settings";
import { useTenantTranslation, useUnitsFormatter } from "hooks/formatters";

import { sumRecords } from "src/utils/sumRecords";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";

const getDRIMass = (
  materialMasses: Record<number, number>,
  materialYieldsAsDecimal: Record<number, number>,
  targetTappedMass: number,
  driYieldAsDecimal: number
) => {
  if (driYieldAsDecimal < 0 || driYieldAsDecimal > 1) {
    throw new Error("DRI yield not gte 0 and lte 1");
  }
  if (
    Object.values(materialYieldsAsDecimal).some(
      (materialYield) => materialYield < 0 || materialYield > 1
    )
  ) {
    throw new Error("Material yield not gte 0 and lte 1");
  }
  return (
    (1 / driYieldAsDecimal) *
    (targetTappedMass -
      typeSafeObjectEntries(materialMasses).reduce(
        (total, [materialId, mass]) =>
          total + mass * materialYieldsAsDecimal[materialId]!,
        0
      ))
  );
};

type TotalRow = {
  id: number;
  type: "total";
  driMaterialIds: number[];
  mixId: number;
};

type BasketRow = {
  id: number;
  type: "basket";
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  materialMasses: Record<number, number>;
  mixId: number;
};

type ProfileRow = {
  id: number;
  type: "profile";
  copperGroup: number;
  mixName: string;
  steelGrades: number[];
  materialMasses: Record<number, number>;
  mixId: number;
};

type Row = ProfileRow | BasketRow | TotalRow;

const makeProfileRow = (mix: NamedPlanMix): Omit<ProfileRow, "id"> => {
  return {
    type: "profile",
    copperGroup: mix.copper_percent_max_tolerance,
    mixName: mix.mix_name,
    steelGrades: mix.steel_grade_ids,
    materialMasses: mix.baskets[0]?.material_masses ?? {},
    mixId: mix.mix_id,
  };
};

const makeBasketRows = (mix: NamedPlanMix): Omit<BasketRow, "id">[] => {
  return Object.values(mix.baskets)
    .slice(1)
    .map((basket) => ({
      type: "basket",
      steelGrades: mix.steel_grade_ids,
      materialMasses: basket.material_masses,
      copperGroup: mix.copper_percent_max_tolerance,
      mixName: mix.mix_name,
      mixId: mix.mix_id,
    }));
};

const makeTotalRow = (mix: NamedPlanMix): Omit<TotalRow, "id"> => {
  return {
    type: "total",
    mixId: mix.mix_id,
    driMaterialIds: Object.keys(mix.dri.material_masses).map((id) =>
      Number(id)
    ),
  };
};

const makeRows = (mixes: NamedPlanMixes): Row[] => {
  return Object.values(mixes.mixes)
    .flatMap((mix) => {
      return [makeProfileRow(mix), ...makeBasketRows(mix), makeTotalRow(mix)];
    })
    .map((row, index) => ({
      ...row,
      id: index,
    }));
};

const getDRIMaterials = (mixes: NamedPlanMixes): Set<number> => {
  return new Set(
    Object.values(mixes.mixes)
      .flatMap((mix) => Object.keys(mix.dri.material_masses))
      .map((materialMassId) => Number(materialMassId))
  );
};

type SteelGradesCellProps = {
  steelGrades: SteelGrade[];
};

const SteelGradesCell = ({ steelGrades }: SteelGradesCellProps) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 0.4,
        flexWrap: "wrap",
        paddingX: 0.5,
        paddingY: 0.5,
      }}
    >
      {steelGrades.map((steelGrade) => (
        <Chip key={steelGrade.id} label={steelGrade.name} size="small" />
      ))}
    </Stack>
  );
};

const useMakeColumns = (
  materials: MaterialRead[],
  originalRows: Record<number, Row>,
  materialYields: Record<number, number>,
  targetTappedMass: number,
  steelGrades: Record<number, SteelGrade>
): GridColDef<Row>[] => {
  const { t } = useTenantTranslation();
  const units = useUnitsFormatter(false);
  const bracketUnits = useUnitsFormatter(true);
  const columns = React.useMemo((): GridColDef<Row>[] => {
    const columns: GridColDef<Row>[] = [
      {
        field: "steel_grades",
        headerName: t("steelGrades"),
        width: 200,
        valueGetter: (_, row): number[] | string => {
          switch (row.type) {
            case "basket":
            case "profile": {
              return row.steelGrades;
            }
            case "total":
              return t("total");
          }
        },
        renderCell: ({ row }) => {
          switch (row.type) {
            case "basket":
            case "profile": {
              return (
                <SteelGradesCell
                  steelGrades={row.steelGrades.map(
                    (steelGradeId) => steelGrades[steelGradeId]!
                  )}
                />
              );
            }
            case "total":
              return null;
          }
        },
        cellClassName: ({ row: { type } }) => {
          switch (type) {
            case "total": {
              return "total";
            }
            case "profile":
              return "steel_grades";
            case "basket":
              return "";
          }
        },
      },
      {
        field: "mixName",
        headerName: "#",
        valueGetter: (_, row) => {
          switch (row.type) {
            case "basket":
            case "profile": {
              return row.mixName;
            }
            case "total":
              return null;
          }
        },
        cellClassName: ({ row: { type } }) => {
          switch (type) {
            case "total": {
              return "total";
            }
            case "profile":
              return "mix";
            case "basket":
              return "";
          }
        },
      },
      {
        field: "copper_group",
        headerName: "Cu",
        valueGetter: (_, row) => {
          switch (row.type) {
            case "basket":
            case "profile": {
              return row.copperGroup;
            }
            case "total":
              return null;
          }
        },
        valueFormatter: (value: number, row) => {
          if (row.type === "profile") {
            return (value * 100).toFixed(0);
          }
        },
        cellClassName: ({ row: { type } }) => {
          switch (type) {
            case "total": {
              return "total";
            }
            case "profile":
              return "copper";
            case "basket":
              return "";
          }
        },
      },
      {
        field: "dri_weight",
        headerName: `${t("dri")} ${bracketUnits("mass")}`,
        width: 120,
        valueGetter: (_, row, __, dataGridApi) => {
          switch (row.type) {
            case "total": {
              if ("getAllRowIds" in dataGridApi.current) {
                const { driMaterialIds } = row;
                const amount = getDRIMass(
                  dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter(
                      (
                        dataGridRow: Row | null
                      ): dataGridRow is ProfileRow | BasketRow =>
                        dataGridRow !== null &&
                        row.mixId === dataGridRow.mixId &&
                        (dataGridRow.type === "basket" ||
                          dataGridRow.type === "profile")
                    )
                    .map((row) => row.materialMasses)
                    .reduce((total, materialMasses) =>
                      sumRecords(total, materialMasses)
                    ),
                  materialYields,
                  targetTappedMass,
                  /* 
                    driMaterialIds should be a single id,
                    this needs to change in a subsequent PR
                    for the whole PlanMixes class in the backend
                  */
                  materialYields[driMaterialIds[0]!]!
                );
                return amount;
              }
              return null;
            }
            case "basket":
            case "profile":
              return "dri";
          }
        },
        valueFormatter: (value) => {
          if (typeof value === "number") {
            return Number(value).toFixed(0);
          } else {
            return "";
          }
        },
        cellClassName: ({ value, row: { type } }) => {
          switch (type) {
            case "total": {
              return `total ${
                typeof value === "number" && value < 0 ? "warning" : ""
              } material`;
            }
            case "profile":
              return "weight";
            case "basket":
              return "";
          }
        },
      },
      {
        field: "basket_weight",
        headerName: `${t("basket")} ${bracketUnits("mass")}`,
        width: 160,
        valueGetter: (_, row, __, dataGridApi) => {
          switch (row.type) {
            case "basket":
            case "profile":
              return Object.values(row.materialMasses).reduce(
                (total, mass) => total + mass,
                0
              );
            case "total":
              return Object.values(
                dataGridApi.current
                  .getAllRowIds()
                  .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                  .filter(
                    (row: Row | null): row is ProfileRow | BasketRow =>
                      row !== null && row.type !== "total"
                  )
                  .filter(({ mixId }) => mixId === row.mixId)
                  .reduce(
                    (totals, row) => {
                      const { materialMasses } = row;
                      return sumRecords(totals, materialMasses);
                    },
                    {} as Record<number, number>
                  )
              ).reduce((sum, mass) => sum + mass, 0);
          }
        },
        valueFormatter: (value: number) => {
          if (value !== undefined) {
            return `${value.toFixed(0)}${units("mass")}`;
          }
        },
        rowSpanValueGetter: () => null,
        cellClassName: ({ row: { type } }) => {
          switch (type) {
            case "total": {
              return "total material";
            }
            case "basket":
            case "profile":
              return "weight material";
          }
        },
      },
      ...materials.map(
        (material): GridColDef<Row> => ({
          field: material.id.toString(),
          headerName: material.name,
          width: 100,
          type: "number",
          valueGetter: (_, row, __, dataGridApi) => {
            switch (row.type) {
              case "basket":
              case "profile":
                return row.materialMasses[material.id] &&
                  row.materialMasses[material.id] !== 0
                  ? row.materialMasses[material.id]
                  : null;
              case "total":
                return (
                  dataGridApi.current
                    .getAllRowIds()
                    .map((rowId) => dataGridApi.current.getRow<Row>(rowId))
                    .filter((row: Row | null): row is Row => row !== null)
                    .filter(
                      (
                        dataGridRow: Row
                      ): dataGridRow is ProfileRow | BasketRow => {
                        switch (dataGridRow.type) {
                          case "basket":
                          case "profile": {
                            return row.mixId === dataGridRow.mixId;
                          }
                          case "total": {
                            return false;
                          }
                        }
                        // This needs to be here to make eslint happy. Not sure why.
                        return false;
                      }
                    )
                    .reduce(
                      (totals, row) => {
                        const { materialMasses } = row;
                        return sumRecords(totals, materialMasses);
                      },
                      {} as Record<number, number>
                    )[material.id] ?? 0
                );
            }
          },
          valueFormatter: (value: unknown) => {
            if (typeof value === "number") {
              return `${value.toFixed(0)}`;
            }
          },
          rowSpanValueGetter: () => null,
          editable: true,
          valueSetter: (value, row) => {
            switch (row.type) {
              case "total": {
                return row;
              }
              case "profile":
              case "basket":
                if (value !== undefined && value !== null && value >= 0) {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: Number(value),
                    },
                  };
                } else {
                  return {
                    ...row,
                    materialMasses: {
                      ...row.materialMasses,
                      [material.id]: 0,
                    },
                  };
                }
            }
          },
          cellClassName: ({ row }) => {
            const { type } = row;
            switch (type) {
              case "total": {
                return "total material";
              }
              case "basket":
              case "profile": {
                const { materialMasses, id } = row;
                if (
                  materialMasses[material.id] !==
                  (originalRows[id] as ProfileRow)?.materialMasses[material.id]
                ) {
                  return "edited material";
                } else {
                  return "material";
                }
              }
            }
          },
        })
      ),
    ];

    return columns.map((column) => ({
      ...column,
      sortable: false,
      headerAlign: "center",
    }));
  }, [t, materials]);
  return columns;
};

type BodyProps = {
  materials: MaterialRead[];
  mixes: NamedPlanMixes;
  targetTappedMass: number;
  materialDecimalYields: Record<number, number>;
  steelGrades: Record<number, SteelGrade>;
};

type Props = {
  planId: number;
};

export const DataGridSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="200px repeat(3, 80px) 120px 160px repeat(20, 80px)"
      gridAutoRows="min-content"
      sx={{
        columnGap: 0.5,
        rowGap: 0.5,
        overflow: "hidden",
        height: "calc(100% - 24px)",
        width: "fit-content",
        margin: "12px auto",
      }}
    >
      {new Array(26).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={`${index}.column`} variant="rectangular" />
      ))}
      {new Array(26 * 20).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={40} key={`${index}.cell`} variant="rectangular" />
      ))}
    </Box>
  );
};

export const PlanView = ({ planId }: Props) => {
  const tenantName = useTenant();
  const { data: planMixes } = useGetPlanMixesQuery({
    tenantName,
    planId,
    period: 1,
  });
  const { data: planContext } = useGetContextForPlanQuery({
    tenantName,
    planId,
  });
  const { data: planParameters } = useGetParametersForPlanQuery({
    tenantName,
    planId,
  });
  const { data: materials } = useListAllMaterialsQuery({ tenantName });
  if (planMixes && materials && planContext && planParameters) {
    return (
      <Body
        mixes={planMixes}
        materials={materials}
        key={planId}
        materialDecimalYields={typeSafeObjectFromEntries(
          Object.values(planContext.material_physics).map((material) => [
            material.material_id,
            material.yield_percent / 100,
          ])
        )}
        targetTappedMass={
          planParameters.physical_parameters.target_tapped_mass_lower
        }
        steelGrades={typeSafeObjectFromEntries(
          planContext.steel_grades.map((steelGrade) => [
            steelGrade.id,
            steelGrade,
          ])
        )}
      />
    );
  } else {
    return <DataGridSkeleton />;
  }
};

const centerSX = {
  display: "grid",
  justifyContent: "center",
  alignContent: "center",
};

const getAllRows = (apiRef: React.MutableRefObject<GridApi>) => {
  return apiRef.current
    .getAllRowIds()
    .map((rowId) => apiRef.current.getRow<Row>(rowId))
    .filter((row: Row | null): row is Row => row !== null);
};

export const Body = ({
  mixes,
  materials,
  materialDecimalYields,
  targetTappedMass,
  steelGrades,
}: BodyProps) => {
  const apiRef = useGridApiRef();

  const theme = useTheme();
  const { t } = useTenantTranslation();

  const [hasEdited, setHasEdited] = React.useState(false);
  const [resetCounter, setResetCounter] = React.useState(0);

  const rows = React.useMemo(() => {
    return makeRows(mixes);
  }, [mixes, resetCounter]);

  const driMaterials = React.useMemo(() => {
    return getDRIMaterials(mixes);
  }, [mixes]);

  const columns = useMakeColumns(
    materials.filter((material) => !driMaterials.has(material.id)),
    rows,
    materialDecimalYields,
    targetTappedMass,
    steelGrades
  );

  const handleUpdateRow = React.useCallback(() => {
    const hasEdited = !equal(getAllRows(apiRef), rows);
    setHasEdited(hasEdited);
  }, [rows, setHasEdited]);

  const handleDiscardChanges = () => {
    setResetCounter((counter) => counter + 1);
    setHasEdited(false);
  };

  return (
    <>
      <Snackbar
        open={hasEdited}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          sx={{
            alignItems: "center",
            ".MuiAlert-action": {
              pt: "0px !important",
            },
          }}
          icon={<WarningAmber />}
          severity="warning"
          action={
            <>
              <LoadingButton
                loading={false}
                onClick={() => console.log(getAllRows(apiRef))}
                color="primary"
                variant="contained"
                sx={{ mr: 2 }}
              >
                {t("submit")}
              </LoadingButton>
              <LoadingButton
                loading={false}
                onClick={handleDiscardChanges}
                color="secondary"
                variant="outlined"
              >
                {t("discard")}
              </LoadingButton>
            </>
          }
        >
          <Typography variant="body1">{t("changesMade")}</Typography>
        </Alert>
      </Snackbar>
      <DataGridPremium<Row>
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        columnHeaderHeight={40}
        processRowUpdate={(newRow) => {
          /* 
            handleUpdateRow needs to happen AFTER this callback has returned the new row.
            By setting a timeout we can put its call to the the queue behind the 
            processRowUpdate prop callback's promise
          */
          setTimeout(() => handleUpdateRow());
          return newRow;
        }}
        unstable_rowSpanning
        isCellEditable={(params: GridCellParams<Row>) =>
          params.row.type !== "total"
        }
        sx={{
          width: "calc(100% - 24px)",
          height: "calc(100% - 24px)",
          margin: 1,
          [".total"]: {
            backgroundColor: theme.palette.grey[300],
            color: "black",
            fontWeight: "bold",
          },
          [".weight"]: {
            backgroundColor: theme.palette.data.lightblue,
            color: "black",
          },
          [".material"]: {
            color: "black",
            fontSize: theme.typography.h5.fontSize,
            ...centerSX,
          },
          [".copper"]: {
            ...centerSX,
            backgroundColor: theme.palette.data.orange,
            color: theme.palette.text.primary,
            fontSize: theme.typography.h3.fontSize,
          },
          [".mix"]: {
            ...centerSX,
            backgroundColor: theme.palette.data.blue,
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.text.primary,
          },
          [".edited"]: {
            backgroundColor: theme.palette.success.light,
          },
          [".warning"]: {
            backgroundColor: theme.palette.error.light,
          },
          [".steel_grades"]: {
            padding: 0,
          },
          [".MuiDataGrid-columnHeader"]: {
            textAlign: "center",
          },
          [".MuiDataGrid-columnHeaderTitle"]: {
            fontSize: theme.typography.h4.fontSize,
          },
        }}
      />
    </>
  );
};
