import { templateApi as api } from "./templateApi";
export const addTagTypes = [
  "tenants",
  "misc_params",
  "recipes",
  "chef_groups",
  "steel_grades",
  "steel_grade_plans",
  "baskets",
  "baskets_material_limits",
  "chemistry",
  "materials",
  "deployments",
  "drop_weight",
  "multiple_price_availability",
  "material_bundles",
  "product_groups",
  "product_group_plans",
  "production_plans",
  "search",
  "search_context",
  "experimental_search",
  "plan",
  "session",
  "result",
  "uploaded_files",
  "obtainable_estimates",
  "publish",
  "messages",
  "inventory",
  "target_inventory",
  "target_baskets",
  "invite",
  "auth",
  "video_labelling",
  "mix_material_limits",
  "scrap_tracking",
  "simulator",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTenantMetadata: build.query<
        GetTenantMetadataApiResponse,
        GetTenantMetadataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/tenants/${queryArg.tenantName}`,
        }),
        providesTags: ["tenants"],
      }),
      listMiscParamsSets: build.query<
        ListMiscParamsSetsApiResponse,
        ListMiscParamsSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/misc_params/${queryArg.tenantName}`,
        }),
        providesTags: ["misc_params"],
      }),
      createMiscParamsSet: build.mutation<
        CreateMiscParamsSetApiResponse,
        CreateMiscParamsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/misc_params/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.miscParamsSetCreate,
        }),
        invalidatesTags: ["misc_params"],
      }),
      getMiscParamsSet: build.query<
        GetMiscParamsSetApiResponse,
        GetMiscParamsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/misc_params/${queryArg.tenantName}/${queryArg.miscParamsSetId}`,
        }),
        providesTags: ["misc_params"],
      }),
      setDefaultMiscParamsSet: build.mutation<
        SetDefaultMiscParamsSetApiResponse,
        SetDefaultMiscParamsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/misc_params/${queryArg.tenantName}/${queryArg.miscParamsSetId}`,
          method: "PUT",
        }),
        invalidatesTags: ["misc_params"],
      }),
      getSimilarChargedRecipes: build.query<
        GetSimilarChargedRecipesApiResponse,
        GetSimilarChargedRecipesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/regular/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.similarChargedRecipesParams,
        }),
        providesTags: ["recipes"],
      }),
      createAutoRecipe: build.mutation<
        CreateAutoRecipeApiResponse,
        CreateAutoRecipeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/auto/${queryArg.tenantName}/${queryArg.autoRecipeType}`,
          method: "POST",
          params: {
            view_from_timestamp: queryArg.viewFromTimestamp,
            deleted: queryArg.deleted,
          },
        }),
        invalidatesTags: ["recipes"],
      }),
      listChefGroups: build.query<
        ListChefGroupsApiResponse,
        ListChefGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}`,
        }),
        providesTags: ["chef_groups"],
      }),
      createChefGrouping: build.mutation<
        CreateChefGroupingApiResponse,
        CreateChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.chefGroupingCreate,
        }),
        invalidatesTags: ["chef_groups"],
      }),
      getChefGrouping: build.query<
        GetChefGroupingApiResponse,
        GetChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}/${queryArg.chefGroupingId}`,
        }),
        providesTags: ["chef_groups"],
      }),
      setDefaultChefGrouping: build.mutation<
        SetDefaultChefGroupingApiResponse,
        SetDefaultChefGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chef_groups/${queryArg.tenantName}/${queryArg.chefGroupingId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["chef_groups"],
      }),
      listSteelGrades: build.query<
        ListSteelGradesApiResponse,
        ListSteelGradesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}`,
        }),
        providesTags: ["steel_grades"],
      }),
      createSteelGradeSet: build.mutation<
        CreateSteelGradeSetApiResponse,
        CreateSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.steelGradeSetCreate,
        }),
        invalidatesTags: ["steel_grades"],
      }),
      getSteelGradeSet: build.query<
        GetSteelGradeSetApiResponse,
        GetSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
        }),
        providesTags: ["steel_grades"],
      }),
      setDefaultSteelGradeSet: build.mutation<
        SetDefaultSteelGradeSetApiResponse,
        SetDefaultSteelGradeSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grades/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["steel_grades"],
      }),
      listSteelGradePlans: build.query<
        ListSteelGradePlansApiResponse,
        ListSteelGradePlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}`,
        }),
        providesTags: ["steel_grade_plans"],
      }),
      createSteelGradePlan: build.mutation<
        CreateSteelGradePlanApiResponse,
        CreateSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.steelGradePlanCreate,
        }),
        invalidatesTags: ["steel_grade_plans"],
      }),
      getSteelGradePlan: build.query<
        GetSteelGradePlanApiResponse,
        GetSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}/${queryArg.steelGradePlanId}`,
        }),
        providesTags: ["steel_grade_plans"],
      }),
      setDefaultSteelGradePlan: build.mutation<
        SetDefaultSteelGradePlanApiResponse,
        SetDefaultSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/${queryArg.tenantName}/${queryArg.steelGradePlanId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["steel_grade_plans"],
      }),
      getSteelGradePlanFromFile: build.query<
        GetSteelGradePlanFromFileApiResponse,
        GetSteelGradePlanFromFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/plan_file/${queryArg.tenantName}/${queryArg.planFileId}`,
          params: { steel_grade_set_id: queryArg.steelGradeSetId },
        }),
        providesTags: ["steel_grade_plans"],
      }),
      getTypicalSteelGradePlan: build.query<
        GetTypicalSteelGradePlanApiResponse,
        GetTypicalSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/steel_grade_plans/typical/production/${queryArg.tenantName}/${queryArg.steelGradeSetId}`,
          params: { num_heats: queryArg.numHeats },
        }),
        providesTags: ["steel_grade_plans"],
      }),
      listBaskets: build.query<ListBasketsApiResponse, ListBasketsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/baskets/${queryArg.tenantName}`,
        }),
        providesTags: ["baskets"],
      }),
      listBasketLayers: build.query<
        ListBasketLayersApiResponse,
        ListBasketLayersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_layers/${queryArg.tenantName}`,
        }),
        providesTags: ["baskets"],
      }),
      createBasketConstraintSet: build.mutation<
        CreateBasketConstraintSetApiResponse,
        CreateBasketConstraintSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_constraints/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.basketConstraintSetCreate,
        }),
        invalidatesTags: ["baskets"],
      }),
      listBasketConstraintSets: build.query<
        ListBasketConstraintSetsApiResponse,
        ListBasketConstraintSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_constraints/${queryArg.tenantName}`,
        }),
        providesTags: ["baskets"],
      }),
      getBasketConstraintSet: build.query<
        GetBasketConstraintSetApiResponse,
        GetBasketConstraintSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_constraints/${queryArg.tenantName}/${queryArg.basketConstraintSetId}`,
        }),
        providesTags: ["baskets"],
      }),
      setDefaultBasketConstraintSet: build.mutation<
        SetDefaultBasketConstraintSetApiResponse,
        SetDefaultBasketConstraintSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_constraints/${queryArg.tenantName}/${queryArg.basketConstraintSetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["baskets"],
      }),
      getFormattedBasketConstraints: build.query<
        GetFormattedBasketConstraintsApiResponse,
        GetFormattedBasketConstraintsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/formatted_basket_constraints/${queryArg.tenantName}`,
        }),
        providesTags: ["baskets"],
      }),
      createBasketMaterialAddition: build.mutation<
        CreateBasketMaterialAdditionApiResponse,
        CreateBasketMaterialAdditionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_material_additions/${queryArg.tenantName}/${queryArg.planId}`,
          method: "POST",
          body: queryArg.basketMaterialAdditionCreate,
        }),
        invalidatesTags: ["baskets"],
      }),
      updateSearchChefGroupBasketMaterialLimits: build.mutation<
        UpdateSearchChefGroupBasketMaterialLimitsApiResponse,
        UpdateSearchChefGroupBasketMaterialLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_material_limit/${queryArg.tenantName}/search/${queryArg.searchId}/chef_group_basket_material_limit_set`,
          method: "POST",
          body: queryArg.chefGroupBasketMaterialLimitSetCreate,
        }),
        invalidatesTags: ["baskets_material_limits"],
      }),
      getChefGroupBasketMaterialLimitSet: build.query<
        GetChefGroupBasketMaterialLimitSetApiResponse,
        GetChefGroupBasketMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/basket_material_limit/${queryArg.tenantName}/chef_group_basket_material_limit_set/${queryArg.chefGroupBasketMaterialLimitSetId}`,
        }),
        providesTags: ["baskets_material_limits"],
      }),
      listChemicalElements: build.query<
        ListChemicalElementsApiResponse,
        ListChemicalElementsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/chemical_elements/${queryArg.tenantName}`,
        }),
        providesTags: ["chemistry"],
      }),
      listMaterialSets: build.query<
        ListMaterialSetsApiResponse,
        ListMaterialSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_sets/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getMaterialSet: build.query<
        GetMaterialSetApiResponse,
        GetMaterialSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_sets/${queryArg.tenantName}/${queryArg.materialSetId}`,
        }),
        providesTags: ["materials"],
      }),
      listAllMaterials: build.query<
        ListAllMaterialsApiResponse,
        ListAllMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      listMaterials: build.query<ListMaterialsApiResponse, ListMaterialsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/materials/${queryArg.tenantName}/${queryArg.materialSetId}`,
          }),
          providesTags: ["materials"],
        }
      ),
      listMaterialConstraintClasses: build.query<
        ListMaterialConstraintClassesApiResponse,
        ListMaterialConstraintClassesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_constraint_classes/${queryArg.tenantName}/${queryArg.materialSetId}/${queryArg.materialPhysicsSetId}`,
        }),
        providesTags: ["materials"],
      }),
      getFullMaterials: build.query<
        GetFullMaterialsApiResponse,
        GetFullMaterialsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/full/${queryArg.tenantName}/${queryArg.materialSetId}/${queryArg.materialPhysicsSetId}/${queryArg.materialChemistrySetId}`,
        }),
        providesTags: ["materials"],
      }),
      createMaterialChemistrySet: build.mutation<
        CreateMaterialChemistrySetApiResponse,
        CreateMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.materialChemistrySetCreate,
        }),
        invalidatesTags: ["materials"],
      }),
      listMaterialChemistrySets: build.query<
        ListMaterialChemistrySetsApiResponse,
        ListMaterialChemistrySetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getMaterialChemistrySet: build.query<
        GetMaterialChemistrySetApiResponse,
        GetMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}/${queryArg.materialChemistrySetId}`,
        }),
        providesTags: ["materials"],
      }),
      setDefaultMaterialChemistrySet: build.mutation<
        SetDefaultMaterialChemistrySetApiResponse,
        SetDefaultMaterialChemistrySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_chemistry/${queryArg.tenantName}/${queryArg.materialChemistrySetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["materials"],
      }),
      createMaterialPhysicsSet: build.mutation<
        CreateMaterialPhysicsSetApiResponse,
        CreateMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.materialPhysicsSetCreate,
        }),
        invalidatesTags: ["materials"],
      }),
      listMaterialPhysicsSets: build.query<
        ListMaterialPhysicsSetsApiResponse,
        ListMaterialPhysicsSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getMaterialPhysicsSet: build.query<
        GetMaterialPhysicsSetApiResponse,
        GetMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}/${queryArg.materialPhysicsSetId}`,
        }),
        providesTags: ["materials"],
      }),
      setDefaultMaterialPhysicsSet: build.mutation<
        SetDefaultMaterialPhysicsSetApiResponse,
        SetDefaultMaterialPhysicsSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_physics/${queryArg.tenantName}/${queryArg.materialPhysicsSetId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["materials"],
      }),
      getMaterialPrices: build.query<
        GetMaterialPricesApiResponse,
        GetMaterialPricesApiArg
      >({
        query: (queryArg) => ({ url: `/api/v2/prices/${queryArg.tenantName}` }),
        providesTags: ["materials"],
      }),
      getMaterialNameMappings: build.query<
        GetMaterialNameMappingsApiResponse,
        GetMaterialNameMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/name_mappings/${queryArg.tenantName}`,
        }),
        providesTags: ["materials"],
      }),
      getTenantContacts: build.query<
        GetTenantContactsApiResponse,
        GetTenantContactsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/contacts`,
        }),
        providesTags: ["deployments"],
      }),
      getPlanMixes: build.query<GetPlanMixesApiResponse, GetPlanMixesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/plan_mixes/${queryArg.planId}`,
          params: { period: queryArg.period },
        }),
        providesTags: ["deployments"],
      }),
      getPlanMixesExcel: build.query<
        GetPlanMixesExcelApiResponse,
        GetPlanMixesExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/plan_mixes/${queryArg.planId}/excel/${queryArg.language}`,
          params: { period: queryArg.period },
        }),
        providesTags: ["deployments"],
      }),
      sendDeployedMixesEmail: build.mutation<
        SendDeployedMixesEmailApiResponse,
        SendDeployedMixesEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/deployments/${queryArg.tenantName}/send_deployed_mixes_email/${queryArg.planId}/${queryArg.language}`,
          method: "POST",
        }),
        invalidatesTags: ["deployments"],
      }),
      listChargeBucketDropWeightsRecentDeprecated: build.query<
        ListChargeBucketDropWeightsRecentDeprecatedApiResponse,
        ListChargeBucketDropWeightsRecentDeprecatedApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/drop_weight/${queryArg.tenantName}/charge_bucket/${queryArg.chargeBucketId}/recent/deprecated`,
        }),
        providesTags: ["drop_weight"],
      }),
      listChargeBucketDropWeightsRecent: build.query<
        ListChargeBucketDropWeightsRecentApiResponse,
        ListChargeBucketDropWeightsRecentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/drop_weight/${queryArg.tenantName}/charge_bucket/${queryArg.chargeBucketId}/recent`,
        }),
        providesTags: ["drop_weight"],
      }),
      getObtainableBundleItemSet: build.query<
        GetObtainableBundleItemSetApiResponse,
        GetObtainableBundleItemSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/multiple_price_availability/${queryArg.tenantName}/${queryArg.obtainableBundleSetId}`,
          params: { material_set_id: queryArg.materialSetId },
        }),
        providesTags: ["multiple_price_availability"],
      }),
      createObtainableBundleItemSet: build.mutation<
        CreateObtainableBundleItemSetApiResponse,
        CreateObtainableBundleItemSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/multiple_price_availability/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.obtainableBundleItemSetCreate,
        }),
        invalidatesTags: ["multiple_price_availability"],
      }),
      listMaterialBundles: build.query<
        ListMaterialBundlesApiResponse,
        ListMaterialBundlesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_bundles/${queryArg.tenantName}`,
        }),
        providesTags: ["material_bundles"],
      }),
      createMaterialBundle: build.mutation<
        CreateMaterialBundleApiResponse,
        CreateMaterialBundleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_bundles/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.materialBundleCreate,
        }),
        invalidatesTags: ["material_bundles"],
      }),
      getMaterialBundle: build.query<
        GetMaterialBundleApiResponse,
        GetMaterialBundleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/material_bundles/${queryArg.tenantName}/${queryArg.materialBundleId}`,
        }),
        providesTags: ["material_bundles"],
      }),
      listProductGroupings: build.query<
        ListProductGroupingsApiResponse,
        ListProductGroupingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}`,
        }),
        providesTags: ["product_groups"],
      }),
      createProductGrouping: build.mutation<
        CreateProductGroupingApiResponse,
        CreateProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.productGroupingCreate,
        }),
        invalidatesTags: ["product_groups"],
      }),
      getProductGrouping: build.query<
        GetProductGroupingApiResponse,
        GetProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}/${queryArg.productGroupingId}`,
        }),
        providesTags: ["product_groups"],
      }),
      setDefaultProductGrouping: build.mutation<
        SetDefaultProductGroupingApiResponse,
        SetDefaultProductGroupingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_groups/${queryArg.tenantName}/${queryArg.productGroupingId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["product_groups"],
      }),
      listProductGroupPlans: build.query<
        ListProductGroupPlansApiResponse,
        ListProductGroupPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}`,
        }),
        providesTags: ["product_group_plans"],
      }),
      createProductGroupPlan: build.mutation<
        CreateProductGroupPlanApiResponse,
        CreateProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.productGroupPlanCreate,
        }),
        invalidatesTags: ["product_group_plans"],
      }),
      getProductGroupPlan: build.query<
        GetProductGroupPlanApiResponse,
        GetProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}/${queryArg.productGroupPlanId}`,
        }),
        providesTags: ["product_group_plans"],
      }),
      setDefaultProductGroupPlan: build.mutation<
        SetDefaultProductGroupPlanApiResponse,
        SetDefaultProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/${queryArg.tenantName}/${queryArg.productGroupPlanId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["product_group_plans"],
      }),
      getTypicalProductGroupPlan: build.query<
        GetTypicalProductGroupPlanApiResponse,
        GetTypicalProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/product_group_plans/typical_production/${queryArg.tenantName}/${queryArg.productGroupingId}`,
          params: { num_heats: queryArg.numHeats },
        }),
        providesTags: ["product_group_plans"],
      }),
      listProductionPlans: build.query<
        ListProductionPlansApiResponse,
        ListProductionPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/${queryArg.tenantName}`,
        }),
        providesTags: ["production_plans"],
      }),
      createProductionPlan: build.mutation<
        CreateProductionPlanApiResponse,
        CreateProductionPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.productionPlanCreate,
        }),
        invalidatesTags: ["production_plans"],
      }),
      getProductionPlan: build.query<
        GetProductionPlanApiResponse,
        GetProductionPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/${queryArg.tenantName}/${queryArg.productionPlanId}`,
        }),
        providesTags: ["production_plans"],
      }),
      setDefaultProductionPlan: build.mutation<
        SetDefaultProductionPlanApiResponse,
        SetDefaultProductionPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/${queryArg.tenantName}/${queryArg.productionPlanId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["production_plans"],
      }),
      getSourcedProductionPlan: build.query<
        GetSourcedProductionPlanApiResponse,
        GetSourcedProductionPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/sourced/${queryArg.tenantName}/${queryArg.productionPlanId}`,
          params: {
            target_steel_grade_set_id: queryArg.targetSteelGradeSetId,
            target_chef_grouping_id: queryArg.targetChefGroupingId,
            target_product_grouping_id: queryArg.targetProductGroupingId,
          },
        }),
        providesTags: ["production_plans"],
      }),
      createSourcedProductionPlanFromSteelGradePlan: build.mutation<
        CreateSourcedProductionPlanFromSteelGradePlanApiResponse,
        CreateSourcedProductionPlanFromSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/sourced/from_steel_grade_plan/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.sourcedProductionPlanFromSteelGradePlan,
        }),
        invalidatesTags: ["production_plans"],
      }),
      createSourcedProductionPlanFromProductGroupPlan: build.mutation<
        CreateSourcedProductionPlanFromProductGroupPlanApiResponse,
        CreateSourcedProductionPlanFromProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/sourced/from_product_group_plan/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.sourcedProductionPlanFromProductGroupPlan,
        }),
        invalidatesTags: ["production_plans"],
      }),
      getProductionPlanFromFile: build.query<
        GetProductionPlanFromFileApiResponse,
        GetProductionPlanFromFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/plan_file/${queryArg.tenantName}/${queryArg.planFileId}`,
          params: { chef_grouping_id: queryArg.chefGroupingId },
        }),
        providesTags: ["production_plans"],
      }),
      getProductionPlanFromProductGroupPlan: build.query<
        GetProductionPlanFromProductGroupPlanApiResponse,
        GetProductionPlanFromProductGroupPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/product_group_plan/${queryArg.tenantName}/${queryArg.productGroupPlanId}`,
          params: { chef_grouping_id: queryArg.chefGroupingId },
        }),
        providesTags: ["production_plans"],
      }),
      getProductionPlanFromSteelGradePlan: build.query<
        GetProductionPlanFromSteelGradePlanApiResponse,
        GetProductionPlanFromSteelGradePlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/steel_grade_plan/${queryArg.tenantName}/${queryArg.steelGradePlanId}`,
          params: { chef_grouping_id: queryArg.chefGroupingId },
        }),
        providesTags: ["production_plans"],
      }),
      getTypicalProductionPlan: build.query<
        GetTypicalProductionPlanApiResponse,
        GetTypicalProductionPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/production_plans/typical_production/${queryArg.tenantName}/${queryArg.chefGroupingId}`,
          params: { num_heats: queryArg.numHeats },
        }),
        providesTags: ["production_plans"],
      }),
      listSearches: build.query<ListSearchesApiResponse, ListSearchesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}`,
          params: {
            ordering: queryArg.ordering,
            session_id: queryArg.sessionId,
            limit: queryArg.limit,
          },
        }),
        providesTags: ["search"],
      }),
      createSearch: build.mutation<CreateSearchApiResponse, CreateSearchApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/v2/search/${queryArg.tenantName}`,
            method: "POST",
            body: queryArg.createSearch,
          }),
          invalidatesTags: ["search"],
        }
      ),
      sendSearchNotification: build.mutation<
        SendSearchNotificationApiResponse,
        SendSearchNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/notify`,
          method: "POST",
        }),
        invalidatesTags: ["search"],
      }),
      createSearchFromContext: build.mutation<
        CreateSearchFromContextApiResponse,
        CreateSearchFromContextApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/context`,
          method: "POST",
        }),
        invalidatesTags: ["search"],
      }),
      runSearch: build.mutation<RunSearchApiResponse, RunSearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/run`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["search"],
      }),
      getSearch: build.query<GetSearchApiResponse, GetSearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/search_parameters/${queryArg.searchId}`,
        }),
        providesTags: ["search"],
      }),
      createSearchFromParameters: build.mutation<
        CreateSearchFromParametersApiResponse,
        CreateSearchFromParametersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/search_parameters`,
          method: "POST",
          body: queryArg.syncedSearchParametersInput,
        }),
        invalidatesTags: ["search"],
      }),
      getSearchContext: build.query<
        GetSearchContextApiResponse,
        GetSearchContextApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/search_context/${queryArg.searchContextId}`,
        }),
        providesTags: ["search"],
      }),
      getNameBasedSearch: build.query<
        GetNameBasedSearchApiResponse,
        GetNameBasedSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/name_based_search/${queryArg.searchId}`,
        }),
        providesTags: ["search"],
      }),
      createSearchContextGroup: build.mutation<
        CreateSearchContextGroupApiResponse,
        CreateSearchContextGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.searchContextGroupCreate,
        }),
        invalidatesTags: ["search_context"],
      }),
      listSearchContextGroups: build.query<
        ListSearchContextGroupsApiResponse,
        ListSearchContextGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}`,
        }),
        providesTags: ["search_context"],
      }),
      getSearchContextGroup: build.query<
        GetSearchContextGroupApiResponse,
        GetSearchContextGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}/${queryArg.searchContextGroupId}`,
        }),
        providesTags: ["search_context"],
      }),
      updateSearchContextGroupMixMaterialLimits: build.mutation<
        UpdateSearchContextGroupMixMaterialLimitsApiResponse,
        UpdateSearchContextGroupMixMaterialLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search_context_group/${queryArg.tenantName}/${queryArg.searchContextGroupId}/search_context/${queryArg.searchContextId}/mix_material_limits`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["search_context"],
      }),
      loadExperimentalSearch: build.query<
        LoadExperimentalSearchApiResponse,
        LoadExperimentalSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/load/${queryArg.experimentalSearchId}`,
        }),
        providesTags: ["experimental_search"],
      }),
      syncExperimentalSearch: build.mutation<
        SyncExperimentalSearchApiResponse,
        SyncExperimentalSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/sync`,
          method: "POST",
          body: queryArg.experimentalSearchCreate,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      runExperimentalSearch: build.mutation<
        RunExperimentalSearchApiResponse,
        RunExperimentalSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/run`,
          method: "POST",
          body: queryArg.optimiserInputs,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      importInputsFromFile: build.mutation<
        ImportInputsFromFileApiResponse,
        ImportInputsFromFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/import_inputs_from_file/${queryArg.contextId}`,
          method: "POST",
          body: queryArg.uploadedFileCreate,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      importInputsFromText: build.mutation<
        ImportInputsFromTextApiResponse,
        ImportInputsFromTextApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/import_inputs_from_text`,
          method: "POST",
          body: queryArg.importedText,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      importMixAllocationFromPlan: build.mutation<
        ImportMixAllocationFromPlanApiResponse,
        ImportMixAllocationFromPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/import_mix_allocation_from_plan/${queryArg.planId}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      importMixAllocationFromFile: build.mutation<
        ImportMixAllocationFromFileApiResponse,
        ImportMixAllocationFromFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/import_mix_allocation_from_file`,
          method: "POST",
          body: queryArg.importMixAllocationBody,
        }),
        invalidatesTags: ["experimental_search"],
      }),
      getExperimentalSessions: build.query<
        GetExperimentalSessionsApiResponse,
        GetExperimentalSessionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/experimental_sessions`,
        }),
        providesTags: ["experimental_search"],
      }),
      getExperimentalSearches: build.query<
        GetExperimentalSearchesApiResponse,
        GetExperimentalSearchesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/experimental_search/${queryArg.tenantName}/experimental_searches/${queryArg.sessionId}`,
        }),
        providesTags: ["experimental_search"],
      }),
      listDeployedPlans: build.query<
        ListDeployedPlansApiResponse,
        ListDeployedPlansApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/deployed`,
        }),
        providesTags: ["plan"],
      }),
      sendPlanNotification: build.mutation<
        SendPlanNotificationApiResponse,
        SendPlanNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/send_notification`,
          method: "POST",
          params: { plan_name: queryArg.planName },
        }),
        invalidatesTags: ["plan"],
      }),
      getContextForPlan: build.query<
        GetContextForPlanApiResponse,
        GetContextForPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/context`,
        }),
        providesTags: ["plan"],
      }),
      getParametersForPlan: build.query<
        GetParametersForPlanApiResponse,
        GetParametersForPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/plan/${queryArg.tenantName}/${queryArg.planId}/parameters`,
        }),
        providesTags: ["plan"],
      }),
      listSessions: build.query<ListSessionsApiResponse, ListSessionsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}`,
        }),
        providesTags: ["session"],
      }),
      renameSession: build.mutation<
        RenameSessionApiResponse,
        RenameSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "PATCH",
          body: queryArg.renameSession,
        }),
        invalidatesTags: ["session"],
      }),
      deleteSession: build.mutation<
        DeleteSessionApiResponse,
        DeleteSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["session"],
      }),
      copySearch: build.mutation<CopySearchApiResponse, CopySearchApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/session/${queryArg.tenantName}/${queryArg.sessionId}`,
          method: "POST",
          body: queryArg.copySearch,
        }),
        invalidatesTags: ["session"],
      }),
      getResult: build.query<GetResultApiResponse, GetResultApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/result/${queryArg.tenantName}/${queryArg.searchId}`,
        }),
        providesTags: ["result"],
      }),
      saveUploadedFile: build.mutation<
        SaveUploadedFileApiResponse,
        SaveUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files`,
          method: "POST",
          body: queryArg.uploadedFileCreate,
        }),
        invalidatesTags: ["uploaded_files"],
      }),
      listUploadedFiles: build.query<
        ListUploadedFilesApiResponse,
        ListUploadedFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files`,
          params: {
            types: queryArg.types,
            deleted: queryArg.deleted,
            limit: queryArg.limit,
            order: queryArg.order,
          },
        }),
        providesTags: ["uploaded_files"],
      }),
      getUploadedFile: build.query<
        GetUploadedFileApiResponse,
        GetUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files/${queryArg.uploadedFileId}`,
        }),
        providesTags: ["uploaded_files"],
      }),
      reassignUploadedFile: build.mutation<
        ReassignUploadedFileApiResponse,
        ReassignUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/files/${queryArg.uploadedFileId}`,
          method: "PATCH",
          body: queryArg.uploadedFileType,
        }),
        invalidatesTags: ["uploaded_files"],
      }),
      listUploadedFileTypes: build.query<
        ListUploadedFileTypesApiResponse,
        ListUploadedFileTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/types`,
          method: "POST",
          body: queryArg.body,
        }),
        providesTags: ["uploaded_files"],
      }),
      getParsedSearchParameters: build.query<
        GetParsedSearchParametersApiResponse,
        GetParsedSearchParametersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/uploaded_files/${queryArg.tenantName}/parsed/${queryArg.uploadedFileId}/${queryArg.materialSetId}/${queryArg.steelGradeSetId}`,
        }),
        providesTags: ["uploaded_files"],
      }),
      getPerHeatObtainableEstimateFromTypicalConsumption: build.query<
        GetPerHeatObtainableEstimateFromTypicalConsumptionApiResponse,
        GetPerHeatObtainableEstimateFromTypicalConsumptionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/obtainable_estimate/${queryArg.tenantName}/typical_consumption`,
          params: { material_set_id: queryArg.materialSetId },
        }),
        providesTags: ["obtainable_estimates"],
      }),
      getObtainableEstimateFromPurchasingPlan: build.query<
        GetObtainableEstimateFromPurchasingPlanApiResponse,
        GetObtainableEstimateFromPurchasingPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/materials/obtainable_estimate/${queryArg.tenantName}/purchasing_plan`,
          params: {
            material_set_id: queryArg.materialSetId,
            purchasing_plan_file_id: queryArg.purchasingPlanFileId,
          },
        }),
        providesTags: ["obtainable_estimates"],
      }),
      getLayering: build.mutation<GetLayeringApiResponse, GetLayeringApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.planId}/${queryArg.period}/${queryArg.groupId}/${queryArg.mixNumber}`,
          method: "POST",
          body: queryArg.getLayeringBody,
        }),
        invalidatesTags: ["publish"],
      }),
      publishChargeSchedule: build.mutation<
        PublishChargeScheduleApiResponse,
        PublishChargeScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/new`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["publish"],
      }),
      listNewPublishedChargeSchedules: build.query<
        ListNewPublishedChargeSchedulesApiResponse,
        ListNewPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/new`,
        }),
        providesTags: ["publish"],
      }),
      listAllPublishedChargeSchedules: build.query<
        ListAllPublishedChargeSchedulesApiResponse,
        ListAllPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/all`,
          params: { plan_id: queryArg.planId },
        }),
        providesTags: ["publish"],
      }),
      listCustomerPublishedChargeScheduleLayers: build.query<
        ListCustomerPublishedChargeScheduleLayersApiResponse,
        ListCustomerPublishedChargeScheduleLayersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.publishedChargeScheduleId}/layers`,
        }),
        providesTags: ["publish"],
      }),
      listCustomerPublishedChargeScheduleLayersRecent: build.query<
        ListCustomerPublishedChargeScheduleLayersRecentApiResponse,
        ListCustomerPublishedChargeScheduleLayersRecentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/${queryArg.customerChargeScheduleId}/layers/recent`,
        }),
        providesTags: ["publish"],
      }),
      confirmPublishedChargeSchedules: build.mutation<
        ConfirmPublishedChargeSchedulesApiResponse,
        ConfirmPublishedChargeSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/confirm`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["publish"],
      }),
      listConfirmedChargeScheduleMeta: build.query<
        ListConfirmedChargeScheduleMetaApiResponse,
        ListConfirmedChargeScheduleMetaApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/confirmed/${queryArg.planId}`,
        }),
        providesTags: ["publish"],
      }),
      getChargeScheduleMessageTemplate: build.query<
        GetChargeScheduleMessageTemplateApiResponse,
        GetChargeScheduleMessageTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/message-template`,
          params: { plan_id: queryArg.planId, period: queryArg.period },
        }),
        providesTags: ["publish"],
      }),
      sendChargeScheduleMessage: build.mutation<
        SendChargeScheduleMessageApiResponse,
        SendChargeScheduleMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/message`,
          method: "POST",
          body: queryArg.chargeScheduleMessage,
        }),
        invalidatesTags: ["publish"],
      }),
      sendPlanDeployedMessage: build.mutation<
        SendPlanDeployedMessageApiResponse,
        SendPlanDeployedMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/published_charge_schedules/${queryArg.tenantName}/message/${queryArg.planId}`,
          method: "POST",
        }),
        invalidatesTags: ["publish"],
      }),
      listQueuedMessages: build.query<
        ListQueuedMessagesApiResponse,
        ListQueuedMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/messages/${queryArg.tenantName}`,
        }),
        providesTags: ["messages"],
      }),
      confirmMessageSent: build.mutation<
        ConfirmMessageSentApiResponse,
        ConfirmMessageSentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/messages/${queryArg.tenantName}/${queryArg.messageId}`,
          method: "POST",
        }),
        invalidatesTags: ["messages"],
      }),
      createInventorySet: build.mutation<
        CreateInventorySetApiResponse,
        CreateInventorySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/inventory/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.inventorySet,
        }),
        invalidatesTags: ["inventory"],
      }),
      getInventorySet: build.query<
        GetInventorySetApiResponse,
        GetInventorySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/inventory/${queryArg.tenantName}/${queryArg.inventorySetId}`,
        }),
        providesTags: ["inventory"],
      }),
      getInventoryEstimateAtTimestamp: build.query<
        GetInventoryEstimateAtTimestampApiResponse,
        GetInventoryEstimateAtTimestampApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/inventory_estimate/${queryArg.tenantName}`,
          params: {
            material_set_id: queryArg.materialSetId,
            material_physics_set_id: queryArg.materialPhysicsSetId,
            timestamp: queryArg.timestamp,
          },
        }),
        providesTags: ["inventory"],
      }),
      getRecipesImpuritiesPlotRisk: build.query<
        GetRecipesImpuritiesPlotRiskApiResponse,
        GetRecipesImpuritiesPlotRiskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/recipes/impurities/${queryArg.tenantName}/${queryArg.planId}`,
          params: {
            chef_group_id: queryArg.chefGroupId,
            mix_number: queryArg.mixNumber,
            period: queryArg.period,
            language: queryArg.language,
          },
        }),
        providesTags: ["inventory"],
      }),
      createTargetInventorySet: build.mutation<
        CreateTargetInventorySetApiResponse,
        CreateTargetInventorySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_inventory/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.targetInventorySet,
        }),
        invalidatesTags: ["target_inventory"],
      }),
      getTargetInventorySet: build.query<
        GetTargetInventorySetApiResponse,
        GetTargetInventorySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_inventory/${queryArg.tenantName}/${queryArg.targetInventorySetId}`,
        }),
        providesTags: ["target_inventory"],
      }),
      createTargetBasketSet: build.mutation<
        CreateTargetBasketSetApiResponse,
        CreateTargetBasketSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_baskets/${queryArg.tenantName}`,
          method: "POST",
          body: queryArg.targetBasketSetCreate,
        }),
        invalidatesTags: ["target_baskets"],
      }),
      getTargetBasketSet: build.query<
        GetTargetBasketSetApiResponse,
        GetTargetBasketSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/target_baskets/${queryArg.tenantName}/${queryArg.targetBasketSetId}`,
        }),
        providesTags: ["target_baskets"],
      }),
      inviteUsers: build.mutation<InviteUsersApiResponse, InviteUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
          method: "POST",
          body: queryArg.userInvite,
        }),
        invalidatesTags: ["invite", "auth"],
      }),
      getPendingInvites: build.query<
        GetPendingInvitesApiResponse,
        GetPendingInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
        }),
        providesTags: ["invite", "auth"],
      }),
      deleteUserInvite: build.mutation<
        DeleteUserInviteApiResponse,
        DeleteUserInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/invite`,
          method: "DELETE",
          params: { email: queryArg.email },
        }),
        invalidatesTags: ["invite", "auth"],
      }),
      getVideoUrl: build.query<GetVideoUrlApiResponse, GetVideoUrlApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/get_video_url/${queryArg.videoKey}/${queryArg.camera}`,
          params: { downsampled: queryArg.downsampled },
        }),
        providesTags: ["video_labelling"],
      }),
      suggestVideo: build.query<SuggestVideoApiResponse, SuggestVideoApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/suggest_video`,
          params: { required_materials: queryArg.requiredMaterials },
        }),
        providesTags: ["video_labelling"],
      }),
      readVideoLabels: build.query<
        ReadVideoLabelsApiResponse,
        ReadVideoLabelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/read_video_labels`,
          params: { video_key: queryArg.videoKey },
        }),
        providesTags: ["video_labelling"],
      }),
      writeVideoLabels: build.mutation<
        WriteVideoLabelsApiResponse,
        WriteVideoLabelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/write_video_labels`,
          method: "POST",
          body: queryArg.videoLabels,
        }),
        invalidatesTags: ["video_labelling"],
      }),
      getRailcars: build.query<GetRailcarsApiResponse, GetRailcarsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/video_labelling/${queryArg.tenantName}/railcars/${queryArg.key}`,
        }),
        providesTags: ["video_labelling"],
      }),
      getMixMaterialLimitSet: build.query<
        GetMixMaterialLimitSetApiResponse,
        GetMixMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/mix_material_limit_set/${queryArg.mixMaterialLimitSetId}`,
          params: { chef_group_id: queryArg.chefGroupId },
        }),
        providesTags: ["mix_material_limits"],
      }),
      updateSearchMixMaterialLimitSet: build.mutation<
        UpdateSearchMixMaterialLimitSetApiResponse,
        UpdateSearchMixMaterialLimitSetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/search/${queryArg.tenantName}/${queryArg.searchId}/mix_material_limit_set`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["mix_material_limits"],
      }),
      getPicks: build.query<GetPicksApiResponse, GetPicksApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking/${queryArg.tenantName}/picks/${queryArg.year}/${queryArg.month}/${queryArg.day}`,
        }),
        providesTags: ["scrap_tracking"],
      }),
      getHeats: build.query<GetHeatsApiResponse, GetHeatsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/scrap_tracking/${queryArg.tenantName}/heats/${queryArg.year}/${queryArg.month}/${queryArg.day}`,
        }),
        providesTags: ["scrap_tracking"],
      }),
      listAutoRecipeStrategies: build.query<
        ListAutoRecipeStrategiesApiResponse,
        ListAutoRecipeStrategiesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/auto_recipe_strategies`,
        }),
        providesTags: ["simulator"],
      }),
      listAutoRecipeEstimators: build.query<
        ListAutoRecipeEstimatorsApiResponse,
        ListAutoRecipeEstimatorsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/auto_recipe_estimators`,
        }),
        providesTags: ["simulator"],
      }),
      listSimulatorDataLoaders: build.query<
        ListSimulatorDataLoadersApiResponse,
        ListSimulatorDataLoadersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulator_data_loaders`,
        }),
        providesTags: ["simulator"],
      }),
      getSimulatorRun: build.query<
        GetSimulatorRunApiResponse,
        GetSimulatorRunApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulation/${queryArg.simulatorRunId}`,
        }),
        providesTags: ["simulator"],
      }),
      simulate: build.mutation<SimulateApiResponse, SimulateApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/simulator/${queryArg.tenantName}/simulation`,
          method: "POST",
          body: queryArg.simulatorRunCreate,
        }),
        invalidatesTags: ["simulator"],
      }),
      getMe: build.query<GetMeApiResponse, GetMeApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/me`,
        }),
        providesTags: ["auth"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/users`,
        }),
        providesTags: ["auth"],
      }),
      getUserByEmail: build.query<
        GetUserByEmailApiResponse,
        GetUserByEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/user`,
          params: { email: queryArg.email },
        }),
        providesTags: ["auth"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/auth/${queryArg.tenantName}/user`,
          method: "PATCH",
          body: queryArg.userUpdate,
        }),
        invalidatesTags: ["auth"],
      }),
      syncWithFirebase: build.mutation<
        SyncWithFirebaseApiResponse,
        SyncWithFirebaseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v2/auth/users/${queryArg.tenantName}/sync`,
          method: "POST",
        }),
        invalidatesTags: ["auth"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as generatedApi };
export type GetTenantMetadataApiResponse =
  /** status 200 Successful Response */ Tenant;
export type GetTenantMetadataApiArg = {
  tenantName: string;
};
export type ListMiscParamsSetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListMiscParamsSetsApiArg = {
  tenantName: string;
};
export type CreateMiscParamsSetApiResponse =
  /** status 200 Successful Response */ MiscParamsSetRead;
export type CreateMiscParamsSetApiArg = {
  tenantName: string;
  miscParamsSetCreate: MiscParamsSetCreate;
};
export type GetMiscParamsSetApiResponse =
  /** status 200 Successful Response */ MiscParamsSetRead;
export type GetMiscParamsSetApiArg = {
  tenantName: string;
  miscParamsSetId: number | Default;
};
export type SetDefaultMiscParamsSetApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultMiscParamsSetApiArg = {
  tenantName: string;
  miscParamsSetId: number;
};
export type GetSimilarChargedRecipesApiResponse =
  /** status 200 Successful Response */ SimilarChargedRecipe[];
export type GetSimilarChargedRecipesApiArg = {
  tenantName: string;
  similarChargedRecipesParams: SimilarChargedRecipesParams;
};
export type CreateAutoRecipeApiResponse =
  /** status 200 Successful Response */ Search;
export type CreateAutoRecipeApiArg = {
  tenantName: string;
  autoRecipeType: SearchType;
  viewFromTimestamp?: string;
  /** Whether to immediately mark the search session as deleted */
  deleted?: boolean;
};
export type ListChefGroupsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListChefGroupsApiArg = {
  tenantName: string;
};
export type CreateChefGroupingApiResponse =
  /** status 200 Successful Response */ ChefGroupingRead;
export type CreateChefGroupingApiArg = {
  tenantName: string;
  chefGroupingCreate: ChefGroupingCreate;
};
export type GetChefGroupingApiResponse =
  /** status 200 Successful Response */ ChefGroupingRead;
export type GetChefGroupingApiArg = {
  tenantName: string;
  chefGroupingId: number | Default;
};
export type SetDefaultChefGroupingApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultChefGroupingApiArg = {
  tenantName: string;
  chefGroupingId: number;
};
export type ListSteelGradesApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListSteelGradesApiArg = {
  tenantName: string;
};
export type CreateSteelGradeSetApiResponse =
  /** status 200 Successful Response */ SteelGradeSetRead;
export type CreateSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetCreate: SteelGradeSetCreate;
};
export type GetSteelGradeSetApiResponse =
  /** status 200 Successful Response */ SteelGradeSetRead;
export type GetSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetId: number | Default;
};
export type SetDefaultSteelGradeSetApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultSteelGradeSetApiArg = {
  tenantName: string;
  steelGradeSetId: number;
};
export type ListSteelGradePlansApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListSteelGradePlansApiArg = {
  tenantName: string;
};
export type CreateSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type CreateSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanCreate: SteelGradePlanCreate;
};
export type GetSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type GetSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanId: number | Default;
};
export type SetDefaultSteelGradePlanApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanId: number;
};
export type GetSteelGradePlanFromFileApiResponse =
  /** status 200 Successful Response */ FileSteelGradePlan;
export type GetSteelGradePlanFromFileApiArg = {
  tenantName: string;
  planFileId: number;
  steelGradeSetId: number | null;
};
export type GetTypicalSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SteelGradePlanRead;
export type GetTypicalSteelGradePlanApiArg = {
  tenantName: string;
  steelGradeSetId: number;
  numHeats: number;
};
export type ListBasketsApiResponse =
  /** status 200 Successful Response */ BasketRead[];
export type ListBasketsApiArg = {
  tenantName: string;
};
export type ListBasketLayersApiResponse =
  /** status 200 Successful Response */ BasketLayerRead[];
export type ListBasketLayersApiArg = {
  tenantName: string;
};
export type CreateBasketConstraintSetApiResponse =
  /** status 200 Successful Response */ BasketConstraintSetRead;
export type CreateBasketConstraintSetApiArg = {
  tenantName: string;
  basketConstraintSetCreate: BasketConstraintSetCreate;
};
export type ListBasketConstraintSetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListBasketConstraintSetsApiArg = {
  tenantName: string;
};
export type GetBasketConstraintSetApiResponse =
  /** status 200 Successful Response */ BasketConstraintSetRead;
export type GetBasketConstraintSetApiArg = {
  tenantName: string;
  basketConstraintSetId: number | Default;
};
export type SetDefaultBasketConstraintSetApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultBasketConstraintSetApiArg = {
  tenantName: string;
  basketConstraintSetId: number;
};
export type GetFormattedBasketConstraintsApiResponse =
  /** status 200 Successful Response */ FormattedBasketConstraint[];
export type GetFormattedBasketConstraintsApiArg = {
  tenantName: string;
};
export type CreateBasketMaterialAdditionApiResponse =
  /** status 204 Successful Response */ undefined;
export type CreateBasketMaterialAdditionApiArg = {
  tenantName: string;
  planId: number;
  basketMaterialAdditionCreate: BasketMaterialAdditionCreate;
};
export type UpdateSearchChefGroupBasketMaterialLimitsApiResponse =
  /** status 200 Successful Response */ number;
export type UpdateSearchChefGroupBasketMaterialLimitsApiArg = {
  tenantName: string;
  searchId: number;
  chefGroupBasketMaterialLimitSetCreate: ChefGroupBasketMaterialLimitSetCreate;
};
export type GetChefGroupBasketMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ ChefGroupBasketMaterialLimitSetRead;
export type GetChefGroupBasketMaterialLimitSetApiArg = {
  tenantName: string;
  chefGroupBasketMaterialLimitSetId: number;
};
export type ListChemicalElementsApiResponse =
  /** status 200 Successful Response */ ChemicalElement[];
export type ListChemicalElementsApiArg = {
  tenantName: string;
};
export type ListMaterialSetsApiResponse =
  /** status 200 Successful Response */ MaterialSet[];
export type ListMaterialSetsApiArg = {
  tenantName: string;
};
export type GetMaterialSetApiResponse =
  /** status 200 Successful Response */ MaterialSet;
export type GetMaterialSetApiArg = {
  tenantName: string;
  materialSetId: number | Default;
};
export type ListAllMaterialsApiResponse =
  /** status 200 Successful Response */ MaterialRead[];
export type ListAllMaterialsApiArg = {
  tenantName: string;
};
export type ListMaterialsApiResponse =
  /** status 200 Successful Response */ MaterialRead[];
export type ListMaterialsApiArg = {
  tenantName: string;
  materialSetId: number | Default;
};
export type ListMaterialConstraintClassesApiResponse =
  /** status 200 Successful Response */ MaterialConstraintClass[];
export type ListMaterialConstraintClassesApiArg = {
  tenantName: string;
  materialSetId: number;
  materialPhysicsSetId: number;
};
export type GetFullMaterialsApiResponse =
  /** status 200 Successful Response */ MaterialPropertiesView[];
export type GetFullMaterialsApiArg = {
  tenantName: string;
  materialSetId: number;
  materialPhysicsSetId: number;
  materialChemistrySetId: number;
};
export type CreateMaterialChemistrySetApiResponse =
  /** status 200 Successful Response */ MaterialChemistrySetRead;
export type CreateMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetCreate: MaterialChemistrySetCreate;
};
export type ListMaterialChemistrySetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListMaterialChemistrySetsApiArg = {
  tenantName: string;
};
export type GetMaterialChemistrySetApiResponse =
  /** status 200 Successful Response */ MaterialChemistrySetRead;
export type GetMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetId: number | Default;
};
export type SetDefaultMaterialChemistrySetApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultMaterialChemistrySetApiArg = {
  tenantName: string;
  materialChemistrySetId: number;
};
export type CreateMaterialPhysicsSetApiResponse =
  /** status 200 Successful Response */ MaterialPhysicsSetRead;
export type CreateMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetCreate: MaterialPhysicsSetCreate;
};
export type ListMaterialPhysicsSetsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListMaterialPhysicsSetsApiArg = {
  tenantName: string;
};
export type GetMaterialPhysicsSetApiResponse =
  /** status 200 Successful Response */ MaterialPhysicsSetRead;
export type GetMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetId: number | Default;
};
export type SetDefaultMaterialPhysicsSetApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultMaterialPhysicsSetApiArg = {
  tenantName: string;
  materialPhysicsSetId: number;
};
export type GetMaterialPricesApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: number;
  };
export type GetMaterialPricesApiArg = {
  tenantName: string;
};
export type GetMaterialNameMappingsApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string;
  };
export type GetMaterialNameMappingsApiArg = {
  tenantName: string;
};
export type GetTenantContactsApiResponse =
  /** status 200 Successful Response */ string[];
export type GetTenantContactsApiArg = {
  tenantName: string;
};
export type GetPlanMixesApiResponse =
  /** status 200 Successful Response */ NamedPlanMixes;
export type GetPlanMixesApiArg = {
  tenantName: string;
  planId: number | Latest;
  period: number;
};
export type GetPlanMixesExcelApiResponse =
  /** status 200 Successful Response */ Blob;
export type GetPlanMixesExcelApiArg = {
  tenantName: string;
  planId: number;
  language: SupportedLanguage;
  period?: number | null;
};
export type SendDeployedMixesEmailApiResponse =
  /** status 204 Successful Response */ undefined;
export type SendDeployedMixesEmailApiArg = {
  tenantName: string;
  planId: number;
  language: SupportedLanguage;
};
export type ListChargeBucketDropWeightsRecentDeprecatedApiResponse =
  /** status 200 Successful Response */ DropWeightDeprecated[];
export type ListChargeBucketDropWeightsRecentDeprecatedApiArg = {
  tenantName: string;
  chargeBucketId: CustomerBucketId;
};
export type ListChargeBucketDropWeightsRecentApiResponse =
  /** status 200 Successful Response */ DropWeight[];
export type ListChargeBucketDropWeightsRecentApiArg = {
  tenantName: string;
  chargeBucketId: CustomerBucketId;
};
export type GetObtainableBundleItemSetApiResponse =
  /** status 200 Successful Response */ ObtainableBundleItemSet;
export type GetObtainableBundleItemSetApiArg = {
  tenantName: string;
  obtainableBundleSetId: number;
  materialSetId?: number | null;
};
export type CreateObtainableBundleItemSetApiResponse =
  /** status 200 Successful Response */ ObtainableBundleItemSet;
export type CreateObtainableBundleItemSetApiArg = {
  tenantName: string;
  obtainableBundleItemSetCreate: ObtainableBundleItemSetCreate;
};
export type ListMaterialBundlesApiResponse =
  /** status 200 Successful Response */ MaterialBundleRead[];
export type ListMaterialBundlesApiArg = {
  tenantName: string;
};
export type CreateMaterialBundleApiResponse =
  /** status 200 Successful Response */ MaterialBundleRead;
export type CreateMaterialBundleApiArg = {
  tenantName: string;
  materialBundleCreate: MaterialBundleCreate;
};
export type GetMaterialBundleApiResponse =
  /** status 200 Successful Response */ MaterialBundleRead;
export type GetMaterialBundleApiArg = {
  tenantName: string;
  materialBundleId: number;
};
export type ListProductGroupingsApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListProductGroupingsApiArg = {
  tenantName: string;
};
export type CreateProductGroupingApiResponse =
  /** status 200 Successful Response */ ProductGroupingRead;
export type CreateProductGroupingApiArg = {
  tenantName: string;
  productGroupingCreate: ProductGroupingCreate;
};
export type GetProductGroupingApiResponse =
  /** status 200 Successful Response */ ProductGroupingRead;
export type GetProductGroupingApiArg = {
  tenantName: string;
  productGroupingId: number | Default;
};
export type SetDefaultProductGroupingApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultProductGroupingApiArg = {
  tenantName: string;
  productGroupingId: number;
};
export type ListProductGroupPlansApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListProductGroupPlansApiArg = {
  tenantName: string;
};
export type CreateProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type CreateProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanCreate: ProductGroupPlanCreate;
};
export type GetProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type GetProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanId: number | Default;
};
export type SetDefaultProductGroupPlanApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanId: number;
};
export type GetTypicalProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductGroupPlanRead;
export type GetTypicalProductGroupPlanApiArg = {
  tenantName: string;
  productGroupingId: number;
  numHeats: number;
};
export type ListProductionPlansApiResponse =
  /** status 200 Successful Response */ Metadata[];
export type ListProductionPlansApiArg = {
  tenantName: string;
};
export type CreateProductionPlanApiResponse =
  /** status 200 Successful Response */ ProductionPlanRead;
export type CreateProductionPlanApiArg = {
  tenantName: string;
  productionPlanCreate: ProductionPlanCreate;
};
export type GetProductionPlanApiResponse =
  /** status 200 Successful Response */ ProductionPlanRead;
export type GetProductionPlanApiArg = {
  tenantName: string;
  productionPlanId: number | Default;
};
export type SetDefaultProductionPlanApiResponse =
  /** status 204 Successful Response */ undefined;
export type SetDefaultProductionPlanApiArg = {
  tenantName: string;
  productionPlanId: number;
};
export type GetSourcedProductionPlanApiResponse =
  /** status 200 Successful Response */ SourcedProductionPlan;
export type GetSourcedProductionPlanApiArg = {
  tenantName: string;
  productionPlanId: number;
  targetSteelGradeSetId?: number | null;
  targetChefGroupingId?: number | null;
  targetProductGroupingId?: number | null;
};
export type CreateSourcedProductionPlanFromSteelGradePlanApiResponse =
  /** status 200 Successful Response */ SourcedProductionPlan;
export type CreateSourcedProductionPlanFromSteelGradePlanApiArg = {
  tenantName: string;
  sourcedProductionPlanFromSteelGradePlan: SourcedProductionPlanFromSteelGradePlan;
};
export type CreateSourcedProductionPlanFromProductGroupPlanApiResponse =
  /** status 200 Successful Response */ SourcedProductionPlan;
export type CreateSourcedProductionPlanFromProductGroupPlanApiArg = {
  tenantName: string;
  sourcedProductionPlanFromProductGroupPlan: SourcedProductionPlanFromProductGroupPlan;
};
export type GetProductionPlanFromFileApiResponse =
  /** status 200 Successful Response */ FileProductionPlan;
export type GetProductionPlanFromFileApiArg = {
  tenantName: string;
  planFileId: number;
  chefGroupingId: number | null;
};
export type GetProductionPlanFromProductGroupPlanApiResponse =
  /** status 200 Successful Response */ ProductionPlanRead;
export type GetProductionPlanFromProductGroupPlanApiArg = {
  tenantName: string;
  productGroupPlanId: number;
  chefGroupingId: number;
};
export type GetProductionPlanFromSteelGradePlanApiResponse =
  /** status 200 Successful Response */ ProductionPlanRead;
export type GetProductionPlanFromSteelGradePlanApiArg = {
  tenantName: string;
  steelGradePlanId: number;
  chefGroupingId: number;
};
export type GetTypicalProductionPlanApiResponse =
  /** status 200 Successful Response */ ProductionPlanRead;
export type GetTypicalProductionPlanApiArg = {
  tenantName: string;
  chefGroupingId: number;
  numHeats: number;
};
export type ListSearchesApiResponse =
  /** status 200 Successful Response */ SearchSummary[];
export type ListSearchesApiArg = {
  tenantName: string;
  ordering: Ordering;
  sessionId?: number | null;
  limit?: number | null;
};
export type CreateSearchApiResponse =
  /** status 200 Successful Response */ Search;
export type CreateSearchApiArg = {
  tenantName: string;
  createSearch: CreateSearch;
};
export type SendSearchNotificationApiResponse =
  /** status 204 Successful Response */ undefined;
export type SendSearchNotificationApiArg = {
  tenantName: string;
  searchId: number;
};
export type CreateSearchFromContextApiResponse =
  /** status 200 Successful Response */ Search;
export type CreateSearchFromContextApiArg = {
  tenantName: string;
  searchId: number;
};
export type RunSearchApiResponse =
  /** status 200 Successful Response */ SearchResult;
export type RunSearchApiArg = {
  tenantName: string;
  searchId: number;
  body: RunSearchOptions | null;
};
export type GetSearchApiResponse =
  /** status 200 Successful Response */ SyncedSearchParameters;
export type GetSearchApiArg = {
  tenantName: string;
  searchId: number;
};
export type CreateSearchFromParametersApiResponse =
  /** status 200 Successful Response */ SyncedSearchParameters;
export type CreateSearchFromParametersApiArg = {
  tenantName: string;
  syncedSearchParametersInput: SyncedSearchParameters2;
};
export type GetSearchContextApiResponse =
  /** status 200 Successful Response */ SyncedSearchContext;
export type GetSearchContextApiArg = {
  tenantName: string;
  searchContextId: number;
};
export type GetNameBasedSearchApiResponse =
  /** status 200 Successful Response */ NameBasedSearch;
export type GetNameBasedSearchApiArg = {
  tenantName: string;
  searchId: number;
};
export type CreateSearchContextGroupApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type CreateSearchContextGroupApiArg = {
  tenantName: string;
  searchContextGroupCreate: SearchContextGroupCreate;
};
export type ListSearchContextGroupsApiResponse =
  /** status 200 Successful Response */ SearchContextGroupSummary[];
export type ListSearchContextGroupsApiArg = {
  tenantName: string;
};
export type GetSearchContextGroupApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type GetSearchContextGroupApiArg = {
  tenantName: string;
  searchContextGroupId: number;
};
export type UpdateSearchContextGroupMixMaterialLimitsApiResponse =
  /** status 200 Successful Response */ SearchContextGroup;
export type UpdateSearchContextGroupMixMaterialLimitsApiArg = {
  tenantName: string;
  searchContextGroupId: number;
  searchContextId: number;
  body: MixMaterialLimitCreate[];
};
export type LoadExperimentalSearchApiResponse =
  /** status 200 Successful Response */ ExperimentalSearch;
export type LoadExperimentalSearchApiArg = {
  tenantName: string;
  experimentalSearchId: number;
};
export type SyncExperimentalSearchApiResponse =
  /** status 200 Successful Response */ ExperimentalSearch;
export type SyncExperimentalSearchApiArg = {
  tenantName: string;
  experimentalSearchCreate: ExperimentalSearchCreate;
};
export type RunExperimentalSearchApiResponse =
  /** status 200 Successful Response */ number;
export type RunExperimentalSearchApiArg = {
  tenantName: string;
  optimiserInputs: OptimiserInputs;
};
export type ImportInputsFromFileApiResponse =
  /** status 200 Successful Response */ ImportedExperimentalInputs;
export type ImportInputsFromFileApiArg = {
  tenantName: string;
  contextId: number;
  uploadedFileCreate: UploadedFileCreate;
};
export type ImportInputsFromTextApiResponse =
  /** status 200 Successful Response */ ImportedExperimentalInputs;
export type ImportInputsFromTextApiArg = {
  tenantName: string;
  importedText: ImportedText;
};
export type ImportMixAllocationFromPlanApiResponse =
  /** status 200 Successful Response */ ImportedMixAllocation[];
export type ImportMixAllocationFromPlanApiArg = {
  tenantName: string;
  planId: number;
  body: OptimiserProduction[];
};
export type ImportMixAllocationFromFileApiResponse =
  /** status 200 Successful Response */ ImportedMixAllocation[];
export type ImportMixAllocationFromFileApiArg = {
  tenantName: string;
  importMixAllocationBody: ImportMixAllocationBody;
};
export type GetExperimentalSessionsApiResponse =
  /** status 200 Successful Response */ ExperimentalSessionHeader[];
export type GetExperimentalSessionsApiArg = {
  tenantName: string;
};
export type GetExperimentalSearchesApiResponse =
  /** status 200 Successful Response */ ExperimentalSearchHeader[];
export type GetExperimentalSearchesApiArg = {
  tenantName: string;
  sessionId: number;
};
export type ListDeployedPlansApiResponse =
  /** status 200 Successful Response */ DeployedPlanRead[];
export type ListDeployedPlansApiArg = {
  tenantName: string;
};
export type SendPlanNotificationApiResponse =
  /** status 204 Successful Response */ undefined;
export type SendPlanNotificationApiArg = {
  tenantName: string;
  planId: number;
  planName: string;
};
export type GetContextForPlanApiResponse =
  /** status 200 Successful Response */ SyncedSearchContext;
export type GetContextForPlanApiArg = {
  tenantName: string;
  planId: number;
};
export type GetParametersForPlanApiResponse =
  /** status 200 Successful Response */ SyncedSearchParameters;
export type GetParametersForPlanApiArg = {
  tenantName: string;
  planId: number;
};
export type ListSessionsApiResponse =
  /** status 200 Successful Response */ SessionSummary[];
export type ListSessionsApiArg = {
  tenantName: string;
};
export type RenameSessionApiResponse =
  /** status 204 Successful Response */ undefined;
export type RenameSessionApiArg = {
  tenantName: string;
  sessionId: number;
  renameSession: RenameSession;
};
export type DeleteSessionApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteSessionApiArg = {
  tenantName: string;
  sessionId: number;
};
export type CopySearchApiResponse =
  /** status 200 Successful Response */ SyncedSearchParameters;
export type CopySearchApiArg = {
  tenantName: string;
  sessionId: number;
  copySearch: CopySearch;
};
export type GetResultApiResponse =
  /** status 200 Successful Response */ SearchResult;
export type GetResultApiArg = {
  tenantName: string;
  searchId: number;
};
export type SaveUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta;
export type SaveUploadedFileApiArg = {
  tenantName: string;
  uploadedFileCreate: UploadedFileCreate;
};
export type ListUploadedFilesApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta[];
export type ListUploadedFilesApiArg = {
  tenantName: string;
  types?: UploadedFileType[] | null;
  deleted?: boolean | null;
  limit?: number | null;
  order?: Ordering;
};
export type GetUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileRead;
export type GetUploadedFileApiArg = {
  tenantName: string;
  uploadedFileId: number;
};
export type ReassignUploadedFileApiResponse =
  /** status 200 Successful Response */ UploadedFileMeta;
export type ReassignUploadedFileApiArg = {
  tenantName: string;
  uploadedFileId: number;
  uploadedFileType: UploadedFileType;
};
export type ListUploadedFileTypesApiResponse =
  /** status 200 Successful Response */ UploadedFileType[];
export type ListUploadedFileTypesApiArg = {
  tenantName: string;
  body: UploadedFileOutputTypeList | null;
};
export type GetParsedSearchParametersApiResponse =
  /** status 200 Successful Response */ UploadedSearchParameters;
export type GetParsedSearchParametersApiArg = {
  tenantName: string;
  uploadedFileId: number;
  materialSetId: number;
  steelGradeSetId: number;
};
export type GetPerHeatObtainableEstimateFromTypicalConsumptionApiResponse =
  /** status 200 Successful Response */ ObtainableEstimate[];
export type GetPerHeatObtainableEstimateFromTypicalConsumptionApiArg = {
  tenantName: string;
  materialSetId: number;
};
export type GetObtainableEstimateFromPurchasingPlanApiResponse =
  /** status 200 Successful Response */ ObtainableEstimate[];
export type GetObtainableEstimateFromPurchasingPlanApiArg = {
  tenantName: string;
  materialSetId: number;
  purchasingPlanFileId: number;
};
export type GetLayeringApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleCreate;
export type GetLayeringApiArg = {
  tenantName: string;
  planId: number;
  period: number;
  groupId: number;
  mixNumber: number;
  getLayeringBody: GetLayeringBody;
};
export type PublishChargeScheduleApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleRead[];
export type PublishChargeScheduleApiArg = {
  tenantName: string;
  body: PublishedChargeScheduleCreate[];
};
export type ListNewPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleRead[];
export type ListNewPublishedChargeSchedulesApiArg = {
  tenantName: string;
};
export type ListAllPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ PublishedChargeScheduleRead[];
export type ListAllPublishedChargeSchedulesApiArg = {
  tenantName: string;
  planId?: number | null;
};
export type ListCustomerPublishedChargeScheduleLayersApiResponse =
  /** status 200 Successful Response */ CustomerPublishedChargeScheduleLayer[];
export type ListCustomerPublishedChargeScheduleLayersApiArg = {
  tenantName: string;
  publishedChargeScheduleId: number;
};
export type ListCustomerPublishedChargeScheduleLayersRecentApiResponse =
  /** status 200 Successful Response */ CustomerPublishedChargeScheduleLayer[];
export type ListCustomerPublishedChargeScheduleLayersRecentApiArg = {
  tenantName: string;
  customerChargeScheduleId: string;
};
export type ConfirmPublishedChargeSchedulesApiResponse =
  /** status 200 Successful Response */ string;
export type ConfirmPublishedChargeSchedulesApiArg = {
  tenantName: string;
  body: ChargeScheduleConfirm[];
};
export type ListConfirmedChargeScheduleMetaApiResponse =
  /** status 200 Successful Response */ ConfirmedChargeScheduleMeta[];
export type ListConfirmedChargeScheduleMetaApiArg = {
  tenantName: string;
  planId: number;
};
export type GetChargeScheduleMessageTemplateApiResponse =
  /** status 200 Successful Response */ ChargeScheduleMessage;
export type GetChargeScheduleMessageTemplateApiArg = {
  tenantName: string;
  planId: number;
  period: number;
};
export type SendChargeScheduleMessageApiResponse =
  /** status 204 Successful Response */ undefined;
export type SendChargeScheduleMessageApiArg = {
  tenantName: string;
  chargeScheduleMessage: ChargeScheduleMessage;
};
export type SendPlanDeployedMessageApiResponse =
  /** status 204 Successful Response */ undefined;
export type SendPlanDeployedMessageApiArg = {
  tenantName: string;
  planId: number;
};
export type ListQueuedMessagesApiResponse =
  /** status 200 Successful Response */ Message[];
export type ListQueuedMessagesApiArg = {
  tenantName: string;
};
export type ConfirmMessageSentApiResponse =
  /** status 200 Successful Response */ string;
export type ConfirmMessageSentApiArg = {
  tenantName: string;
  messageId: number;
};
export type CreateInventorySetApiResponse =
  /** status 200 Successful Response */ InventorySet;
export type CreateInventorySetApiArg = {
  tenantName: string;
  inventorySet: InventorySet;
};
export type GetInventorySetApiResponse =
  /** status 200 Successful Response */ InventorySet;
export type GetInventorySetApiArg = {
  tenantName: string;
  inventorySetId: number;
};
export type GetInventoryEstimateAtTimestampApiResponse =
  /** status 200 Successful Response */ InventoryItem[];
export type GetInventoryEstimateAtTimestampApiArg = {
  tenantName: string;
  materialSetId: number;
  materialPhysicsSetId: number;
  timestamp: string;
};
export type GetRecipesImpuritiesPlotRiskApiResponse =
  /** status 200 Successful Response */ string;
export type GetRecipesImpuritiesPlotRiskApiArg = {
  tenantName: string;
  planId: number;
  chefGroupId: number;
  mixNumber: number;
  period: number;
  language: SupportedLanguage;
};
export type CreateTargetInventorySetApiResponse =
  /** status 200 Successful Response */ TargetInventorySet;
export type CreateTargetInventorySetApiArg = {
  tenantName: string;
  targetInventorySet: TargetInventorySet;
};
export type GetTargetInventorySetApiResponse =
  /** status 200 Successful Response */ TargetInventorySet;
export type GetTargetInventorySetApiArg = {
  tenantName: string;
  targetInventorySetId: number;
};
export type CreateTargetBasketSetApiResponse =
  /** status 200 Successful Response */ TargetBasketSetRead;
export type CreateTargetBasketSetApiArg = {
  tenantName: string;
  targetBasketSetCreate: TargetBasketSetCreate;
};
export type GetTargetBasketSetApiResponse =
  /** status 200 Successful Response */ TargetBasketSetRead;
export type GetTargetBasketSetApiArg = {
  tenantName: string;
  targetBasketSetId: number | Default;
};
export type InviteUsersApiResponse =
  /** status 204 Successful Response */ undefined;
export type InviteUsersApiArg = {
  tenantName: string;
  userInvite: UserInvite;
};
export type GetPendingInvitesApiResponse =
  /** status 200 Successful Response */ InvitedUser[];
export type GetPendingInvitesApiArg = {
  tenantName: string;
};
export type DeleteUserInviteApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteUserInviteApiArg = {
  tenantName: string;
  email: string;
};
export type GetVideoUrlApiResponse = /** status 200 Successful Response */
  | string
  | null;
export type GetVideoUrlApiArg = {
  tenantName: string;
  videoKey: string;
  camera: number;
  downsampled: boolean;
};
export type SuggestVideoApiResponse = /** status 200 Successful Response */
  | string
  | null;
export type SuggestVideoApiArg = {
  tenantName: string;
  requiredMaterials: string[];
};
export type ReadVideoLabelsApiResponse =
  /** status 200 Successful Response */ VideoLabels | null;
export type ReadVideoLabelsApiArg = {
  tenantName: string;
  videoKey: string;
};
export type WriteVideoLabelsApiResponse =
  /** status 200 Successful Response */ string;
export type WriteVideoLabelsApiArg = {
  tenantName: string;
  videoLabels: VideoLabels;
};
export type GetRailcarsApiResponse =
  /** status 200 Successful Response */ string;
export type GetRailcarsApiArg = {
  tenantName: string;
  key: string;
};
export type GetMixMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ MixMaterialLimitSetRead;
export type GetMixMaterialLimitSetApiArg = {
  tenantName: string;
  mixMaterialLimitSetId: number;
  chefGroupId?: number | null;
};
export type UpdateSearchMixMaterialLimitSetApiResponse =
  /** status 200 Successful Response */ number;
export type UpdateSearchMixMaterialLimitSetApiArg = {
  tenantName: string;
  searchId: number;
  body: MixMaterialLimitCreate[];
};
export type GetPicksApiResponse =
  /** status 200 Successful Response */ RealisedPick[];
export type GetPicksApiArg = {
  tenantName: string;
  year: number;
  month: number;
  day: number;
};
export type GetHeatsApiResponse =
  /** status 200 Successful Response */ PlannedHeat[];
export type GetHeatsApiArg = {
  tenantName: string;
  year: number;
  month: number;
  day: number;
};
export type ListAutoRecipeStrategiesApiResponse =
  /** status 200 Successful Response */ AutoRecipeStrategySpecs;
export type ListAutoRecipeStrategiesApiArg = {
  tenantName: string;
};
export type ListAutoRecipeEstimatorsApiResponse =
  /** status 200 Successful Response */ AutoRecipeEstimatorsSpecs;
export type ListAutoRecipeEstimatorsApiArg = {
  tenantName: string;
};
export type ListSimulatorDataLoadersApiResponse =
  /** status 200 Successful Response */ SimulatorDataLoadersSpecs;
export type ListSimulatorDataLoadersApiArg = {
  tenantName: string;
};
export type GetSimulatorRunApiResponse =
  /** status 200 Successful Response */ SimulatorRunRead;
export type GetSimulatorRunApiArg = {
  tenantName: string;
  simulatorRunId: number;
};
export type SimulateApiResponse =
  /** status 200 Successful Response */ SimulatorRunRead;
export type SimulateApiArg = {
  tenantName: string;
  simulatorRunCreate: SimulatorRunCreate;
};
export type GetMeApiResponse = /** status 200 Successful Response */ UserRead;
export type GetMeApiArg = {
  tenantName: string;
};
export type GetUsersApiResponse =
  /** status 200 Successful Response */ UserRead[];
export type GetUsersApiArg = {
  /** Tenant name */
  tenantName: string;
};
export type GetUserByEmailApiResponse =
  /** status 200 Successful Response */ UserRead;
export type GetUserByEmailApiArg = {
  /** Tenant name */
  tenantName: string;
  email: string;
};
export type UpdateUserApiResponse =
  /** status 200 Successful Response */ UserRead;
export type UpdateUserApiArg = {
  tenantName: string;
  userUpdate: UserUpdate;
};
export type SyncWithFirebaseApiResponse =
  /** status 204 Successful Response */ undefined;
export type SyncWithFirebaseApiArg = {
  tenantName: string;
};
export type MixBreakdown = "percentage" | "mass";
export type ProductionPlanSource = "product_groups" | "steel_grades";
export type SearchType =
  | "charging"
  | "medium_term_charging"
  | "purchasing"
  | "simulator";
export type FeatureFlag =
  | "material_availability_import"
  | "search_context_groups"
  | "electrical_energy_price"
  | "plan_excel_download"
  | "inventory_estimate_at_timestamp"
  | "manual_entry_crane_ui";
export type DeployProcess = "sync_and_sms" | "email" | "message" | "none";
export type BasketTabType = "basket_plot" | "layering_table";
export type UnitType =
  | "mass"
  | "cost"
  | "specific_cost"
  | "electrical_energy_consumption"
  | "mass_fraction"
  | "yield"
  | "volume"
  | "density"
  | "energy"
  | "specific_energy_cost";
export type UnitSpec = {
  unit_type: UnitType;
  units: string;
};
export type Tenant = {
  tenant_name: string;
  long_name: string;
  typical_heats_per_day: number;
  created_at: string;
  default_mix_breakdown: MixBreakdown;
  default_production_plan_source: ProductionPlanSource;
  auto_search_types: SearchType[];
  feature_flags: FeatureFlag[];
  deploy_process: DeployProcess;
  basket_tab_type: BasketTabType;
  units: UnitSpec[];
  translation_overrides: {
    [key: string]: string;
  };
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type Metadata = {
  id: number;
  name: string;
  created_at: string;
  created_by_id: string;
  default?: boolean | null;
};
export type MassDisplaySummaryType = "tapped" | "billet";
export type MiscParams = {
  allow_obtainable_during_plan: boolean;
  recipe_decimal_places: number;
  mass_display_summary_type: MassDisplaySummaryType;
  integer_solution: boolean;
  uniform_deployable_mixes: boolean;
  optimisation_time_limit?: number | null;
};
export type MiscParamsSetRead = {
  name: string;
  params: MiscParams;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MiscParamsSetCreate = {
  name?: string;
  params: MiscParams;
};
export type Default = "default";
export type SimilarChargedRecipeSummary = {
  price_per_tonne_tapped: number;
  recipe_mass: number;
  tapped_mass: number;
  total_cost_per_heat: number;
};
export type SimilarChargedRecipeMaterial = {
  material_name: string;
  mass: number;
  basket_layer_name: string;
};
export type SimilarChargedRecipeChemistry = {
  chemical_element_symbol: string;
  weight_percent: number;
  sample_ordinal: number;
  factory_entity: string;
};
export type SimilarChargedRecipe = {
  customer_heat_id: string;
  start_time: string;
  steel_grade_name: string;
  summary: SimilarChargedRecipeSummary;
  materials: SimilarChargedRecipeMaterial[];
  chemistry: SimilarChargedRecipeChemistry[];
};
export type DistanceMetricEnum = "euclidean" | "manhattan" | "cosine";
export type SimilarChargedRecipesParams = {
  optimisation_result_set_id: number;
  chef_group_id: number;
  mix_number: number;
  period?: number;
  n_recipes: number;
  metric?: DistanceMetricEnum;
  steel_grade_name?: string | null;
};
export type SearchPeriod = {
  name: string | null;
  period: number;
  production_plan_id: number;
  obtainable_bundle_set_id: number | null;
  target_inventory_set_id: number | null;
  period_material_exclusivity_constraint_set_id: number | null;
  is_deployable: boolean;
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type Search = {
  product_grouping_id: number | null;
  chef_grouping_id: number;
  steel_grade_set_id: number;
  material_set_id: number;
  material_physics_set_id: number;
  material_chemistry_set_id: number;
  basket_constraint_set_id: number;
  mix_material_limit_set_id: number;
  chef_group_basket_material_limit_set_id: number;
  search_id: number;
  session_id: number;
  session_name: string;
  session_created_by_email: string;
  session_created_at: string;
  created_by_email: string;
  created_by_id: string;
  created_at: string;
  parent_search_id: number | null;
  search_type: SearchType;
  misc_params_set_id: number;
  physical_parameters_set_id: number;
  production_plan_id: number;
  inventory_set_id: number;
  target_inventory_set_id: number;
  obtainable_bundle_set_id: number;
  target_basket_set_id: number;
  production_forecast_id: number | null;
  period_material_exclusivity_schedule_id?: number | null;
  period_material_exclusivity_forecast_id?: number | null;
  periods: SearchPeriod[];
};
export type ChemicalElement = {
  id: number;
  name: string;
  symbol: string;
};
export type ChemicalConstraintCoefficient = {
  chemical_element: ChemicalElement;
  coefficient: number;
};
export type ChemicalConstraint = {
  weight_percent_max: number;
  adjusted_weight_percent_max: number;
  coefficients: ChemicalConstraintCoefficient[];
  name?: string | null;
};
export type SteelGradeSummary = {
  id: number;
  name: string;
};
export type ExclusiveMaterialSet = {
  material_ids: number[];
  violation_cost?: number | null;
};
export type ChefGroup = {
  id?: number | null;
  product_group_id: number;
  name: string;
  fraction_of_heats: number;
  default_target_num_baskets: number;
  max_failure_rate: number;
  chemical_constraints: ChemicalConstraint[];
  steel_grades: SteelGradeSummary[];
  exclusivity_constraints: ExclusiveMaterialSet[];
};
export type ChefGroupingRead = {
  name?: string;
  chef_groups: ChefGroup[];
  steel_grade_set_id: number;
  product_grouping_id: number;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ChemicalConstraint2 = {
  weight_percent_max: number;
  adjusted_weight_percent_max: number;
  coefficients: ChemicalConstraintCoefficient[];
  name?: string | null;
};
export type ChefGroup2 = {
  id?: number | null;
  product_group_id: number;
  name: string;
  fraction_of_heats: number;
  default_target_num_baskets: number;
  max_failure_rate: number;
  chemical_constraints: ChemicalConstraint2[];
  steel_grades: SteelGradeSummary[];
  exclusivity_constraints: ExclusiveMaterialSet[];
};
export type ChefGroupingCreate = {
  name?: string;
  chef_groups: ChefGroup2[];
  steel_grade_set_id: number;
  product_grouping_id: number;
};
export type SteelGrade = {
  id: number;
  name: string;
  chemical_constraints: ChemicalConstraint[];
};
export type SteelGradeSetRead = {
  id: number;
  name?: string;
  created_at: string;
  created_by_id: string;
  steel_grades: SteelGrade[];
};
export type SteelGradeCreate = {
  name: string;
  chemical_constraints: ChemicalConstraint2[];
};
export type SteelGradeSetCreate = {
  name?: string;
  steel_grades: SteelGradeCreate[];
};
export type SteelGradePlanItem = {
  steel_grade_id: number;
  num_heats: number;
};
export type SteelGradePlanRead = {
  name: string;
  steel_grade_set_id: number;
  items: SteelGradePlanItem[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type SteelGradePlanCreate = {
  name?: string;
  steel_grade_set_id: number;
  items: SteelGradePlanItem[];
};
export type FileSteelGradePlan = {
  name: string;
  steel_grade_set_id: number;
  items: SteelGradePlanItem[];
  id: number;
  created_at: string;
  created_by_id: string;
  unrecognised_steel_grades: string[];
  from_file_id: number;
};
export type BasketRead = {
  basket_number: number;
  basket_volume: number;
  id: number;
};
export type BasketLayerRead = {
  name: string;
  id: number;
};
export type BasketConstraint = {
  basket_id: number;
  min_fill_level: number;
  max_fill_level: number;
  min_mass: number;
  max_mass: number;
};
export type BasketConstraintSetRead = {
  name: string;
  items: BasketConstraint[];
  id: number;
  created_at: string;
};
export type BasketConstraintSetCreate = {
  name?: string;
  items: BasketConstraint[];
};
export type FormattedBasketRange = {
  minimum: number | null;
  maximum: number | null;
};
export type FormattedBasketConstraint = {
  basket_number: number;
  fill_level: FormattedBasketRange;
  mass: FormattedBasketRange;
};
export type BasketMaterialAdditionCreate = {
  basket_number: number;
  material_masses: {
    [key: string]: number;
  };
  correlation_id: string;
};
export type MaterialLimitCoefficientCreate = {
  material_constraint_class_id: number;
  coefficient: number;
};
export type BasketMaterialLimitBoundsCreate = {
  basket_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
};
export type BasketMaterialLimitCreate = {
  coefficients: MaterialLimitCoefficientCreate[];
  name?: string | null;
  bounds: BasketMaterialLimitBoundsCreate[];
  hardness: number;
};
export type ChefGroupBasketMaterialLimitCreate = {
  chef_group_id: number;
  basket_material_limit: BasketMaterialLimitCreate;
};
export type ChefGroupBasketMaterialLimitSetCreate = {
  chef_group_basket_material_limits: ChefGroupBasketMaterialLimitCreate[];
};
export type BasketMaterialLimitBounds = {
  basket_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  basket: BasketRead;
};
export type BasketMaterialLimitRead = {
  id: number;
  bounds: BasketMaterialLimitBounds[];
  coefficients: MaterialLimitCoefficientCreate[];
  hardness: number;
  name: string;
  coefficients_signature: string;
};
export type ChefGroupBasketMaterialLimitRead = {
  id: number;
  chef_group_id: number;
  basket_material_limit: BasketMaterialLimitRead;
};
export type ChefGroupBasketMaterialLimitSetRead = {
  id: number;
  default: boolean;
  chef_group_basket_material_limits: ChefGroupBasketMaterialLimitRead[];
};
export type MaterialSet = {
  id: number;
  default: boolean;
};
export type MaterialType = "scrap" | "iron" | "flux";
export type AdditionLocation = "basket" | "hopper";
export type MaterialRead = {
  id: number;
  name: string;
  material_type: MaterialType;
  internal: boolean;
  addition_location: AdditionLocation;
  basket_layers: BasketLayerRead[];
  ordering: number;
};
export type MaterialConstraintClass = {
  id: number;
  name: string;
};
export type Variability = "low" | "medium" | "high";
export type ElementPropertiesView = {
  mean: number;
  variability: Variability | null;
};
export type MaterialPropertiesView = {
  material_id: number;
  material_name: string;
  material_type: MaterialType;
  yield_percent: number;
  density: number;
  min_feasible_mass: number;
  element_statistics: {
    [key: string]: ElementPropertiesView;
  };
};
export type MaterialChemistry = {
  chemical_element_id: number;
  material_id: number;
  loc: number;
  scale: number;
};
export type MaterialChemistrySetRead = {
  name: string;
  items: MaterialChemistry[];
  source_uris: string[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MaterialChemistrySetCreate = {
  name?: string;
  items: MaterialChemistry[];
  source_uris: string[];
};
export type IntercalationRole = "ignored" | "big" | "small";
export type MaterialPhysics = {
  material_id: number;
  density: number;
  yield_percent: number;
  min_feasible_mass: number;
  material_constraint_classes: MaterialConstraintClass[];
  intercalation_role: IntercalationRole;
  empty_space_fraction: number;
  default_substitute_material_id: number;
  electrical_energy_consumption: number;
};
export type MaterialPhysicsSetRead = {
  name: string;
  items: MaterialPhysics[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MaterialPhysicsSetCreate = {
  name?: string;
  items: MaterialPhysics[];
};
export type PlanMixBasket = {
  material_masses: {
    [key: string]: number;
  };
  total_mass: number;
};
export type NamedPlanMix = {
  mix_id: number;
  steel_grade_ids: number[];
  product_group_id: number;
  copper_percent_max_tolerance: number;
  baskets: {
    [key: string]: PlanMixBasket;
  };
  dri: PlanMixBasket;
  totals: PlanMixBasket;
  period: number;
  number_of_heats: number;
  target_tapped_mass: number;
  display_price_per_tonne: number;
  mix_name: string;
};
export type NamedPlanMixes = {
  plan_id: number;
  mixes: NamedPlanMix[];
};
export type Latest = "latest";
export type SupportedLanguage = "DE" | "EN" | "ES" | "FR" | "PL";
export type DropWeightDeprecated = {
  drop_datetime: string;
  heat_id: number;
  charge_bucket_id: number;
  drop_weight: number;
  operator_layer: number;
  customer_charge_schedule_id: string | null;
  customer_bucket_number: number | null;
};
export type CustomerBucketId = 1 | 2 | 3;
export type DropWeight = {
  drop_datetime: string;
  load_id: number;
  material_id: number | null;
  charge_bucket_id: number;
  drop_weight: number;
  customer_charge_schedule_id: string;
  customer_charge_schedule_bucket_number: number;
};
export type ObtainableBundleItem = {
  uuid: string;
  material_id: number;
  created_at: string;
  label: string;
  min_obtainable: number | null;
  max_obtainable: number | null;
  specific_price: number | null;
  arrival_probability?: number | null;
};
export type ObtainableBundleItemSet = {
  items: ObtainableBundleItem[];
  obtainable_bundle_set_id: number;
};
export type ObtainableBundleItemSetCreate = {
  items: ObtainableBundleItem[];
};
export type MaterialBundleItem = {
  material_id: number;
  quantity: number;
};
export type MaterialBundleRead = {
  name: string;
  source: string;
  items: MaterialBundleItem[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type MaterialBundleCreate = {
  name?: string;
  source?: string;
  items: MaterialBundleItem[];
};
export type ProductGroup = {
  id?: number | null;
  name: string;
  steel_grades: SteelGradeSummary[];
};
export type ProductGroupingRead = {
  name: string;
  product_groups: ProductGroup[];
  steel_grade_set_id: number;
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ProductGroupingCreate = {
  name: string;
  product_groups: ProductGroup[];
  steel_grade_set_id: number;
};
export type ProductGroupPlanItem = {
  product_group_id: number;
  num_heats: number;
};
export type ProductGroupPlanRead = {
  name: string;
  product_grouping_id: number;
  items: ProductGroupPlanItem[];
  id: number;
  created_at: string;
  created_by_id: string;
};
export type ProductGroupPlanCreate = {
  name?: string;
  product_grouping_id: number;
  items: ProductGroupPlanItem[];
};
export type ProductionPlanItem = {
  chef_group_id: number;
  num_heats: number;
  mix_number: number;
};
export type ProductionPlanRead = {
  name: string;
  from_product_group_plan_id?: number | null;
  from_steel_grade_plan_id?: number | null;
  id: number;
  created_at: string;
  created_by_id: string;
  items: ProductionPlanItem[];
};
export type ProductionPlanCreate = {
  name?: string;
  from_product_group_plan_id?: number | null;
  from_steel_grade_plan_id?: number | null;
  items: ProductionPlanItem[];
};
export type SourcedProductionPlan = {
  chef_group_plan: ProductionPlanItem[];
  product_group_plan?: ProductGroupPlanItem[] | null;
  steel_grade_plan?: SteelGradePlanItem[] | null;
  chef_group_plan_id?: number | null;
  from_product_group_plan_id?: number | null;
  from_steel_grade_plan_id?: number | null;
};
export type SourcedProductionPlanFromSteelGradePlan = {
  steel_grade_set_id: number;
  chef_grouping_id: number;
  steel_grade_plan: SteelGradePlanItem[];
  chemistry_group_plan: ProductionPlanItem[];
};
export type SourcedProductionPlanFromProductGroupPlan = {
  product_grouping_id: number;
  chef_grouping_id: number;
  product_group_plan: ProductGroupPlanItem[];
  chemistry_group_plan: ProductionPlanItem[];
};
export type FileProductionPlan = {
  name: string;
  from_product_group_plan_id?: number | null;
  from_steel_grade_plan_id?: number | null;
  id: number;
  created_at: string;
  created_by_id: string;
  items: ProductionPlanItem[];
  unrecognised_steel_grades: string[];
  from_file_id: number;
};
export type SearchStatus = "not_started" | "running" | "success" | "failure";
export type SearchSummary = {
  search_id: number;
  parent_search_id: number | null;
  context_id: number;
  status: SearchStatus;
  created_by_current_user: boolean;
  user_email: string;
  created_at: string;
};
export type Ordering = "asc" | "desc";
export type CreateSearch = {
  search?: Search | null;
  name?: string | null;
  search_type?: SearchType | null;
  deleted?: boolean;
};
export type OptimisationStatus =
  | "not_started"
  | "failed"
  | "failed_suboptimal"
  | "succeeded_suboptimal"
  | "succeeded"
  | "edited";
export type SummaryTagEnum =
  | "not_enough_available_scrap"
  | "challenging_for_scrapyard"
  | "uses_non_default_baskets";
export type TagSentiment = "positive" | "neutral" | "negative" | "terrible";
export type SummaryTag = {
  period: number;
  tag: SummaryTagEnum;
  details: string[];
  sentiment: TagSentiment;
};
export type NullableDatetime = string | null;
export type PeriodSummary = {
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  display_cost_per_tonne_materials: number;
  display_cost_per_tonne_electrical_energy: number;
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  display_produced_mass: number;
  display_yield_percent: number;
  period: number;
  name: string | null;
  is_deployable: boolean;
  start_timestamp: NullableDatetime;
  end_timestamp: NullableDatetime;
  num_heats: number;
  total_cost_materials: number;
  total_cost_electrical_energy: number;
  total_cost: number;
};
export type MixSummary = {
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  display_cost_per_tonne_materials: number;
  display_cost_per_tonne_electrical_energy: number;
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  display_produced_mass: number;
  display_yield_percent: number;
  period: number;
  num_heats: number;
  num_baskets: number;
  chef_group_name: string;
  chef_group_id: number;
  mix_number: number;
};
export type MixMaterialSummary = {
  period: number;
  material: string;
  recipe_mass: number;
  chef_group_name: string;
  mix_number: number;
};
export type MixBasketSummary = {
  period: number;
  chef_group_name: string;
  mix_number: number;
  basket_number: number;
  recipe_mass: number;
  volume: number;
  fullness: number;
};
export type MixMaterialBasketSummary = {
  period: number;
  material: string;
  recipe_mass: number;
  chef_group_name: string;
  mix_number: number;
  basket_number: number;
  basket_layer: string;
};
export type NullableNonNegativeFloat = number | null;
export type MaterialSummary = {
  material: string;
  material_id: number;
  is_scrap: boolean;
  density: number;
  yield_percent: number;
  price: NullableNonNegativeFloat;
  inventory_target_min: number;
  inventory_target_max: NullableNonNegativeFloat;
  inventory: number;
  obtainable_min: number;
  obtainable_max: number;
  consumption_planned: number;
  consumption_forecast: number;
  recipe_mass: number;
  obtainable_used: number;
  inventory_used: number;
  obtainable_obtained: number;
  obtainable_remaining: number;
  inventory_post_planned: number;
  inventory_post_forecast: number;
  highlight_safety_stock: boolean;
  highlight_stock_to_drain: boolean;
};
export type ProductGroupMixSummary = {
  product_group_name: string;
  cost_per_heat_materials: number;
  cost_per_heat_electrical_energy: number;
  cost_per_tonne_tapped_materials: number;
  cost_per_tonne_tapped_electrical_energy: number;
  cost_per_tonne_billets_materials: number;
  cost_per_tonne_billets_electrical_energy: number;
  cost_per_heat: number;
  cost_per_tonne_tapped: number;
  cost_per_tonne_billets: number;
  display_cost_per_tonne_materials: number;
  display_cost_per_tonne_electrical_energy: number;
  display_cost_per_tonne: number;
  recipe_mass: number;
  scrap_mass: number;
  dri_mass: number;
  tapped_mass: number;
  target_tapped_mass_lower: number;
  billet_mass: number;
  yield_percent: number;
  yield_percent_billets: number;
  display_produced_mass: number;
  display_yield_percent: number;
  period: number;
  num_heats: number;
  num_baskets: number;
};
export type ProductGroupMixMaterialSummary = {
  product_group_name: string;
  period: number;
  material: string;
  recipe_mass: number;
};
export type ObtainableResultItem = {
  period: number;
  material_id: number;
  material_name: string;
  bundle_name: string;
  bundle_id: number;
  required_obtainable: number;
  max_obtainable: number;
  specific_price: number;
  created_at: string;
};
export type PeriodMaterialSummary = {
  period: number;
  material_id: number;
  material: string;
  initial_inventory: number;
  final_inventory: number;
  mass_consumed: number;
  obtainable_obtained: number;
  obtainable_remaining: number;
  obtained_price_per_tonne: NullableNonNegativeFloat;
  cost_per_tonne_material: number;
  cost_per_tonne_electrical_energy: number;
  cost_consumed: number;
};
export type OptimisationResultSetSummary = {
  tags: SummaryTag[];
  period_summary: PeriodSummary[];
  mix_summary: MixSummary[];
  mix_material_summary: MixMaterialSummary[];
  mix_basket_summary: MixBasketSummary[];
  mix_material_basket_summary: MixMaterialBasketSummary[];
  material_summary: MaterialSummary[];
  product_group_mix_summary: ProductGroupMixSummary[] | null;
  product_group_mix_material_summary: ProductGroupMixMaterialSummary[] | null;
  obtainable_summary: ObtainableResultItem[];
  period_material_summary: PeriodMaterialSummary[];
  first_period_summary: PeriodSummary;
};
export type SlackVariableEnum =
  | "unobtainium_purchased"
  | "unobtainium_consumed"
  | "material_period_exclusivity_constraint_violated"
  | "target_inventory_increases"
  | "target_inventory_decreases"
  | "soft_material_max_increases"
  | "soft_material_min_decreases"
  | "soft_material_exclusivity_constraint_violated"
  | "mix_use_count_increases"
  | "impurity_weight_percent_max_increase";
export type OptimisationSlackVariableUsage = {
  details: {
    [key: string]: string | number;
  };
  slack_variable: SlackVariableEnum;
  usage: number;
  period: number;
  cost: number;
};
export type Plan = {
  id: number;
  plan_index: number;
  session_id: number;
  search_id: number;
  matches_search: boolean;
  optimisation_status: OptimisationStatus;
  summary: OptimisationResultSetSummary;
  optimisation_slack_variable_usage: OptimisationSlackVariableUsage[];
  time_limit: number;
};
export type OptimisationFailureReason =
  | "Infeasible Optimisation"
  | "Optimisation Timed Out"
  | "Optimisation Error"
  | "unacceptable_slack_variable_usage";
export type SearchResultStatus = "not_started" | "incomplete" | "complete";
export type SearchResult = {
  search_id: number;
  session_id: number;
  plans: Plan[];
  failure_reason: OptimisationFailureReason | null;
  status: SearchResultStatus;
};
export type OptimisationMethod = "classic";
export type RunSearchOptions = {
  optimisation_method?: OptimisationMethod | null;
  optimisation_time_limit?: number;
  force_non_integer_solution?: boolean;
  task_limit?: number | null;
};
export type PhysicalParameters = {
  target_tapped_mass_lower: number;
  target_tapped_mass_upper: number;
  tapped_to_billet_yield: number;
  max_distinct_materials_per_mix?: number | null;
  typical_hot_heel_mass: number;
  target_charged_mass_lower: number | null;
  target_charged_mass_upper: number | null;
};
export type InventoryItem = {
  material_id: number;
  quantity: number | null;
  specific_price: number | null;
  previous_quantity?: number | null;
  deliveries_since_previous?: number | null;
  consumption_since_previous?: number | null;
  projected_deliveries_since_previous?: number | null;
  projected_consumption_since_previous?: number | null;
};
export type TargetBasketItem = {
  chef_group_id: number;
  target_num_baskets: number;
};
export type SyncedProductionPlan = {
  product_group_items: ProductGroupPlanItem[] | null;
  steel_grade_items: SteelGradePlanItem[] | null;
};
export type TargetInventoryItem = {
  material_id: number;
  min_quantity: number | null;
  max_quantity: number | null;
  is_hard_requirement?: boolean;
};
export type PeriodMaterialExclusivityConstraintCreate = {
  material_ids: number[];
};
export type SyncedSearchPeriod = {
  name?: string | null;
  start_timestamp?: string | null;
  end_timestamp?: string | null;
  is_deployable: boolean;
  production_plan: SyncedProductionPlan;
  obtainable_bundles: ObtainableBundleItem[];
  target_inventory: TargetInventoryItem[];
  material_exclusivity: PeriodMaterialExclusivityConstraintCreate[];
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price?: number;
};
export type SyncedSearchParameters = {
  id: number | null;
  session_id: number;
  session_name: string;
  session_created_at: string;
  session_created_by_email: string;
  parent_id: number | null;
  search_type: SearchType;
  context_id: number;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: InventoryItem[];
  target_baskets: TargetBasketItem[];
  periods: SyncedSearchPeriod[];
};
export type SyncedSearchPeriod2 = {
  name?: string | null;
  start_timestamp?: string | null;
  end_timestamp?: string | null;
  is_deployable: boolean;
  production_plan: SyncedProductionPlan;
  obtainable_bundles: ObtainableBundleItem[];
  target_inventory: TargetInventoryItem[];
  material_exclusivity: PeriodMaterialExclusivityConstraintCreate[];
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price?: number;
};
export type SyncedSearchParameters2 = {
  id: number | null;
  session_id: number;
  session_name: string;
  session_created_at: string;
  session_created_by_email: string;
  parent_id: number | null;
  search_type: SearchType;
  context_id: number;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: InventoryItem[];
  target_baskets: TargetBasketItem[];
  periods: SyncedSearchPeriod2[];
};
export type MaterialLimitCoefficientRead = {
  material_constraint_class_id: number;
  coefficient: number;
  id: number;
};
export type MixMaterialLimitRead = {
  chef_group_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  hardness: number;
  id: number;
  coefficients: MaterialLimitCoefficientRead[];
  name: string;
  coefficients_signature: string;
};
export type SyncedSearchContext = {
  product_groups_id: number | null;
  steel_grades_id: number;
  materials_id: number;
  material_physics_id: number;
  mix_material_limit_set_id: number;
  chef_group_basket_material_limit_set_id: number;
  has_default_sets: boolean;
  product_groups: ProductGroup[];
  chef_groups: ChefGroup[];
  steel_grades: SteelGrade[];
  materials: MaterialRead[];
  material_physics: MaterialPhysics[];
  material_chemistry: MaterialChemistry[];
  mix_material_limits: MixMaterialLimitRead[];
  chef_group_basket_material_limit_set: ChefGroupBasketMaterialLimitSetRead;
  baskets: BasketRead[];
  chemical_elements: ChemicalElement[];
};
export type NameBasedInventoryItem = {
  material_name: string;
  quantity: number | null;
  specific_price: number | null;
  previous_quantity?: number | null;
  deliveries_since_previous?: number | null;
  consumption_since_previous?: number | null;
  projected_deliveries_since_previous?: number | null;
  projected_consumption_since_previous?: number | null;
};
export type NameBasedTargetBasketItem = {
  chef_group_name: string;
  target_num_baskets: number;
};
export type NameBasedProductGroupPlanItem = {
  product_group_name: string;
  num_heats: number;
};
export type NameBasedSteelGradePlanItem = {
  steel_grade_name: string;
  num_heats: number;
};
export type NameBasedProductionPlan = {
  product_group_items: NameBasedProductGroupPlanItem[] | null;
  steel_grade_items: NameBasedSteelGradePlanItem[] | null;
};
export type NameBasedObtainableBundleItem = {
  material_name: string;
  label: string;
  min_obtainable: number | null;
  max_obtainable: number | null;
  specific_price: number | null;
  arrival_probability: number | null;
};
export type NameBasedTargetInventoryItem = {
  material_name: string;
  min_quantity: number | null;
  max_quantity: number | null;
  is_hard_requirement?: boolean;
};
export type NameBasedExclusiveMaterials = {
  material_names: string[];
};
export type NameBasedSearchPeriod = {
  name: string | null;
  start_timestamp: string | null;
  end_timestamp: string | null;
  production_plan: NameBasedProductionPlan;
  obtainable_bundles: NameBasedObtainableBundleItem[];
  target_inventory: NameBasedTargetInventoryItem[];
  material_exclusivity: NameBasedExclusiveMaterials[];
  is_deployable: boolean;
  suppress_mix_material_exclusivity_constraints: boolean;
  suppress_min_feasible_mass_constraints: boolean;
  optimisation_objective_weighting: number;
  electrical_energy_price: number;
};
export type NameBasedSearch = {
  search_type: SearchType;
  misc_params: MiscParams;
  physical_parameters: PhysicalParameters;
  inventory: NameBasedInventoryItem[];
  target_baskets: NameBasedTargetBasketItem[];
  periods: NameBasedSearchPeriod[];
};
export type GroupedSearchContext = {
  product_grouping_id: number | null;
  chef_grouping_id: number;
  steel_grade_set_id: number;
  material_set_id: number;
  material_physics_set_id: number;
  material_chemistry_set_id: number;
  basket_constraint_set_id: number;
  mix_material_limit_set_id: number;
  chef_group_basket_material_limit_set_id: number;
  id: number;
  parent_search_context_id: number;
  search_context_group_id: number;
  created_at: string;
};
export type SearchContextGroup = {
  id: number;
  name: string;
  user_id: string;
  user_email: string;
  created_at: string;
  updated_at: string;
  latest_search_context_id: number;
  contexts: GroupedSearchContext[];
};
export type SearchContextGroupCreate = {
  name: string;
  context_id: number;
};
export type SearchContextGroupSummary = {
  id: number;
  name: string;
  user_id: string;
  user_email: string;
  created_at: string;
  updated_at: string;
  latest_search_context_id: number;
};
export type MixMaterialLimitCreate = {
  chef_group_id: number;
  min_mass: number | null;
  max_mass: number | null;
  soft_min_mass: number | null;
  soft_max_mass: number | null;
  hardness: number;
  coefficients: MaterialLimitCoefficientCreate[];
  name?: string | null;
  reason?: string | null;
};
export type MixItem = {
  material_name: string;
  mass: number;
};
export type ExperimentalMix = {
  totals: MixItem[];
  baskets: MixItem[][];
};
export type MixAllocation = {
  heats: number;
  mix: ExperimentalMix;
};
export type ProductionItem = {
  steel_grade_name: string;
  heats: number;
  mixes: MixAllocation[] | null;
};
export type ProductionBlock = {
  uuid: string;
  name: string | null;
  suppressed: boolean;
  start: number;
  end: number;
  steel_grades: ProductionItem[];
};
export type ExperimentalObtainableBundle = {
  material_name: string;
  minimum_quantity: number | null;
  maximum_quantity: number | null;
  specific_price: number | null;
  arrival_probability: number | null;
};
export type ObtainableBlock = {
  uuid: string;
  name: string | null;
  suppressed: boolean;
  start: number;
  end: number;
  bundles: ExperimentalObtainableBundle[];
};
export type ExperimentalInventoryItem = {
  material_name: string;
  mass: number | null;
  specific_price: number | null;
};
export type Inventory = {
  name: string | null;
  timestamp: number;
  inventory: ExperimentalInventoryItem[];
};
export type Period = {
  name: string | null;
  timestamp: number;
};
export type MarkerBlock = {
  uuid: string;
  name: string | null;
  suppressed: boolean;
  start: number;
  end: number;
  text: string;
};
export type ExperimentalInputs = {
  production: ProductionBlock[];
  obtainable: ObtainableBlock[];
  inventory: Inventory;
  periods: Period[];
  markers: MarkerBlock[];
};
export type ExperimentalSearch = {
  id: number;
  context_id: number;
  session_id: number;
  parent_id: number | null;
  search_id: number | null;
  inputs: ExperimentalInputs;
};
export type MixAllocation2 = {
  heats: number;
  mix: ExperimentalMix;
};
export type ProductionItem2 = {
  steel_grade_name: string;
  heats: number;
  mixes: MixAllocation2[] | null;
};
export type ProductionBlock2 = {
  uuid: string;
  name: string | null;
  suppressed: boolean;
  start: number;
  end: number;
  steel_grades: ProductionItem2[];
};
export type ExperimentalInputs2 = {
  production: ProductionBlock2[];
  obtainable: ObtainableBlock[];
  inventory: Inventory;
  periods: Period[];
  markers: MarkerBlock[];
};
export type ExperimentalSearchCreate = {
  context_id: number | null;
  session_id: number | null;
  parent_id: number | null;
  search_id: number | null;
  inputs: ExperimentalInputs2;
};
export type OptimiserProduction = {
  period: number;
  source_uuid: string;
  production_plan: SteelGradePlanItem[];
};
export type OptimiserObtainable = {
  period: number;
  source_uuid: string;
  obtainable_bundles: ObtainableBundleItem[];
};
export type OptimiserInputs = {
  session_id: number;
  context_id: number;
  inventory: InventoryItem[];
  production: OptimiserProduction[];
  obtainable: OptimiserObtainable[];
};
export type ImportedExperimentalInputs = {
  production?: ProductionBlock[] | null;
  obtainable?: ObtainableBlock[] | null;
  inventory?: Inventory | null;
  periods?: Period[] | null;
  markers?: MarkerBlock[] | null;
};
export type File = {
  name?: string;
  updated_at?: string | null;
  content: string;
};
export type UploadedFileCreate = {
  type: string;
  file: File;
};
export type ImportedText = {
  text: string;
};
export type ImportedMixAllocation = {
  production_uuid: string;
  steel_grade_name: string;
  mixes: MixAllocation[];
};
export type ImportMixAllocationBody = {
  sheet_name: string;
  file_content: string;
  production: ProductionBlock2[];
};
export type ExperimentalSessionHeader = {
  session_id: number;
  session_name: string;
};
export type ExperimentalSearchHeader = {
  id: number;
  parent_id: number | null;
  search_id: number | null;
};
export type DeployedPlanRead = {
  session_id: number;
  search_id: number;
  plan_id: number;
  session_name: string;
  deployed_at: string;
  deployed_by_email: string;
  first_period_summary: PeriodSummary;
};
export type DeploymentHistory = {
  session_id: number;
  search_id: number;
  username: string;
  email: string;
  deployed_at: string;
  plan_id: number;
};
export type SessionSummary = {
  session_id: number;
  name: string;
  created_by_current_user: boolean;
  user_email: string;
  created_at: string;
  search_count: number;
  searches_not_started: number;
  searches_running: number;
  searches_failed: number;
  searches_succeeded: number;
  deployment_history: DeploymentHistory[];
  last_search_id: number;
};
export type RenameSession = {
  name: string;
};
export type CopySearch = {
  name: string | null;
};
export type UploadedFileState = "new" | "parsed";
export type FileMeta = {
  name?: string;
  updated_at?: string | null;
};
export type UploadedFileMeta = {
  id: number;
  uploaded_at: string;
  uploaded_by_id: string;
  type: string;
  deleted: boolean;
  state: UploadedFileState;
  file: FileMeta;
};
export type UploadedFileType =
  | "unknown"
  | "recipes"
  | "fdm_recipes"
  | "melt_plan"
  | "order_book"
  | "twenty_six_week_schedule"
  | "scrap_plan"
  | "inventory_report"
  | "inventory_measurement"
  | "rail_car_movements"
  | "contracted_buy"
  | "scrap_composite"
  | "ship_deliveries"
  | "truck_deliveries"
  | "deliveries"
  | "purchased_scrap"
  | "billet_stock"
  | "scrap_usage"
  | "chemical_samples"
  | "heat_mass"
  | "steel_grade_billet_size"
  | "steel_grade_chemistry"
  | "scrap_types"
  | "heat_process_data"
  | "heat_scrap_data"
  | "material_metadata"
  | "other_material_additions"
  | "product_chemical_analysis"
  | "quality_codes"
  | "slag_analysis"
  | "sap_billet_orders"
  | "sap_rolled_orders"
  | "drop_weights"
  | "scrap_car_weight"
  | "live_heat_data"
  | "live_charge_buckets";
export type UploadedFileRead = {
  type: string;
  file: File;
  id: number;
  uploaded_at: string;
  uploaded_by_id: string;
  deleted: boolean;
  state: UploadedFileState;
};
export type UploadedFileOutputType =
  | "steel_grade_plan"
  | "product_group_plan"
  | "purchasing_plan"
  | "material_inputs";
export type UploadedFileOutputTypeList = {
  items: UploadedFileOutputType[];
};
export type MaterialAvailability = {
  material_id: number;
  inventory?: number | null;
  obtainable_min?: number | null;
  obtainable_max?: number | null;
  inventory_target_min?: number | null;
  inventory_target_max?: number | null;
  is_hard_requirement?: boolean;
  price?: number | null;
};
export type UploadedSearchParameters = {
  unrecognised_names?: string[] | null;
  steel_grade_plan?: SteelGradePlanItem[] | null;
  availabilities?: MaterialAvailability[] | null;
};
export type ObtainableEstimate = {
  material_id: number;
  obtainable_max: number | null;
};
export type PublishedChargeScheduleLayeringItem = {
  ChargeNum: 1 | 2 | 3;
  LayerNum: number;
  Weight: number;
  ScrapID: number;
  Description: string;
};
export type PublishedChargeScheduleCreate = {
  plan_id: number;
  period: number;
  chef_group_id: number;
  mix_number: number;
  layering: PublishedChargeScheduleLayeringItem[];
};
export type LayeringFudgeFactorMaterial = {
  material_id: number;
  factor: number;
};
export type LayeringFudgeFactors = {
  replacement_material_id: number;
  materials: LayeringFudgeFactorMaterial[];
};
export type GetLayeringBody = {
  fudge_factors?: LayeringFudgeFactors | null;
};
export type CmcChargeScheduleType = 1000 | 2000 | 3000;
export type PublishedChargeScheduleSteelGrade = {
  BilletSize: string;
  MeltGrade: string;
  FinishGoodCode: string;
};
export type PublishedChargeScheduleRead = {
  fdm_charge_schedule_id: number;
  cmc_charge_schedule_type: CmcChargeScheduleType;
  steel_grades: PublishedChargeScheduleSteelGrade[];
  layering: PublishedChargeScheduleLayeringItem[];
};
export type CustomerPublishedChargeScheduleLayer = {
  id: number;
  customer_material_id: number;
  customer_material_name: string;
  layer_num: number;
  basket_num: number;
  mass_lbs: number;
  publish_charge_schedule_id: number;
};
export type ChargeScheduleConfirm = {
  fdm_charge_schedule_id: number;
  cmc_charge_schedule_id: string;
};
export type ConfirmedChargeScheduleMeta = {
  fdm_charge_schedule_id: number;
  cmc_charge_schedule_id: string;
  plan_id: number;
  period: number;
  chef_group_id: number;
  mix_number: number;
};
export type ChargeScheduleMessage = {
  text: string;
};
export type Message = {
  id: number;
  message: string;
  sent: boolean;
};
export type InventorySet = {
  id: number;
  items: InventoryItem[];
};
export type TargetInventorySet = {
  id: number;
  items: TargetInventoryItem[];
};
export type TargetBasketSetRead = {
  items: TargetBasketItem[];
  id: number;
};
export type TargetBasketSetCreate = {
  items: TargetBasketItem[];
};
export type UserInvite = {
  emails: string[];
};
export type InvitedUser = {
  email: string;
  is_active: boolean;
  invited_at: string;
  accepted_at?: string | null;
};
export type VideoLabels = {
  key: string;
  user: string;
  description: string;
  labels: object[];
  duration?: number | null;
};
export type MixMaterialLimitSetRead = {
  id: number;
  default: boolean;
  mix_material_limits: MixMaterialLimitRead[];
};
export type RealisedPick = {
  material: string;
  quantity: number;
  timestamp: string;
};
export type PlannedPick = {
  material: string;
  quantity: number;
};
export type PlannedHeat = {
  start: string | null;
  end: string | null;
  steel_grade: string;
  ratio: PlannedPick[];
  fill: PlannedPick[];
};
export type JsonValue = any;
export type ParameterisedStrategySpec = {
  name: string;
  params: {
    [key: string]: JsonValue;
  };
};
export type AutoRecipeStrategySpecs = {
  period_splitting_strategies: ParameterisedStrategySpec[];
  period_weighting_strategies: ParameterisedStrategySpec[];
  period_constraint_suppression_strategies: ParameterisedStrategySpec[];
  target_inventory_strategies: ParameterisedStrategySpec[];
  material_exclusivity_strategies: ParameterisedStrategySpec[];
};
export type AutoRecipeEstimatorsSpecs = {
  initial_inventory_estimators: ParameterisedStrategySpec[];
  production_estimators: ParameterisedStrategySpec[];
  deliveries_estimators: ParameterisedStrategySpec[];
};
export type SimulatorDataLoadersSpecs = {
  deliveries_loaders: ParameterisedStrategySpec[];
};
export type SimulatorRunStatus =
  | "not_started"
  | "running"
  | "success"
  | "failure";
export type SimulatorFailureReason =
  | "no_deployable_periods"
  | "search_error"
  | "search_timeout"
  | "optimisation_infeasible"
  | "optimisation_timeout"
  | "optimisation_error"
  | "optimisation_unacceptable_slack_variable_usage";
export type UtcTimestamp = string;
export type SimulatorStepResult = {
  session_id: number;
  search_id: number;
  plan_id: number;
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
};
export type AutoRecipeStrategySpec = {
  period_splitting_strategy: ParameterisedStrategySpec;
  period_weighting_strategy: ParameterisedStrategySpec;
  period_constraint_suppression_strategy: ParameterisedStrategySpec;
  target_inventory_strategy: ParameterisedStrategySpec;
  material_exclusivity_strategy: ParameterisedStrategySpec;
};
export type AutoRecipeEstimatorsSpec = {
  initial_inventory_estimator: ParameterisedStrategySpec;
  production_estimator: ParameterisedStrategySpec;
  deliveries_estimator: ParameterisedStrategySpec;
};
export type SimulatorDataLoadersSpec = {
  deliveries_loader: ParameterisedStrategySpec;
};
export type SimulatorRunRead = {
  id: number;
  created_by_id: string;
  status: SimulatorRunStatus;
  failure_reason: SimulatorFailureReason | null;
  results: SimulatorStepResult[];
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
  auto_recipe_strategy: AutoRecipeStrategySpec;
  auto_recipe_estimators: AutoRecipeEstimatorsSpec;
  simulator_data_loaders: SimulatorDataLoadersSpec;
  search_timeout_seconds: number;
};
export type AutoRecipeStrategySpec2 = {
  period_splitting_strategy: ParameterisedStrategySpec;
  period_weighting_strategy: ParameterisedStrategySpec;
  period_constraint_suppression_strategy: ParameterisedStrategySpec;
  target_inventory_strategy: ParameterisedStrategySpec;
  material_exclusivity_strategy: ParameterisedStrategySpec;
};
export type AutoRecipeEstimatorsSpec2 = {
  initial_inventory_estimator: ParameterisedStrategySpec;
  production_estimator: ParameterisedStrategySpec;
  deliveries_estimator: ParameterisedStrategySpec;
};
export type SimulatorDataLoadersSpec2 = {
  deliveries_loader: ParameterisedStrategySpec;
};
export type SimulatorRunCreate = {
  start_timestamp: UtcTimestamp;
  end_timestamp: UtcTimestamp;
  auto_recipe_strategy?: AutoRecipeStrategySpec2 | null;
  auto_recipe_estimators?: AutoRecipeEstimatorsSpec2 | null;
  simulator_data_loaders?: SimulatorDataLoadersSpec2 | null;
  search_timeout_seconds?: number;
};
export type Permission =
  | "review"
  | "publish"
  | "edit"
  | "read"
  | "review_mix"
  | "video_labelling"
  | "crane_driver"
  | "plan_recipient";
export type UserPermission = {
  user_uid: string;
  permission: Permission;
};
export type UserRead = {
  uid: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  is_enabled: boolean;
  is_admin: boolean;
  permissions: UserPermission[];
};
export type UserUpdate = {
  uid: string;
  is_admin?: boolean | null;
  is_enabled?: boolean | null;
  permissions?: {
    [key: string]: Permission[];
  } | null;
};
export const {
  useGetTenantMetadataQuery,
  useListMiscParamsSetsQuery,
  useCreateMiscParamsSetMutation,
  useGetMiscParamsSetQuery,
  useSetDefaultMiscParamsSetMutation,
  useGetSimilarChargedRecipesQuery,
  useCreateAutoRecipeMutation,
  useListChefGroupsQuery,
  useCreateChefGroupingMutation,
  useGetChefGroupingQuery,
  useSetDefaultChefGroupingMutation,
  useListSteelGradesQuery,
  useCreateSteelGradeSetMutation,
  useGetSteelGradeSetQuery,
  useSetDefaultSteelGradeSetMutation,
  useListSteelGradePlansQuery,
  useCreateSteelGradePlanMutation,
  useGetSteelGradePlanQuery,
  useSetDefaultSteelGradePlanMutation,
  useGetSteelGradePlanFromFileQuery,
  useGetTypicalSteelGradePlanQuery,
  useListBasketsQuery,
  useListBasketLayersQuery,
  useCreateBasketConstraintSetMutation,
  useListBasketConstraintSetsQuery,
  useGetBasketConstraintSetQuery,
  useSetDefaultBasketConstraintSetMutation,
  useGetFormattedBasketConstraintsQuery,
  useCreateBasketMaterialAdditionMutation,
  useUpdateSearchChefGroupBasketMaterialLimitsMutation,
  useGetChefGroupBasketMaterialLimitSetQuery,
  useListChemicalElementsQuery,
  useListMaterialSetsQuery,
  useGetMaterialSetQuery,
  useListAllMaterialsQuery,
  useListMaterialsQuery,
  useListMaterialConstraintClassesQuery,
  useGetFullMaterialsQuery,
  useCreateMaterialChemistrySetMutation,
  useListMaterialChemistrySetsQuery,
  useGetMaterialChemistrySetQuery,
  useSetDefaultMaterialChemistrySetMutation,
  useCreateMaterialPhysicsSetMutation,
  useListMaterialPhysicsSetsQuery,
  useGetMaterialPhysicsSetQuery,
  useSetDefaultMaterialPhysicsSetMutation,
  useGetMaterialPricesQuery,
  useGetMaterialNameMappingsQuery,
  useGetTenantContactsQuery,
  useGetPlanMixesQuery,
  useGetPlanMixesExcelQuery,
  useSendDeployedMixesEmailMutation,
  useListChargeBucketDropWeightsRecentDeprecatedQuery,
  useListChargeBucketDropWeightsRecentQuery,
  useGetObtainableBundleItemSetQuery,
  useCreateObtainableBundleItemSetMutation,
  useListMaterialBundlesQuery,
  useCreateMaterialBundleMutation,
  useGetMaterialBundleQuery,
  useListProductGroupingsQuery,
  useCreateProductGroupingMutation,
  useGetProductGroupingQuery,
  useSetDefaultProductGroupingMutation,
  useListProductGroupPlansQuery,
  useCreateProductGroupPlanMutation,
  useGetProductGroupPlanQuery,
  useSetDefaultProductGroupPlanMutation,
  useGetTypicalProductGroupPlanQuery,
  useListProductionPlansQuery,
  useCreateProductionPlanMutation,
  useGetProductionPlanQuery,
  useSetDefaultProductionPlanMutation,
  useGetSourcedProductionPlanQuery,
  useCreateSourcedProductionPlanFromSteelGradePlanMutation,
  useCreateSourcedProductionPlanFromProductGroupPlanMutation,
  useGetProductionPlanFromFileQuery,
  useGetProductionPlanFromProductGroupPlanQuery,
  useGetProductionPlanFromSteelGradePlanQuery,
  useGetTypicalProductionPlanQuery,
  useListSearchesQuery,
  useCreateSearchMutation,
  useSendSearchNotificationMutation,
  useCreateSearchFromContextMutation,
  useRunSearchMutation,
  useGetSearchQuery,
  useCreateSearchFromParametersMutation,
  useGetSearchContextQuery,
  useGetNameBasedSearchQuery,
  useCreateSearchContextGroupMutation,
  useListSearchContextGroupsQuery,
  useGetSearchContextGroupQuery,
  useUpdateSearchContextGroupMixMaterialLimitsMutation,
  useLoadExperimentalSearchQuery,
  useSyncExperimentalSearchMutation,
  useRunExperimentalSearchMutation,
  useImportInputsFromFileMutation,
  useImportInputsFromTextMutation,
  useImportMixAllocationFromPlanMutation,
  useImportMixAllocationFromFileMutation,
  useGetExperimentalSessionsQuery,
  useGetExperimentalSearchesQuery,
  useListDeployedPlansQuery,
  useSendPlanNotificationMutation,
  useGetContextForPlanQuery,
  useGetParametersForPlanQuery,
  useListSessionsQuery,
  useRenameSessionMutation,
  useDeleteSessionMutation,
  useCopySearchMutation,
  useGetResultQuery,
  useSaveUploadedFileMutation,
  useListUploadedFilesQuery,
  useGetUploadedFileQuery,
  useReassignUploadedFileMutation,
  useListUploadedFileTypesQuery,
  useGetParsedSearchParametersQuery,
  useGetPerHeatObtainableEstimateFromTypicalConsumptionQuery,
  useGetObtainableEstimateFromPurchasingPlanQuery,
  useGetLayeringMutation,
  usePublishChargeScheduleMutation,
  useListNewPublishedChargeSchedulesQuery,
  useListAllPublishedChargeSchedulesQuery,
  useListCustomerPublishedChargeScheduleLayersQuery,
  useListCustomerPublishedChargeScheduleLayersRecentQuery,
  useConfirmPublishedChargeSchedulesMutation,
  useListConfirmedChargeScheduleMetaQuery,
  useGetChargeScheduleMessageTemplateQuery,
  useSendChargeScheduleMessageMutation,
  useSendPlanDeployedMessageMutation,
  useListQueuedMessagesQuery,
  useConfirmMessageSentMutation,
  useCreateInventorySetMutation,
  useGetInventorySetQuery,
  useGetInventoryEstimateAtTimestampQuery,
  useGetRecipesImpuritiesPlotRiskQuery,
  useCreateTargetInventorySetMutation,
  useGetTargetInventorySetQuery,
  useCreateTargetBasketSetMutation,
  useGetTargetBasketSetQuery,
  useInviteUsersMutation,
  useGetPendingInvitesQuery,
  useDeleteUserInviteMutation,
  useGetVideoUrlQuery,
  useSuggestVideoQuery,
  useReadVideoLabelsQuery,
  useWriteVideoLabelsMutation,
  useGetRailcarsQuery,
  useGetMixMaterialLimitSetQuery,
  useUpdateSearchMixMaterialLimitSetMutation,
  useGetPicksQuery,
  useGetHeatsQuery,
  useListAutoRecipeStrategiesQuery,
  useListAutoRecipeEstimatorsQuery,
  useListSimulatorDataLoadersQuery,
  useGetSimulatorRunQuery,
  useSimulateMutation,
  useGetMeQuery,
  useGetUsersQuery,
  useGetUserByEmailQuery,
  useUpdateUserMutation,
  useSyncWithFirebaseMutation,
} = injectedRtkApi;
