import { liftLoadedState } from "models/loaded";
import { LoadedContent } from "src/components/common/loading/loadedContent";
import { usePlan } from "src/contexts/plan";
import {
  useChemicalElements,
  useChemistryGroups,
  useMaterialChemistry,
  useMaterialPhysics,
} from "contexts/search/provider";
import { Period } from "hooks/periodIndex";
import { PlanId } from "components/common/boundary/PlanId";
import { MaterialProperties } from "./properties";
import React from "react";
import { Overview } from "./overview";
import { MaterialConsumption } from "./consumption";
import { useTenantTranslation } from "hooks/formatters";
import { Layout } from "../layout";
import { Stack } from "@mui/material";
import { TitledTabs } from "components/common/titledTabs";

type BaseProps = {
  title: string;
  id: number;
  open: boolean;
  doClose: () => void;
};
type ConsumptionProps = {
  name: string;
  id: number;
  planId: PlanId;
  period: Period;
};

type Props = BaseProps | (BaseProps & ConsumptionProps);

export const MaterialDetailDrawer = ({
  title,
  id,
  open,
  doClose,
  ...props
}: Props) => {
  const isConsumption = "planId" in props && "period" in props;
  const width = isConsumption ? 1000 : 500;
  return (
    <Layout title={title} open={open} doClose={doClose} width={width}>
      {isConsumption ? (
        <Consumption
          name={title}
          id={id}
          planId={props.planId}
          period={props.period}
        />
      ) : (
        <Properties id={id} />
      )}
    </Layout>
  );
};

const Properties = ({ id }: { id: number }) => {
  const materialPhysics = useMaterialPhysics();
  const materialChemistry = useMaterialChemistry();
  const chemicalElements = useChemicalElements();

  const dependencies = liftLoadedState({
    materialPhysics,
    materialChemistry,
    chemicalElements,
  });

  return (
    <LoadedContent data={dependencies} disableFetching>
      {({ materialPhysics, materialChemistry, chemicalElements }) => (
        <MaterialProperties
          materialPhysics={materialPhysics.byId[id]!}
          materialChemistry={materialChemistry[id]!}
          elements={chemicalElements.byIndex}
        />
      )}
    </LoadedContent>
  );
};

const Consumption = ({ name, id, planId, period }: ConsumptionProps) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const { t } = useTenantTranslation();

  const chemistryGroups = useChemistryGroups();
  const materialPhysics = useMaterialPhysics();
  const materialChemistry = useMaterialChemistry();
  const chemicalElements = useChemicalElements();

  const { plan } = usePlan(planId);

  const dependencies = liftLoadedState({
    plan,
    chemistryGroups,
    materialPhysics,
    materialChemistry,
    chemicalElements,
  });

  return (
    <LoadedContent data={dependencies} disableFetching>
      {({
        plan,
        chemistryGroups,
        materialPhysics,
        materialChemistry,
        chemicalElements,
      }) => {
        const materialSummary = plan.summary.period_material_summary.find(
          (item) => item.material === name && item.period === period
        );
        const mixMaterialSummaries = plan.summary.mix_material_summary.filter(
          (item) => item.period === period
        );
        const mixSummaries = plan.summary.mix_summary.filter(
          (item) => item.period === period
        );

        return (
          <Stack sx={{ height: "100%" }}>
            <Overview summary={materialSummary} />
            <TitledTabs
              tabIndex={tabIndex}
              onChange={setTabIndex}
              tabSpecs={[
                {
                  title: t("consumption"),
                  key: "consumption",
                  content: (
                    <MaterialConsumption
                      name={name}
                      materialSummaries={mixMaterialSummaries}
                      chemistryGroups={chemistryGroups}
                      mixSummaries={mixSummaries}
                    />
                  ),
                },
                {
                  title: t("properties"),
                  key: "properties",
                  content: (
                    <MaterialProperties
                      materialPhysics={materialPhysics.byId[id]!}
                      materialChemistry={materialChemistry[id]!}
                      elements={chemicalElements.byIndex}
                    />
                  ),
                },
              ]}
            />
          </Stack>
        );
      }}
    </LoadedContent>
  );
};
