import { Route, Routes } from "react-router-dom";
import { BucketsView } from "./bucketsView";
import { RequireIsCraneDriver } from "src/routes/authentication";

export const LiveRoutes = () => {
  return (
    <Routes>
      <Route
        path="/buckets"
        element={
          <RequireIsCraneDriver>
            <BucketsView />
          </RequireIsCraneDriver>
        }
      />
    </Routes>
  );
};
