import { useMemo } from "react";
import { groupBy, sorted } from "helpers";
import {
  SearchChemistryGroups,
  SearchMaterials,
  SearchProductGroups,
} from "contexts/search/context";
import {
  MixMaterialSummary,
  MixSummary,
  ProductGroupMixMaterialSummary,
  ProductGroupMixSummary,
} from "src/store/api/generatedApi";
import { Period } from "hooks/periodIndex";
import { getMixSummaryName } from "src/utils/getMixSummaryName";
import { getMixSummaryNumber } from "src/utils/getMixSummaryNumber";

export type PlanTableOptions = {
  productGroups: boolean;
  percentages: boolean;
  forecast: boolean;
  steelGrades: string[];
};

export type ProductionContent = {
  groups: PlanGroup[];
  mixes: PlanMixes;
};

export type PlanMix = {
  materials: Record<number, number>;
  total: number;
  maximum: number;
  total_price: number;
  materials_price: number;
  electrical_energy_price: number;
};

export type PlanMixes = Record<number, Record<number, PlanMix>>;

export type PlanGroup = {
  group: number;
  mix: number;
  multipleMixes: boolean;
  heats: number;
};

export type PlanTableMaterial =
  | { material: number; summary?: undefined }
  | { summary: "total" }
  | { summary: "price" };

export type ConsumptionKey =
  | "inventoryInitial"
  | "consumption"
  | "inventoryFinal"
  | "obtainableRequired"
  | "obtainableRemaining";

export const useProductionMixes = (
  dependentMaterials: SearchMaterials,
  grouping: SearchProductGroups | SearchChemistryGroups,
  mixMaterialSummary: ProductGroupMixMaterialSummary[] | MixMaterialSummary[],
  mixSummaries: ProductGroupMixSummary[] | MixSummary[],
  period: Period
): PlanMixes => {
  return useMemo(() => {
    const summaries = mixMaterialSummary.filter(
      (item) => item.period === period
    );

    const mixes = groupBy(
      summaries,
      (summary) => grouping.byName[getMixSummaryName(summary)]!.id,
      (groupItems) =>
        groupBy(
          groupItems,
          (item) => getMixSummaryNumber(item),
          (mixItems) => {
            const materials = groupBy(
              mixItems,
              (item) => dependentMaterials.byName[item.material]!.id,
              (materialItem) => materialItem[0]!.recipe_mass
            );
            const firstMixItem = mixItems[0]!;

            return {
              materials,
              ...summarise(materials),
              total_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne ?? 0,
              materials_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne_materials ?? 0,
              electrical_energy_price:
                mixSummaries.find((item) => {
                  const { period } = firstMixItem;
                  return (
                    item.period === period &&
                    getMixSummaryName(item) ===
                      getMixSummaryName(firstMixItem) &&
                    getMixSummaryNumber(item) ===
                      getMixSummaryNumber(firstMixItem)
                  );
                })?.display_cost_per_tonne_electrical_energy ?? 0,
            };
          }
        )
    );

    return mixes;
  }, [
    dependentMaterials,
    grouping.byName,
    mixMaterialSummary,
    mixSummaries,
    period,
  ]);
};

export const useProductionGroups = (
  mixes: PlanMixes,
  mixSummaries: ProductGroupMixSummary[] | MixSummary[],
  grouping: SearchProductGroups | SearchChemistryGroups,
  period: Period
) => {
  return useMemo(() => {
    const groups = sorted(Object.entries(mixes), (item) =>
      parseInt(item[0])
    ).flatMap((group) =>
      sorted(Object.keys(group[1]), parseInt)
        .map((mix) => ({
          group: parseInt(group[0]),
          mix: parseInt(mix),
          multipleMixes: Object.keys(group[1]).length > 1,
        }))
        // Add in the number of heats for this group
        .map((group) => ({
          ...group,
          heats:
            mixSummaries
              .filter((item) => item.period === period)
              .find(
                (item) =>
                  getMixSummaryNumber(item) === group.mix &&
                  grouping.byName[getMixSummaryName(item)]!.id === group.group
              )?.num_heats ?? 0,
        }))
    );

    return groups;
  }, [grouping.byName, mixSummaries, mixes, period]);
};

const summarise = (materials: { [material: number]: number }) => {
  return {
    total: Object.values(materials).reduce((left, right) => left + right, 0),
    maximum: Math.max(...Object.values(materials)),
  };
};
