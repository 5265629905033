import { Box } from "@mui/material";

import { Bucket } from "./bucket";

const pollingIntervalMS = 10000;

export const BucketsView = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "12px 32px",
        height: "calc(100% - 24px)",
        overflow: "scroll",
        gap: 4,
      }}
    >
      <Bucket
        id={1}
        rowHeight={40}
        headerHeight={40}
        pollingIntervalMS={pollingIntervalMS}
      />
      <Bucket
        id={2}
        rowHeight={40}
        headerHeight={40}
        pollingIntervalMS={pollingIntervalMS}
      />
      <Bucket
        id={3}
        rowHeight={40}
        headerHeight={40}
        pollingIntervalMS={pollingIntervalMS}
      />
    </Box>
  );
};
