import { LoadedContent } from "src/components/common/loading/loadedContent";
import { useTargetBasketDependencies } from "./dependencies";
import { TargetBasketsTable } from "./table";

export const TargetBasketsEditor = () => {
  return (
    <LoadedContent data={useTargetBasketDependencies()} disableFetching>
      {({
        chemistryGroups,
        productGroups,
        productionPlans,
        targetBaskets,
        maxBaskets,
        steelGrades,
      }) => (
        <TargetBasketsTable
          chemistryGroups={chemistryGroups}
          productionPlans={productionPlans}
          productGroups={productGroups}
          targetBaskets={targetBaskets}
          maxBaskets={maxBaskets}
          steelGrades={steelGrades}
        />
      )}
    </LoadedContent>
  );
};
