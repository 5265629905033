import React from "react";

import { VERSION } from "src/constants";

export const useVersionRefresh = () => {
  React.useEffect(() => {
    const checkVersion = async () => {
      try {
        if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "development") {
          return;
        }

        const response = await fetch("/version.txt");
        const version = await response.text();

        if (version.trim() !== VERSION) {
          window.location.reload();
        }
      } catch (error) {
        console.warn("Failed to check version:", error);
      }
    };

    const interval = setInterval(checkVersion, 60000);
    return () => clearInterval(interval);
  }, []);
};
