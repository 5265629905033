import React from "react";
import { captureException } from "@sentry/react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { v4 as uuidv4 } from "uuid";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { createAction, createReducer, nanoid } from "@reduxjs/toolkit";
import { z } from "zod";
import {
  Alert,
  Box,
  Button,
  Container,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  MaterialRead,
  NamedPlanMix,
  useCreateBasketMaterialAdditionMutation,
  useGetContextForPlanQuery,
  useGetPlanMixesQuery,
  useListAllMaterialsQuery,
} from "store/api/generatedApi.tsx";

import { useTenant } from "hooks/settings.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";
import { useLocalStorage } from "hooks/useLocalStorage.ts";

import { BasketLoading } from "./BasketLoading";
import { createMapping } from "helpers";
import { useNumberSerialiser } from "hooks/serialisers/numbers.ts";

export const materialIdSchema = z.number().brand<"materialId">();
export const materialInstanceIdSchema = z
  .string()
  .nanoid()
  .brand<"materialInstanceId">();
export const basketNumberSchema = z.number().brand<"basketNumber">();
export const correlationIdSchema = z.string().uuid().brand<"correlationId">();

export const updateMassSchema = z.union([
  z.literal(-2),
  z.literal(-1.5),
  z.literal(-1),
  z.literal(-0.5),
  z.literal(2),
  z.literal(1.5),
  z.literal(1),
  z.literal(0.5),
]);

type MaterialId = z.infer<typeof materialIdSchema>;
type MaterialInstanceId = z.infer<typeof materialInstanceIdSchema>;
type BasketNumber = z.infer<typeof basketNumberSchema>;
type UpdateMass = z.infer<typeof updateMassSchema>;
type CorrelationId = z.infer<typeof correlationIdSchema>;

const additionalMaterialMassAdd = createAction(
  nanoid(),
  ([materialId, basketNumber, targetMass]: [
    MaterialId,
    BasketNumber,
    number,
  ]) => {
    return {
      payload: [
        materialInstanceIdSchema.parse(nanoid()),
        materialId,
        basketNumber,
        targetMass,
      ] as const,
    };
  }
);

const additionalMaterialMassRemove = createAction<
  [MaterialInstanceId, BasketNumber]
>(nanoid());
const updateAdditionalMaterialMass = createAction<
  [MaterialInstanceId, BasketNumber, UpdateMass]
>(nanoid());
const updateStoredMaterialMass = createAction<
  [MaterialId, BasketNumber, UpdateMass]
>(nanoid());
const submitBasket = createAction<BasketNumber>(nanoid());
const undo = createAction<BasketNumber>(nanoid());
const reset = createAction(nanoid());
const submitting = createAction<BasketNumber>(nanoid());
const success = createAction<BasketNumber>(nanoid());
const error = createAction<BasketNumber>(nanoid());
const idle = createAction<BasketNumber>(nanoid());

type MaterialMassAddition = {
  type: "material_mass_addition";
  instanceId: MaterialInstanceId;
  materialId: MaterialId;
  basketNumber: BasketNumber;
  targetMass: number;
};
type MaterialMassRemoval = {
  type: "material_mass_removal";
  instanceId: MaterialInstanceId;
  basketNumber: BasketNumber;
};

type UpdateAdditionalMaterialMass = {
  type: "update_additional_material_mass";
  instanceId: MaterialInstanceId;
  basketNumber: BasketNumber;
  mass: number;
};
type UpdateStoredMaterialMass = {
  type: "update_stored_material_mass";
  id: MaterialId;
  basketNumber: BasketNumber;
  mass: number;
};

type SubmitBasket = {
  type: "submit_basket";
  basketNumber: BasketNumber;
};
type StateEvent =
  | MaterialMassAddition
  | MaterialMassRemoval
  | UpdateAdditionalMaterialMass
  | UpdateStoredMaterialMass
  | SubmitBasket;

type Network = "idle" | "submitting" | "success" | "error";

type State = {
  correlationId: CorrelationId;
  events: StateEvent[];
  network: Record<BasketNumber, Network>;
};

const selectAdditionalMaterialMass = (
  state: State,
  basketNumber: BasketNumber,
  materialInstanceId: MaterialInstanceId
) => {
  return selectStateEvents(state)
    .filter(
      (stateEvent: StateEvent): stateEvent is UpdateAdditionalMaterialMass => {
        const { type } = stateEvent;
        return (
          type === "update_additional_material_mass" &&
          materialInstanceId === stateEvent.instanceId &&
          basketNumber === stateEvent.basketNumber
        );
      }
    )
    .reduce((total, stateEvent) => {
      return total + stateEvent.mass;
    }, 0);
};

const selectStoredMaterialMass = (
  state: State,
  materialId: MaterialId,
  basketNumber: BasketNumber
) => {
  return selectStateEvents(state)
    .filter((stateEvent): stateEvent is UpdateStoredMaterialMass => {
      const { type } = stateEvent;
      return (
        type === "update_stored_material_mass" &&
        stateEvent.id === materialId &&
        basketNumber === stateEvent.basketNumber
      );
    })
    .reduce((total, updateMass) => {
      return total + updateMass.mass;
    }, 0);
};

const listAdditionalMaterialMasses = (
  state: State,
  basketNumber: BasketNumber
) => {
  const stateEvents = selectStateEvents(state);
  const removeAdditionalMaterialMassesActions = stateEvents
    .filter(
      (stateEvent): stateEvent is MaterialMassRemoval =>
        stateEvent.type === "material_mass_removal" &&
        stateEvent.basketNumber === basketNumber
    )
    .map((action) => action.instanceId);
  return stateEvents
    .filter((stateEvent): stateEvent is MaterialMassAddition => {
      return stateEvent.type === "material_mass_addition";
    })
    .filter(
      (stateEvent) =>
        !removeAdditionalMaterialMassesActions.includes(
          stateEvent.instanceId
        ) && stateEvent.basketNumber === basketNumber
    )
    .map((stateEvent) => {
      return {
        instanceId: stateEvent.instanceId,
        materialId: stateEvent.materialId,
        targetMass: stateEvent.targetMass,
        currentMass: selectAdditionalMaterialMass(
          state,
          basketNumber,
          stateEvent.instanceId
        ),
        type: "additional" as const,
      };
    });
};

const listStoredMaterialMasses = (
  materialMasses: Record<string, number>,
  basketNumber: BasketNumber,
  state: State
) => {
  return Object.keys(materialMasses)
    .map((id) => materialIdSchema.parse(Number(id)))
    .map((id) => ({
      id,
      targetMass: z.number().parse(materialMasses[id]),
      currentMass: selectStoredMaterialMass(
        state,
        id,
        basketNumberSchema.parse(basketNumber)
      ),
      type: "stored" as const,
    }));
};

const selectIsSubmitted = (basketNumber: BasketNumber, state: State) => {
  return Boolean(
    selectStateEvents(state).find((stateEvent) => {
      return (
        stateEvent.type === "submit_basket" &&
        stateEvent.basketNumber === basketNumber
      );
    })
  );
};

const selectCanUndo = (
  basketNumber: BasketNumber,
  stateEvents: StateEvent[]
) => {
  return (
    stateEvents.filter((stateEvent) => stateEvent.basketNumber === basketNumber)
      .length > 0
  );
};
const selectCanRestartHeat = (stateEvents: StateEvent[]) => {
  return stateEvents.length > 0;
};

const selectStateEvents = (state: State) => state.events;
const selectStateNetwork = (
  state: State,
  basketNumber: BasketNumber
): Network => state.network[basketNumber]!;

const initializeReducer = (
  initialState: Omit<State, "network">,
  basketNumbers: BasketNumber[]
) => {
  const initialNetworkState = basketNumbers.reduce(
    (networkState, basketNumber) => ({
      ...networkState,
      [basketNumber]: "idle",
    }),
    {} as State["network"]
  );
  const reducer = createReducer(
    { ...initialState, network: initialNetworkState } as State,
    (builder) => {
      builder
        .addCase(
          additionalMaterialMassAdd,
          (
            state,
            { payload: [instanceId, materialId, basketNumber, targetMass] }
          ) => {
            state.events.push({
              type: "material_mass_addition",
              instanceId,
              materialId,
              basketNumber,
              targetMass,
            });
            state.network[basketNumber] = "idle";
          }
        )
        .addCase(
          additionalMaterialMassRemove,
          (state, { payload: [instanceId, basketNumber] }) => {
            state.events.push({
              type: "material_mass_removal",
              instanceId,
              basketNumber,
            });
            state.network[basketNumber] = "idle";
          }
        )
        .addCase(
          updateAdditionalMaterialMass,
          (state, { payload: [instanceId, basketNumber, mass] }) => {
            state.events.push({
              type: "update_additional_material_mass",
              instanceId,
              basketNumber,
              mass,
            });
            state.network[basketNumber] = "idle";
          }
        )
        .addCase(
          updateStoredMaterialMass,
          (state, { payload: [id, basketNumber, mass] }) => {
            state.events.push({
              type: "update_stored_material_mass",
              id,
              basketNumber,
              mass,
            });
            state.network[basketNumber] = "idle";
          }
        )
        .addCase(submitBasket, (state, { payload: basketNumber }) => {
          state.events.push({
            type: "submit_basket",
            basketNumber: basketNumber,
          });
        })
        .addCase(undo, (state, { payload: basketNumber }) => {
          return {
            correlationId: state.correlationId,
            events: [
              ...state.events.filter(
                (stateEvent) => stateEvent.basketNumber !== basketNumber
              ),
              ...state.events
                .filter(
                  (stateEvent) => stateEvent.basketNumber === basketNumber
                )
                .slice(0, -1),
            ],
            network: {
              ...state.network,
              [basketNumber]: "idle",
            },
          };
        })
        .addCase(reset, () => ({
          correlationId: correlationIdSchema.parse(uuidv4()),
          events: [],
          network: initialNetworkState,
        }))
        .addCase(idle, (state, { payload }) => {
          state.network[payload] = "idle";
        })
        .addCase(success, (state, { payload }) => {
          state.network[payload] = "success";
        })
        .addCase(submitting, (state, { payload }) => {
          state.network[payload] = "submitting";
        })
        .addCase(error, (state, { payload }) => {
          state.network[payload] = "error";
        });
    }
  );
  return [reducer, reducer.getInitialState()] as const;
};

const useSnackbarMessage = (basketNumber: BasketNumber) => {
  const { t } = useTenantTranslation();
  const messageBuilder = React.useCallback(
    (network: Network): string => {
      switch (network) {
        case "error":
          return t("submitBasketError", {
            basketNumber: basketNumber.toString(),
          });
        case "success":
          return t("submitBasketSuccess", {
            basketNumber: basketNumber.toString(),
          });
        case "submitting":
          return t("submitBasketSubmitting", {
            basketNumber: basketNumber.toString(),
          });
        case "idle":
          return "";
      }
    },
    [basketNumber]
  );
  return messageBuilder;
};

const getSnackbarSeverity = (
  network: Network
): "error" | "success" | "info" => {
  switch (network) {
    case "error":
      return "error";
    case "idle":
    case "submitting":
      return "info";
    case "success":
      return "success";
  }
};

const LoadingInputs = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns:
          "repeat(2, 3fr) 2fr 1fr repeat(4, 3fr) 2fr repeat(4, 3fr)",
        gridTemplateRows: "60px 80px 60px 150px repeat(8, 50px)",
        rowGap: "24px",
        columnGap: "8px",
        width: "calc(100% - 156px)",
        maxWidth: "1200px",
        minWidth: "600px",
        margin: "0 auto",
      }}
    >
      <Box sx={{ gridColumn: "1/-1", display: "flex", gap: 2 }}>
        <Skeleton>
          <Typography sx={{ fontSize: 32 }}>Back to grade selection</Typography>
        </Skeleton>
        <Skeleton sx={{ marginLeft: "auto" }}>
          <Typography sx={{ fontSize: 32 }}>Restart heat</Typography>
        </Skeleton>
        <Skeleton>
          <Typography sx={{ fontSize: 32 }}>Undo </Typography>
        </Skeleton>
      </Box>
      <Skeleton
        sx={{ gridColumn: "1/-1", height: "100%", justifySelf: "center" }}
      >
        <Typography sx={{ fontSize: 48 }}>Steelgrades</Typography>
      </Skeleton>
      <Box
        sx={{
          gridColumn: "1/-1",
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="rectangular" sx={{ width: "50%", height: "100%" }} />
        <Skeleton variant="rectangular" sx={{ width: "50%", height: "100%" }} />
      </Box>
      <Skeleton
        variant="rectangular"
        sx={{ gridColumn: "1/-1", height: "100%" }}
      />
      {new Array(8).fill(null).map((_, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <div />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export const GradeMaterialsInput = () => {
  const tenant = useTenant();
  const params = useParams();
  const { t } = useTenantTranslation();
  const {
    data: planMixes,
    isLoading: planMixesLoading,
    isError: planMixesError,
  } = useGetPlanMixesQuery({
    tenantName: tenant,
    planId: "latest",
    period: 1,
  });
  const {
    data: planContext,
    isLoading: isContextLoading,
    isUninitialized: isUninitialised,
    isError: planContextError,
  } = useGetContextForPlanQuery(
    planMixes
      ? {
          tenantName: tenant,
          planId: planMixes.plan_id,
        }
      : skipToken
  );
  const { data: materials = [], isLoading: isMaterialsLoading } =
    useListAllMaterialsQuery({
      tenantName: tenant,
    });

  const materialsNameLookup = React.useMemo(
    () =>
      new Map(
        materials.map((material) => [
          materialIdSchema.parse(material.id),
          material.name,
        ])
      ),
    [materials]
  );

  if (
    planMixes &&
    planContext &&
    !isMaterialsLoading &&
    "steel_grade_id" in params &&
    "basket_number" in params &&
    params.steel_grade_id !== undefined &&
    params.basket_number !== undefined
  ) {
    const steelGradesNameMap = createMapping(
      planContext.steel_grades,
      (sg) => sg.name
    );
    const { steel_grade_id } = params;
    const steelGradeId = steelGradesNameMap[steel_grade_id]!.id;
    const relevantMix = Object.values(planMixes.mixes).find((mix) =>
      mix.steel_grade_ids.includes(steelGradeId)
    );
    if (relevantMix) {
      const { basket_number } = params;
      const originalBasket = relevantMix.baskets[basket_number];
      if (originalBasket) {
        return (
          <Body
            planId={planMixes.plan_id}
            mix={relevantMix}
            steelGradeId={params.steel_grade_id}
            basketNumber={basketNumberSchema.parse(Number(basket_number))}
            materialNames={materialsNameLookup}
            materials={materials}
          />
        );
      }
    }
  } else if (
    isUninitialised ||
    isContextLoading ||
    planMixesLoading ||
    isMaterialsLoading
  ) {
    return <LoadingInputs />;
  } else if (planMixesError || planContextError) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          <Alert severity="error">{t("errorLoadingPlanData")}</Alert>
        </Alert>
      </Box>
    );
  }
  return <Navigate to={`/${tenant}/crane/driver`} />;
};

type BodyProps = {
  mix: NamedPlanMix;
  planId: number;
  steelGradeId: string;
  basketNumber: BasketNumber;
  materialNames: Map<MaterialId, string>;
  materials: MaterialRead[];
};

const version = 3;

const Body = ({
  planId,
  mix,
  steelGradeId,
  basketNumber,
  materialNames,
  materials,
}: BodyProps) => {
  const tenant = useTenant();
  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser({ decimalPlaces: 0 });
  const timeoutRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const [createBasketMaterialAddition] =
    useCreateBasketMaterialAdditionMutation();

  const navigate = useNavigate();
  const currentTab = basketNumber - 1;

  const [stored, setStored] = useLocalStorage<Omit<State, "network">>(
    `crane.${planId}.${steelGradeId}.${version}`,
    {
      correlationId: correlationIdSchema.parse(uuidv4()),
      events: [],
    }
  );

  const [state, dispatch] = React.useReducer(
    ...initializeReducer(
      stored,
      Object.keys(mix.baskets).map((basketNumber) =>
        basketNumberSchema.parse(Number(basketNumber))
      )
    )
  );

  const isSubmitted = selectIsSubmitted(basketNumber, state);

  const makeSnackbarMessage = useSnackbarMessage(basketNumber);

  React.useEffect(() => {
    setStored(state);
  }, [state, setStored]);

  const handleBasketSubmit = async (basketNumber: BasketNumber) => {
    const storedMasses = selectStateEvents(state)
      .filter((stateEvent): stateEvent is UpdateStoredMaterialMass => {
        return stateEvent.type === "update_stored_material_mass";
      })
      .filter((stateEvent) => stateEvent.basketNumber === basketNumber)
      .reduce(
        (masses, storedMass) => ({
          ...masses,
          [storedMass.id]: masses[storedMass.id] ?? 0 + storedMass.mass,
        }),
        {} as Record<MaterialId, number>
      );
    const additionalMasses = listAdditionalMaterialMasses(
      state,
      basketNumber
    ).reduce(
      (masses, additionalMass) => ({
        ...masses,
        [additionalMass.materialId]:
          masses[additionalMass.materialId] ?? 0 + additionalMass.currentMass,
      }),
      {} as Record<MaterialId, number>
    );

    try {
      dispatch(submitting(basketNumber));
      await createBasketMaterialAddition({
        planId,
        tenantName: tenant,
        basketMaterialAdditionCreate: {
          basket_number: basketNumber,
          material_masses: Object.fromEntries(
            Object.entries({
              ...additionalMasses,
              ...storedMasses,
            }).map(
              ([materialId, mass]) =>
                [
                  materialNames.get(materialIdSchema.parse(Number(materialId))),
                  mass,
                ] as const
            )
          ) as { [key in string]: number },
          correlation_id: state.correlationId,
        },
      });
      dispatch(success(basketNumber));
      dispatch(submitBasket(basketNumber));
      timeoutRef.current = setTimeout(() => {
        dispatch(idle(basketNumber));
      }, 2000);
    } catch (e) {
      captureException(e);
      dispatch(error(basketNumber));
    }
  };

  const handleCloseSnackbar = () => {
    dispatch(idle(basketNumber));
  };

  const canUndo = selectCanUndo(basketNumber, selectStateEvents(state));
  const canRestartHeat = selectCanRestartHeat(selectStateEvents(state));
  const network = selectStateNetwork(state, basketNumber);
  const showSnackbar = network !== "idle";
  const snackbarMessage = makeSnackbarMessage(network);
  const snackbarSeverity = getSnackbarSeverity(network);

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Box
        sx={{
          mb: 4,
          display: "grid",
          columnGap: 2,
          gridTemplateColumns: "1fr max-content min-content",
          gridAutoRows: "content",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(`/${tenant}/crane/driver`)}
          size="large"
          sx={{ marginRight: "auto" }}
        >
          {t("backToGradeSelection")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => dispatch(reset())}
          size="large"
          disabled={!canRestartHeat}
        >
          {t("restartHeat")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => dispatch(undo(basketNumber))}
          size="large"
          disabled={!canUndo}
        >
          {t("undo")}
        </Button>

        <Typography
          sx={{ justifySelf: "center", fontSize: 48, gridColumn: "1/-1" }}
        >
          {steelGradeId}
        </Typography>

        <Tabs
          value={currentTab}
          onChange={(_, newValue: number) =>
            navigate(`/${tenant}/crane/driver/${steelGradeId}/${newValue + 1}`)
          }
          variant="fullWidth"
          sx={{ mb: 3, width: "100%", gridColumn: "1/-1" }}
        >
          {Object.entries(mix.baskets).map(([basketNumber, basket]) => (
            <Tab
              key={basketNumber}
              label={`${t("basket")} ${basketNumber} - ${format(
                basket.total_mass
              )}t`}
              sx={{ fontSize: "1.6rem" }}
            />
          ))}
        </Tabs>

        {Object.entries(mix.baskets).map(([basketNumberStr, basket], index) => {
          const basketNumber = basketNumberSchema.parse(
            Number(basketNumberStr)
          );
          return (
            <Box
              key={basketNumber}
              role="tabpanel"
              hidden={currentTab !== index}
              sx={{ gridColumn: "1/-1" }}
            >
              {currentTab === index && (
                <BasketLoading
                  materials={materials}
                  materialNames={materialNames}
                  canSubmit={!isSubmitted}
                  storedMasses={listStoredMaterialMasses(
                    basket.material_masses,
                    basketNumber,
                    state
                  )}
                  onAdditionalMassUpdate={(materialInstanceId, massAmount) => {
                    dispatch(
                      updateAdditionalMaterialMass([
                        materialInstanceId,
                        basketNumber,
                        massAmount,
                      ])
                    );
                  }}
                  onStoredMassUpdate={(materialId, massAmount) => {
                    dispatch(
                      updateStoredMaterialMass([
                        materialId,
                        basketNumber,
                        massAmount,
                      ])
                    );
                  }}
                  onRemoveMaterial={(materialInstanceId) =>
                    dispatch(
                      additionalMaterialMassRemove([
                        materialInstanceId,
                        basketNumber,
                      ])
                    )
                  }
                  additionalMasses={listAdditionalMaterialMasses(
                    state,
                    basketNumber
                  )}
                  onSubmit={() => handleBasketSubmit(basketNumber)}
                  onAddMaterial={(materialId) =>
                    dispatch(
                      additionalMaterialMassAdd([materialId, basketNumber, 0])
                    )
                  }
                />
              )}
            </Box>
          );
        })}
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};
