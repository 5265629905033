import { typeSafeObjectEntries } from "./typeSafeObjectEntries";

export const sumRecords = <
  K1 extends string | number,
  K2 extends string | number,
>(
  record1: Record<K1, number>,
  record2: Record<K2, number>
): Record<K1 | K2, number> => {
  return typeSafeObjectEntries(record1).reduce(
    (sumRecord, [key, value]) => {
      return {
        ...sumRecord,
        [key]: key in sumRecord ? sumRecord[key] + value : value,
      };
    },
    record2 as Record<K1 | K2, number>
  );
};
