import { Navigate, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { SelectTenant } from "components/settings/tenant";
import { useTabName } from "components/common/TabTitle";
import { NavBarLayout } from "components/common/NavBarLayout";
import { SignInPage } from "components/signIn";
import { NotFound } from "components/notFound";
import { LiveRoutes } from "components/live/routes";

import {
  RequireUser,
  RequireTenant,
  RequireIsCraneDriver,
  ValidateUser,
  RequireRead,
  RequireFeatureFlags,
} from "./authentication";
import { AdminRoutes } from "./admin";
import { TenantRoutes } from "./tenant";
import { CraneRoutes } from "./crane";
import { CraneDriverRedirect } from "./tenantRedirects";

export const AppRoutes = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  useTabName();

  return (
    <SentryRoutes>
      <Route path="/sign-in" element={<SignInPage />} />

      <Route
        path="/"
        element={
          <RequireUser>
            <SelectTenant />
          </RequireUser>
        }
      />

      <Route
        path="/:tenant/*"
        element={
          <RequireUser>
            <ValidateUser>
              <RequireTenant>
                <Routes>
                  <Route
                    path="/live/*"
                    element={
                      <RequireIsCraneDriver>
                        <NavBarLayout isStatic>
                          <LiveRoutes />
                        </NavBarLayout>
                      </RequireIsCraneDriver>
                    }
                  />
                  <Route
                    path="/crane/*"
                    element={
                      <RequireIsCraneDriver>
                        <NavBarLayout isStatic>
                          <RequireFeatureFlags
                            featureFlags={["manual_entry_crane_ui"]}
                          >
                            <CraneRoutes />
                          </RequireFeatureFlags>
                        </NavBarLayout>
                      </RequireIsCraneDriver>
                    }
                  />
                  <Route path="/v3/crane/*" element={<CraneDriverRedirect />} />
                  <Route
                    path="*"
                    element={
                      <RequireRead>
                        <NavBarLayout>
                          <Routes>
                            <Route path="/v3/*" element={<TenantRoutes />} />

                            <Route path="/admin/*" element={<AdminRoutes />} />

                            <Route
                              path="/"
                              element={<Navigate to="./v3" replace />}
                            />

                            <Route path="/*" element={<NotFound />} />
                          </Routes>
                        </NavBarLayout>
                      </RequireRead>
                    }
                  />
                </Routes>
              </RequireTenant>
            </ValidateUser>
          </RequireUser>
        }
      />
    </SentryRoutes>
  );
};
