import React from "react";
import { z } from "zod";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import { StatusIndicator } from "./StatusIndicator";
import { getMassStatus } from "./utils";
import { MaterialRead } from "store/api/generatedApi.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";
import { useNumberSerialiser } from "hooks/serialisers/numbers.ts";
import {
  materialInstanceIdSchema,
  materialIdSchema,
  updateMassSchema,
} from "./GradeMaterialsInput";

type MaterialInstanceId = z.infer<typeof materialInstanceIdSchema>;
type MaterialId = z.infer<typeof materialIdSchema>;

type AdditionalMaterialMass = {
  instanceId: MaterialInstanceId;
  materialId: MaterialId;
  targetMass: number;
  currentMass: number;
  type: "additional";
};
type StoredMaterialMass = {
  id: MaterialId;
  targetMass: number;
  currentMass: number;
  type: "stored";
};

type UpdateMass = z.infer<typeof updateMassSchema>;

interface BasketLoadingProps {
  storedMasses: StoredMaterialMass[];
  additionalMasses: AdditionalMaterialMass[];
  onAdditionalMassUpdate: (
    materialInstanceId: MaterialInstanceId,
    mass: UpdateMass
  ) => void;
  canSubmit: boolean;
  materialNames: Map<MaterialId, string>;
  materials: MaterialRead[];
  onStoredMassUpdate: (materialId: MaterialId, mass: UpdateMass) => void;
  onSubmit: () => void;
  onAddMaterial: (materialId: MaterialId) => void;
  onRemoveMaterial: (materialInstanceId: MaterialInstanceId) => void;
}

export const BasketLoading: React.FC<BasketLoadingProps> = ({
  storedMasses,
  additionalMasses,
  onAdditionalMassUpdate,
  onStoredMassUpdate,
  onSubmit,
  onAddMaterial,
  canSubmit,
  onRemoveMaterial,
  materialNames,
  materials,
}) => {
  const { t } = useTenantTranslation();
  const { format } = useNumberSerialiser({ decimalPlaces: 0 });

  const isBasketEmpty = [...storedMasses, ...additionalMasses].every(
    (materialMass) => materialMass.currentMass === 0
  );

  const storedMaterialMassMaterialIds = storedMasses.map(
    (storedMass) => storedMass.id
  );
  const additionalMaterialMassMaterialIds = additionalMasses.map(
    (additionalMass) => additionalMass.materialId
  );

  const otherMaterials = materials.filter((material) => {
    const isInStoredMasses = storedMaterialMassMaterialIds.includes(
      materialIdSchema.parse(material.id)
    );
    const isInAdditionalMasses = additionalMaterialMassMaterialIds.includes(
      materialIdSchema.parse(material.id)
    );
    const isBasketLoaded = material.addition_location === "basket";
    return !isInStoredMasses && !isInAdditionalMasses && isBasketLoaded;
  });

  return (
    <Card sx={{ mb: 2, position: "relative" }}>
      {!canSubmit && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            backgroundColor: "#f6f6f6",
            opacity: 0.8,
          }}
        />
      )}
      <CardContent>
        {/* Material Selection Tray */}
        <Paper
          elevation={3}
          sx={{
            p: 2,
            mb: 3,
            backgroundColor: "grey.100",
            display: otherMaterials.length > 0 ? "block" : "none",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("otherMaterials")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              maxHeight: "150px",
              overflowY: "auto",
            }}
          >
            {otherMaterials
              .sort((a, b) => a.ordering - b.ordering)
              .map((material) => {
                const { id, name } = material;
                return (
                  <Chip
                    key={id}
                    label={name}
                    onClick={() => onAddMaterial(materialIdSchema.parse(id))}
                    sx={{
                      fontSize: "1.4rem",
                      p: 2.5,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                      },
                    }}
                  />
                );
              })}
          </Box>
        </Paper>

        {/* Current Materials */}
        <Grid container spacing={3}>
          {[...additionalMasses, ...storedMasses].map((materialMass) => {
            const { targetMass, currentMass, type } = materialMass;
            const materialId = (() => {
              switch (type) {
                case "additional":
                  return materialMass.materialId;
                case "stored":
                  return materialMass.id;
              }
            })();
            const status = getMassStatus(currentMass, targetMass);

            return (
              <Grid item xs={12} key={materialId}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      maxWidth: "150px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <StatusIndicator status={status} />
                      <Typography sx={{ fontSize: "2rem" }}>
                        {materialNames.get(materialId)}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {format(targetMass)}t
                    </Typography>
                  </Box>
                  {type === "additional" ? (
                    <IconButton
                      sx={{ marginLeft: 2 }}
                      onClick={() => onRemoveMaterial(materialMass.instanceId)}
                    >
                      <Delete />
                    </IconButton>
                  ) : null}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0,
                      flexGrow: 1,
                      justifyContent: "right",
                    }}
                  >
                    <ButtonGroup
                      size="large"
                      variant="outlined"
                      color="primary"
                    >
                      {([-2, -1.5, -1, -0.5] as [-2, -1.5, -1, -0.5]).map(
                        (decrement: UpdateMass) => {
                          return (
                            <Button
                              key={decrement}
                              disabled={currentMass + decrement < 0}
                              onClick={() => {
                                switch (type) {
                                  case "additional": {
                                    const { instanceId } = materialMass;
                                    onAdditionalMassUpdate(
                                      instanceId,
                                      decrement
                                    );
                                    break;
                                  }
                                  case "stored": {
                                    const { id } = materialMass;
                                    onStoredMassUpdate(id, decrement);
                                    break;
                                  }
                                }
                              }}
                              sx={{ fontSize: "2rem", width: "80px" }}
                            >
                              {decrement}t
                            </Button>
                          );
                        }
                      )}
                    </ButtonGroup>

                    <Typography
                      variant="h3"
                      sx={{ width: "60px", textAlign: "center" }}
                    >
                      {currentMass}
                    </Typography>
                    <ButtonGroup
                      size="large"
                      variant="outlined"
                      color="primary"
                    >
                      {([0.5, 1, 1.5, 2] as [0.5, 1, 1.5, 2]).map(
                        (increment: UpdateMass) => {
                          return (
                            <Button
                              key={increment}
                              onClick={() => {
                                switch (type) {
                                  case "additional": {
                                    const { instanceId } = materialMass;
                                    onAdditionalMassUpdate(
                                      instanceId,
                                      updateMassSchema.parse(increment)
                                    );
                                    break;
                                  }
                                  case "stored": {
                                    const { id } = materialMass;
                                    onStoredMassUpdate(
                                      id,
                                      updateMassSchema.parse(increment)
                                    );
                                    break;
                                  }
                                }
                              }}
                              sx={{ fontSize: "2rem", width: "80px" }}
                            >
                              +{increment}t
                            </Button>
                          );
                        }
                      )}
                    </ButtonGroup>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography sx={{ fontSize: "2rem" }}>
                {t("totalMass")}:{" "}
                {format(
                  [...storedMasses, ...additionalMasses].reduce(
                    (total, materialMass) => total + materialMass.currentMass,
                    0
                  )
                )}
                t
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onSubmit}
                disabled={!canSubmit || isBasketEmpty}
                sx={{ py: 2, px: 4 }}
              >
                {t("submitBasket")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
