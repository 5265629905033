import { LicenseInfo } from "@mui/x-license";

export const VERSION = "0.1.0-local";
export const backendURL = import.meta.env.VITE_BACKEND_URL;
export const azureTenantId = import.meta.env.VITE_AZURE_TENANT_ID;
export const meticulousProjectId = import.meta.env.VITE_METICULOUS_PROJECT_ID;
export const sentryReplaySessionSampleRate = parseInt(
  import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE
);
export const sentryReplayErrorSampleRate = parseInt(
  import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE
);
export const MAX_REVISION_HISTORY_LENGTH = 100;

export const languages: { [language: string]: string } = {
  "en-GB": "English",
  "de-DE": "Deutsch",
  "pl-PL": "Polski",
  "es-AR": "Español",
  "fr-FR": "Français",
};

export const defaultLanguage = "en-GB";

export const materialGroupColours: { [group: string]: string } = {
  // `snake_case` is used to match the data returned by the python backend
  heavy_scrap: "#d7301f",
  heavy_small_scrap: "#fdcc8a",
  light_scrap: "#0570b0",
  "light_scrap/light_small_scrap": "#74a9cf",
  light_small_scrap: "#bdc9e1",
  dri: "#36454F",
};

// Configure authentication via Firebase
export const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
};

LicenseInfo.setLicenseKey(
  "c6200b8b64eb6e877081d0a3619158e2Tz04NDUyOSxFPTE3Mzk3OTUyNDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export const recipeDecimalPlaces = 1;
