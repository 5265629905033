import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  NamedPlanMixes,
  SyncedSearchContext,
  useGetContextForPlanQuery,
  useGetPlanMixesQuery,
} from "store/api/generatedApi.tsx";
import { useTenantTranslation } from "hooks/formatters.tsx";
import { useTenant } from "hooks/settings";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { createMapping } from "helpers";

interface GradeSelectionProps {
  planMixes: NamedPlanMixes;
  planContext: SyncedSearchContext;
  onGradeSelect: (grade: string) => void;
}

interface GradesByGroup {
  [copperGroup: string]: string[];
}

export const GradeSelection = () => {
  const tenant = useTenant();
  const { data: planMixes } = useGetPlanMixesQuery({
    tenantName: tenant,
    planId: "latest",
    period: 1,
  });
  const { data: planContext } = useGetContextForPlanQuery(
    planMixes
      ? {
          tenantName: tenant,
          planId: planMixes.plan_id,
        }
      : skipToken
  );
  const navigate = useNavigate();
  if (planMixes && planContext) {
    return (
      <Body
        planMixes={planMixes}
        planContext={planContext}
        onGradeSelect={(grade) => {
          navigate(`/${tenant}/crane/driver/${grade}/1`);
        }}
      />
    );
  }
};

const Body: React.FC<GradeSelectionProps> = ({
  planMixes,
  planContext,
  onGradeSelect,
}) => {
  const { t } = useTenantTranslation();
  const productGroupsIdMap = createMapping(
    planContext.product_groups,
    (pg) => pg.id!
  );
  const steelGradesIdMap = createMapping(
    planContext.steel_grades,
    (sg) => sg.id
  );

  // Organize grades by copper group
  const gradesByGroup = Object.values(planMixes.mixes).reduce<GradesByGroup>(
    (acc, mix) => {
      if (Object.keys(mix.baskets).length > 0) {
        const copperGroup = productGroupsIdMap[mix.product_group_id]!.name;
        const steelGrades = mix.steel_grade_ids.map(
          (sg) => steelGradesIdMap[sg]!.name
        );
        if (!acc[copperGroup]) {
          return { ...acc, [copperGroup]: [...steelGrades] };
        }
        return {
          ...acc,
          [copperGroup]: Array.from(
            new Set([...(acc[copperGroup] ?? []), ...steelGrades])
          ),
        };
      } else {
        return acc;
      }
    },
    {}
  );

  // Sort copper groups and grades lexicographically within each group
  const sortedGroups = Object.entries(gradesByGroup)
    .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
    .map(
      ([group, grades]) =>
        [group, [...grades].sort((a, b) => a.localeCompare(b))] as [
          string,
          string[],
        ]
    );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        {t("selectSteelGrade")}
      </Typography>

      {sortedGroups.map(([copperGroup, grades]) => (
        <Box key={copperGroup} sx={{ mb: 6 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              px: 3,
              py: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="h5">{copperGroup}</Typography>
          </Box>

          <Grid container spacing={2}>
            {grades.map((grade) => (
              <Grid item xs={12} sm={6} md={4} key={grade}>
                <Card
                  onClick={() => onGradeSelect(grade)}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      bgcolor: "action.hover",
                      transform: "scale(1.02)",
                    },
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 2,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontWeight: 500 }}
                    >
                      {grade}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
