import { LoadingButton } from "@mui/lab";
import { useTenant } from "hooks/settings";
import { useState } from "react";
import { backendApi } from "src/store/api/enhancedApi";
import { useMaterialNames } from "./loading";
import { LoadingWheel } from "components/common/loading/loadingWheel";
import { MultiSelect } from "components/common/inputs/multiSelect";
import { IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Select } from "components/common/inputs/select";

export const SelectVideo = ({
  videoKey,
  setVideoKey,
  camera,
  setCamera,
}: {
  videoKey: string | null;
  setVideoKey: (key: string | null) => void;
  camera: number;
  setCamera: (camera: number) => void;
}) => {
  const [requiredMaterials, setRequiredMaterials] = useState<string[]>([]);

  const [suggestVideoKey, { loading }] = useSuggestVideoKey(setVideoKey);
  const [allMaterials, materialsLoading] = useMaterialNames();

  return (
    <>
      <Typography>{videoKey ?? "All done"}</Typography>

      <Select<number>
        options={[1, 2, 3]}
        value={camera}
        setValue={setCamera}
        width="sm"
        format={(value) => ({ key: value.toString(), label: value.toString() })}
        label="Camera"
      />

      <IconButton
        onClick={() => {
          const newVideoKey = prompt(
            "Select video key (format YYYYMMDD_hhmmss):"
          );
          setVideoKey((newVideoKey ?? "").length === 0 ? null : newVideoKey);
        }}
      >
        <SearchIcon sx={{ fontSize: 20 }} />
      </IconButton>

      {materialsLoading ? (
        <LoadingWheel />
      ) : (
        <MultiSelect
          sx={{ width: 400 }}
          label="Require at least one of"
          options={allMaterials ?? []}
          values={requiredMaterials}
          setValues={setRequiredMaterials}
        />
      )}

      <LoadingButton
        loading={loading}
        onClick={() => {
          suggestVideoKey(requiredMaterials);
        }}
      >
        Suggest vido
      </LoadingButton>
    </>
  );
};

const useSuggestVideoKey = (setVideoKey: (videoKey: string | null) => void) => {
  const tenant = useTenant();
  const [suggestVideo] = backendApi.endpoints.suggestVideo.useLazyQuery();

  const [loading, setLoading] = useState(false);

  const suggestKey = (requiredMaterials: string[]) => {
    setVideoKey(null);
    setLoading(true);
    void suggestVideo({
      tenantName: tenant,
      requiredMaterials:
        // FastAPI has issues with empty string arrays
        requiredMaterials.length === 0 ? ["all"] : requiredMaterials,
    })
      .unwrap()
      .then(setVideoKey)
      .finally(() => setLoading(false));
  };

  return [suggestKey, { loading }] as const;
};
