import { useEffect, useState } from "react";

export const useLocalStorageState = <T,>(key: string, value: T) => {
  const storedValue = localStorage.getItem(key);
  const [state, setState] = useState<T>(
    storedValue === null ? value : (JSON.parse(storedValue) as T)
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState] as const;
};
