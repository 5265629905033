import {
  SearchChemistryGroups,
  SearchProductGroups,
  SearchSteelGrades,
} from "contexts/search/context";
import {
  useBaskets,
  useChemistryGroups,
  usePeriods,
  useProductGroups,
  useSteelGrades,
  useTargetBaskets,
} from "contexts/search/provider";
import { Loaded, liftLoadedState, mapLoaded } from "models/loaded";
import { useMemo } from "react";
import { productionPlanToSteelGradeIds } from "src/utils/productionPlanToSteelGradeIds";
import { SyncedProductionPlan, TargetBasketItem } from "store/api/generatedApi";

export type TargetBasketDependencies = {
  productionPlans: SyncedProductionPlan[];
  productGroups: SearchProductGroups;
  chemistryGroups: SearchChemistryGroups;
  targetBaskets: TargetBasketItem[];
  steelGrades: SearchSteelGrades;
  maxBaskets: number;
};

export const useTargetBasketDependencies =
  (): Loaded<TargetBasketDependencies> => {
    const [periods] = usePeriods();
    const productGroups = useProductGroups();
    const chemistryGroups = useChemistryGroups();
    const [targetBaskets] = useTargetBaskets();
    const steelGrades = useSteelGrades();
    const baskets = useBaskets();

    const productionPlans = mapLoaded(periods, (periods) =>
      periods.map((period) => period.production_plan)
    );

    return liftLoadedState({
      productionPlans,
      productGroups,
      chemistryGroups,
      targetBaskets,
      steelGrades,
      maxBaskets: mapLoaded(baskets, (b) => b.length),
    });
  };

/**
 * Work out which chemistry group IDs are present in the production plan.
 */
export const usePresentChemistryGroups = (
  productionPlans: SyncedProductionPlan[],
  productGroups: SearchProductGroups,
  chemistryGroups: SearchChemistryGroups
): number[] =>
  useMemo(() => {
    const steelGrades = new Set(
      productionPlans.flatMap((productionPlan) =>
        productionPlanToSteelGradeIds(productionPlan, productGroups)
      )
    );

    return chemistryGroups.byIndex
      .filter((group) =>
        group.steel_grades.some(({ id }) => steelGrades.has(id))
      )
      .map((group) => group.id);
  }, [productionPlans, productGroups, chemistryGroups]);
