import { useTenant } from "hooks/settings";
import { Navigate, useParams } from "react-router-dom";
import { useListSearchesQuery } from "src/store/api/generatedApi";

type BodyProps = {
  sessionId: number;
  tenantName: string;
};

const Body = ({ sessionId, tenantName }: BodyProps) => {
  const lastestSearch = useListSearchesQuery({
    sessionId,
    tenantName,
    limit: 1,
    ordering: "desc",
  });
  if (lastestSearch.data) {
    if (lastestSearch.data[0]) {
      return (
        <Navigate
          to={`/${tenantName}/v3/search?search=${
            lastestSearch.data[0].search_id
          }&period=${1}`}
        />
      );
    } else {
      return <Navigate to={`/${tenantName}/v3`} />;
    }
  }
  return null;
};

export const SessionRedirect = () => {
  const params = useParams<{ session_id: string }>();
  const tenantName = useTenant();

  if (params.session_id && !isNaN(Number(params.session_id))) {
    return (
      <Body sessionId={Number(params.session_id)} tenantName={tenantName} />
    );
  } else {
    return <Navigate to={`/${tenantName}/v3`} />;
  }
};
