import { Box, Toolbar } from "@mui/material";
import { Navbar } from "./navBar/NavBar";
import { ReactNode } from "react";
import { useAppBarHeight } from "hooks/useAppBarHeight";
import { StaticNavbar } from "./navBar/StaticNavbar";

type Props = {
  children: ReactNode;
  isStatic?: boolean;
};
export const NavBarLayout = ({ children, isStatic = false }: Props) => {
  const appBarHeight = useAppBarHeight();

  return (
    <>
      {isStatic ? <StaticNavbar /> : <Navbar />}
      <Toolbar />
      <Box
        sx={{
          height: `calc(100vh - ${appBarHeight}px)`,
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </>
  );
};
