import { SearchProductGroups } from "contexts/search/context";
import { SyncedProductionPlan } from "src/store/api/generatedApi";

export const productionPlanToSteelGradeIds = (
  productionPlan: SyncedProductionPlan,
  productGroups: SearchProductGroups
) =>
  (productionPlan.steel_grade_items ?? [])
    .filter((item) => item.num_heats > 0)
    .map((item) => item.steel_grade_id)
    .concat(
      (productionPlan.product_group_items ?? [])
        .filter((item) => item.num_heats > 0)
        .flatMap((item) =>
          productGroups.byId[item.product_group_id]!.steel_grades.map(
            ({ id }) => id
          )
        )
    );
