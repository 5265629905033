import { Routes, Route, Navigate, useParams } from "react-router-dom";

import { PlanView } from "components/crane/PlanView";
import { NotFound } from "components/notFound";
import { GradeSelection } from "components/crane/GradeSelection";
import { GradeMaterialsInput } from "components/crane/GradeMaterialsInput";

import { RequireAdmin } from "./authentication";
import { RequireVersionRefresh } from "./dependencies";

import { useTenant } from "hooks/settings";

const SteelGradeRedirect = () => {
  const params = useParams();
  const tenant = useTenant();

  if (params.steel_grade_id) {
    return (
      <Navigate to={`/${tenant}/crane/driver/${params.steel_grade_id}/1`} />
    );
  } else {
    return <Navigate to={`/${tenant}/crane/driver`} />;
  }
};

export const CraneRoutes = () => {
  return (
    <Routes>
      <Route
        path="/driver/*"
        element={
          <RequireVersionRefresh>
            <Routes>
              <Route index element={<GradeSelection />} />
              <Route
                path="/:steel_grade_id/:basket_number"
                element={<GradeMaterialsInput />}
              />
              <Route path="/:steel_grade_id" element={<SteelGradeRedirect />} />
            </Routes>
          </RequireVersionRefresh>
        }
      />

      <Route
        path="/supervisor"
        element={
          <RequireAdmin>
            <PlanView planId={2189} />
          </RequireAdmin>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
