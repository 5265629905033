import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export type VideoControls = {
  frameIndex: number;
  setFrameIndex: (frameIndex: number) => void;
  totalFrames: number;

  // Used to get the current frame asynchronously, in case waiting for the event
  // to update the state is not quick enough
  getFrameIndex: () => number;

  play: () => void;
  pause: () => void;
  toggle: () => void;

  skipForward: () => void;
  skipBackward: () => void;
};

export const useVideoControls = (
  video: HTMLVideoElement | null,
  speed: number,
  downsampled: boolean
): VideoControls | null => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const frameIndex = currentTime * (downsampled ? 15 : 1);
  const setFrameIndex = (newFrameIndex: number) => {
    if (video) {
      video.currentTime = newFrameIndex / (downsampled ? 15 : 1);
    }
  };
  const totalFrames = duration * (downsampled ? 15 : 1);

  const getFrameIndex = () =>
    (video?.currentTime ?? 0) * (downsampled ? 15 : 1);

  // Update the current time and duration as the video plays
  useEffect(() => {
    if (video) {
      const updateProgress = () => setCurrentTime(video.currentTime);
      const updateDuration = () => setDuration(video.duration);

      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("loadedmetadata", updateDuration);

      return () => {
        video.removeEventListener("timeupdate", updateProgress);
        video.removeEventListener("loadedmetadata", updateDuration);
      };
    }
  }, [video]);

  const actualSpeed = speed / (downsampled ? 15 : 1);

  useEffect(() => {
    if (video) {
      video.playbackRate = actualSpeed;
    }
  }, [video, actualSpeed]);

  if (video) {
    return {
      frameIndex,
      setFrameIndex,
      totalFrames,
      getFrameIndex,
      play: () => {
        video.playbackRate = actualSpeed;
        void video.play();
      },
      pause: () => video.pause(),
      toggle: () => {
        if (video.paused) {
          video.playbackRate = actualSpeed;
          void video.play();
        } else {
          video.pause();
        }
      },
      skipForward: () => {
        setFrameIndex(frameIndex + speed);
        video.pause();
      },
      skipBackward: () => {
        setFrameIndex(frameIndex - speed);
        video.pause();
      },
    };
  } else {
    return null;
  }
};

export const JumpToDefaultFrameIndex = ({
  setFrameIndex,
}: {
  setFrameIndex: (frameIndex: number) => void;
}) => {
  // Note that this is not updated while the video plays
  const defaultFrameIndex = parseFloat(
    useSearchParams()[0].get("frame-index") ?? ""
  );

  useEffect(() => {
    if (!isNaN(defaultFrameIndex)) {
      setFrameIndex(defaultFrameIndex);
    }
  }, [defaultFrameIndex]);

  return null;
};
