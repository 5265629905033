import React from "react";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import {
  Alert,
  Box,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";

import { useTenant } from "hooks/settings";

import {
  DropWeight,
  useGetMaterialPricesQuery,
  CustomerBucketId,
  useListCustomerPublishedChargeScheduleLayersRecentQuery,
  CustomerPublishedChargeScheduleLayer,
  useGetMaterialNameMappingsQuery,
  useListChargeBucketDropWeightsRecentQuery,
} from "src/store/api/generatedApi";

const ignoreMaterialIds_ = new Set<number>([38]);
const benchmarkMaterialName_ = "Shredded";

type Props = {
  id: CustomerBucketId;
  rowHeight: number;
  headerHeight: number;
  pollingIntervalMS: number;
  sx?: SxProps<Theme>;
};

const defaultSxProp = {};

const LoadingBucket = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="3fr 2fr 2fr 4fr 4fr"
      sx={{ columnGap: 1 }}
    >
      <Box display="flex" gap={1} alignItems="flex-end" gridColumn="1/-1">
        <Skeleton variant="text">
          <Typography variant="h2">Bucket 1</Typography>
        </Skeleton>
        <Skeleton variant="text">
          <Typography variant="subtitle1">C928A</Typography>
        </Skeleton>
      </Box>
      <Skeleton width="100%" height={60} sx={{ gridColumn: "1/-1" }} />
      {new Array(15).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={60} key={index} />
      ))}
    </Box>
  );
};

export const Bucket = ({
  id,
  rowHeight,
  headerHeight,
  pollingIntervalMS,
  sx = defaultSxProp,
}: Props) => {
  const tenantName = useTenant();
  const prices = useGetMaterialPricesQuery({ tenantName });
  const dropWeights = useListChargeBucketDropWeightsRecentQuery(
    {
      tenantName,
      chargeBucketId: id,
    },
    { pollingInterval: pollingIntervalMS <= 5000 ? 5000 : pollingIntervalMS }
  );
  const nameMapping = useGetMaterialNameMappingsQuery({ tenantName });

  if (
    dropWeights.data &&
    prices.data &&
    nameMapping.data &&
    dropWeights.data[0]?.customer_charge_schedule_id &&
    dropWeights.data[0].customer_charge_schedule_bucket_number !== null
  ) {
    const customerChargeScheduleId =
      dropWeights.data[0].customer_charge_schedule_id;
    const bucketNumber =
      dropWeights.data[0].customer_charge_schedule_bucket_number;
    const mappedMaterialPrices: Record<string, number> =
      Object.fromEntries<number>(
        Object.entries(prices.data).map((entry) => {
          const name = nameMapping?.data?.[entry[0]] ?? entry[0]!;
          return [name, entry[1]] as const;
        })
      );
    return (
      <LayeringWrapper
        sx={sx}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        customerChargeScheduleId={customerChargeScheduleId}
        dropWeights={dropWeights.data}
        bucketNumber={bucketNumber}
        ignoreMaterialIds={ignoreMaterialIds_}
        benchmarkMaterialName={benchmarkMaterialName_}
        materialPrices={mappedMaterialPrices}
      />
    );
  } else {
    return <LoadingBucket />;
  }
};

type LayeringWrapperProps = {
  customerChargeScheduleId: string;
  dropWeights: DropWeight[];
  bucketNumber: number;
  ignoreMaterialIds: Set<number>;
  benchmarkMaterialName: string;
  materialPrices: Record<string, number>;
  rowHeight: number;
  headerHeight: number;
  sx: SxProps<Theme>;
};

const LayeringWrapper = ({
  customerChargeScheduleId,
  dropWeights,
  bucketNumber,
  ignoreMaterialIds,
  benchmarkMaterialName,
  materialPrices,
  rowHeight,
  headerHeight,
  sx,
}: LayeringWrapperProps) => {
  const tenantName = useTenant();

  const layers = useListCustomerPublishedChargeScheduleLayersRecentQuery({
    tenantName,
    customerChargeScheduleId,
  });

  if (layers.error && "status" in layers.error && layers.error.status === 404) {
    return (
      <Alert severity="warning" sx={{ margin: "24px auto" }}>
        The charge schedule <strong>{customerChargeScheduleId}</strong> for{" "}
        <strong>Bucket {bucketNumber}</strong> has no score data available,
        scoring will not be done.
      </Alert>
    );
  } else if (layers.error) {
    <Alert severity="error" sx={{ margin: "24px auto" }}>
      Something went wrong
    </Alert>;
  } else if (layers.data) {
    return (
      <Body
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        dropWeights={dropWeights}
        bucketNumber={bucketNumber}
        ignoreMaterialIds={ignoreMaterialIds}
        benchmarkMaterialName={benchmarkMaterialName}
        materialPrices={materialPrices}
        layers={layers.data}
        customerChargeScheduleId={customerChargeScheduleId}
        sx={sx}
      />
    );
  } else {
    return <LoadingBucket />;
  }
};

type BodyProps = {
  dropWeights: DropWeight[];
  bucketNumber: number;
  ignoreMaterialIds: Set<number>;
  benchmarkMaterialName: string;
  materialPrices: Record<string, number>;
  layers: CustomerPublishedChargeScheduleLayer[];
  customerChargeScheduleId: string;
  rowHeight: number;
  headerHeight: number;
  sx: SxProps<Theme>;
};

const getBenchmarkPrice = (
  materialPrices: Record<string, number>,
  benchmarkMaterialName: string
) => {
  const benchmarkMaterialPrice = materialPrices[benchmarkMaterialName];
  if (benchmarkMaterialPrice) {
    return benchmarkMaterialPrice;
  } else {
    throw new Error(
      `Material for benchmark material name ${benchmarkMaterialName} not found`
    );
  }
};

const selectMaterialWeight = (
  dropWeights: DropWeight[],
  materialId: number,
  weightLimit: number
) => {
  return dropWeights
    .filter((dropWeight) => dropWeight.material_id === materialId)
    .filter((dropWeight) => dropWeight.drop_weight <= weightLimit)
    .reduce((total, { drop_weight }) => total + drop_weight, 0);
};

const getSaving = (
  benchmarkMaterialName: string,
  materialPrices: Record<string, number>,
  materialName: string
) => {
  const benchmarkPrice = getBenchmarkPrice(
    materialPrices,
    benchmarkMaterialName
  );
  const materialPrice = materialPrices[materialName];
  if (!materialPrice) {
    throw new Error(`Material price not found for ${materialName}`);
  } else {
    return Math.max(0, benchmarkPrice - materialPrice);
  }
};

const getLbsSaving = (
  benchmarkMaterialName: string,
  materialPrices: Record<string, number>,
  materialName: string
) => {
  return getSaving(benchmarkMaterialName, materialPrices, materialName) / 2000;
};

type BarCellProps = {
  theme: "success" | "warning";
  maximum: number;
  current: number;
};

const BarCell = ({ maximum, current, theme }: BarCellProps) => {
  const appTheme = useTheme();
  const color: string = (() => {
    switch (theme) {
      case "success": {
        return appTheme.palette.success.light;
      }
      case "warning": {
        return appTheme.palette.error.light;
      }
    }
  })();
  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: `${(1 - current / maximum) * 100}%`,
          transition: "right 300ms",
          backgroundColor: color,
        }}
      />
    </Box>
  );
};

type Row = {
  id: number;
  materialName: string;
  plannedLbs: number;
  actualLbs: number;
  materialMaximumDollarScore: number;
  bucketMaximumDollarScore: number;
};

const columns: GridColDef<Row>[] = [
  {
    field: "materialName",
    headerName: "Material",
    display: "flex",
    flex: 2,
    cellClassName: "material_name",
  },
  {
    field: "plannedLbs",
    headerName: "Plan",
    display: "flex",
    flex: 1,
    cellClassName: "weight",
    valueFormatter: (_: unknown, row: Row) => row.plannedLbs.toFixed(0),
  },
  {
    field: "actualLbs",
    headerName: "Actual",
    display: "flex",
    flex: 1,
    cellClassName: "weight",
    valueFormatter: (_: unknown, row: Row) => row.actualLbs.toFixed(0),
  },
  {
    field: "score_acheived",
    headerName: "Score achieved",
    display: "flex",
    cellClassName: "horizontal_bar",
    flex: 4,
    renderCell: ({
      row: {
        materialMaximumDollarScore,
        bucketMaximumDollarScore,
        actualLbs,
        plannedLbs,
      },
    }) => {
      return (
        <BarCell
          theme="success"
          maximum={bucketMaximumDollarScore}
          current={
            materialMaximumDollarScore * Math.min(1, actualLbs / plannedLbs)
          }
        />
      );
    },
  },
  {
    field: "score_outstanding",
    headerName: "Score remaining",
    display: "flex",
    cellClassName: "horizontal_bar",
    flex: 4,
    renderCell: ({
      row: {
        materialMaximumDollarScore,
        bucketMaximumDollarScore,
        actualLbs,
        plannedLbs,
      },
    }) => {
      return (
        <BarCell
          theme="warning"
          maximum={bucketMaximumDollarScore}
          current={
            materialMaximumDollarScore * Math.max(0, 1 - actualLbs / plannedLbs)
          }
        />
      );
    },
  },
];

const aggregateLayers = (layers: CustomerPublishedChargeScheduleLayer[]) => {
  return Object.values(
    layers.reduce(
      (aggregatedLayers, layer) => {
        if (aggregatedLayers[layer.customer_material_id]) {
          return {
            ...aggregatedLayers,
            [layer.customer_material_id]: {
              ...aggregatedLayers[layer.customer_material_id]!,
              mass_lbs:
                aggregatedLayers[layer.customer_material_id]!.mass_lbs +
                layer.mass_lbs,
            },
          };
        } else {
          return {
            ...aggregatedLayers,
            [layer.customer_material_id]: {
              ...layer,
            },
          };
        }
      },
      {} as Record<number, CustomerPublishedChargeScheduleLayer>
    )
  );
};

const buildRows = (
  layers: CustomerPublishedChargeScheduleLayer[],
  materialPrices: Record<string, number>,
  dropWeights: DropWeight[],
  ignoreMaterialIds: Set<number>,
  benchmarkMaterialName: string,
  bucketNumber: number,
  weightLimit: number
): Row[] => {
  return aggregateLayers(
    layers.filter(
      (layer) =>
        !ignoreMaterialIds.has(layer.customer_material_id) &&
        layer.basket_num === bucketNumber
    )
  )
    .map((layer, _, all) => {
      const materialMaximumDollarScore =
        getLbsSaving(
          benchmarkMaterialName,
          materialPrices,
          layer.customer_material_name
        ) * layer.mass_lbs;
      const bucketMaximumDollarScore = Math.max(
        ...all.map(
          (layer) =>
            getLbsSaving(
              benchmarkMaterialName,
              materialPrices,
              layer.customer_material_name
            ) * layer.mass_lbs
        )
      );
      return {
        id: layer.customer_material_id,
        materialName: layer.customer_material_name,
        plannedLbs: layer.mass_lbs,
        actualLbs:
          selectMaterialWeight(
            dropWeights,
            layer.customer_material_id,
            weightLimit
          ) * 2000,
        materialMaximumDollarScore,
        bucketMaximumDollarScore,
      };
    })
    .sort(
      (a, b) => b.materialMaximumDollarScore - a.materialMaximumDollarScore
    );
};

const getBucketTotalDollarScore = (
  layers: CustomerPublishedChargeScheduleLayer[],
  ignoreMaterialIds: Set<number>,
  bucketNumber: number,
  benchmarkMaterialName: string,
  materialPrices: Record<string, number>
) => {
  return layers
    .filter(
      (layer) =>
        !ignoreMaterialIds.has(layer.customer_material_id) &&
        bucketNumber === layer.basket_num
    )
    .reduce((total, layer) => {
      return (
        total +
        getLbsSaving(
          benchmarkMaterialName,
          materialPrices,
          layer.customer_material_name
        ) *
          layer.mass_lbs
      );
    }, 0);
};

const getBucketCurrentDollarScore = (
  layers: CustomerPublishedChargeScheduleLayer[],
  ignoreMaterialIds: Set<number>,
  dropWeights: DropWeight[],
  bucketNumber: number,
  benchmarkMaterialName: string,
  materialPrices: Record<string, number>
) => {
  return layers
    .filter(
      (layer) =>
        !ignoreMaterialIds.has(layer.customer_material_id) &&
        bucketNumber === layer.basket_num
    )
    .reduce((current, layer) => {
      return (
        current +
        getSaving(
          benchmarkMaterialName,
          materialPrices,
          layer.customer_material_name
        ) *
          Math.min(
            selectMaterialWeight(dropWeights, layer.customer_material_id, 5),
            layer.mass_lbs / 2000
          )
      );
    }, 0);
};

type TotalScoreBarProps = {
  layers: CustomerPublishedChargeScheduleLayer[];
  dropWeights: DropWeight[];
  ignoreMaterialIds: Set<number>;
  bucketNumber: number;
  benchmarkMaterialName: string;
  materialPrices: Record<string, number>;
};

const TotalScoreBar = ({
  layers,
  ignoreMaterialIds,
  dropWeights,
  bucketNumber,
  benchmarkMaterialName,
  materialPrices,
}: TotalScoreBarProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const totalBucketScore = getBucketTotalDollarScore(
    layers,
    ignoreMaterialIds,
    bucketNumber,
    benchmarkMaterialName,
    materialPrices
  );
  const currentBucketScore = getBucketCurrentDollarScore(
    layers,
    ignoreMaterialIds,
    dropWeights,
    bucketNumber,
    benchmarkMaterialName,
    materialPrices
  );
  const progress = currentBucketScore / totalBucketScore;
  const right = `${(1 - progress) * 100}%`;

  const left = `${progress * 100}%`;
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Typography variant="h6">Score</Typography>
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          height: "30px",
          width: "100%",
          outline: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.error.light,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            position: "absolute",
            borderRadius: 1,
            left,
            top: -42,
            transform: "translate(-50%,-0%)",
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
            outline: `1px solid ${theme.palette.grey[400]}`,
            padding: 0.5,
            transition: "left 300ms",
          }}
        >
          ${currentBucketScore.toFixed(0)}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: right,
            backgroundColor: theme.palette.success.light,
            borderRight:
              progress < 1 ? `1px solid ${theme.palette.grey[900]}` : "unset",
            transition: "left 300ms",
          }}
        />
      </Box>
    </Box>
  );
};

export const Body = ({
  dropWeights,
  ignoreMaterialIds,
  bucketNumber,
  benchmarkMaterialName,
  materialPrices,
  layers,
  rowHeight,
  headerHeight,
  customerChargeScheduleId,
  sx,
}: BodyProps) => {
  const rows = buildRows(
    layers,
    materialPrices,
    dropWeights,
    ignoreMaterialIds,
    benchmarkMaterialName,
    bucketNumber,
    5
  );

  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h2" component="span">
        Bucket {bucketNumber}&nbsp;
        <Typography variant="subtitle1" component="span">
          {customerChargeScheduleId}
        </Typography>
      </Typography>
      <TotalScoreBar
        layers={layers}
        dropWeights={dropWeights}
        ignoreMaterialIds={ignoreMaterialIds}
        bucketNumber={bucketNumber}
        benchmarkMaterialName={benchmarkMaterialName}
        materialPrices={materialPrices}
      />
      <DataGridPremium
        columns={columns}
        rows={rows}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        sx={{
          ...sx,
          [".horizontal_bar"]: {
            padding: 0.1,
          },
          [".MuiDataGrid-columnHeaderTitle"]: {
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
          },
          [".material_name"]: {
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
          },
          [".weight"]: {
            fontSize: theme.typography.h5.fontSize,
          },
        }}
      />
    </Box>
  );
};
