import { useParams } from "react-router-dom";
import {
  UploadedFileOutputType,
  useListUploadedFileTypesQuery,
} from "../store/api/generatedApi";
import { mapLoadedUnpack } from "models/loaded";
import { useMaterials } from "contexts/search/provider";
import { useAppSelector } from "src/store/store";

/**
 * Get the current tenant.  Only valid inside a `RequireTenant` component.
 */
export const useTenant = (): string => useParams().tenant!;

/**
 * Get the plan file types allowed for the current tenant.
 *
 * Only valid inside a `RequireTenant` component.
 */
export const useTenantUploadedFileTypes = (
  outputTypes?: UploadedFileOutputType[]
) => {
  const tenant = useTenant();

  return useListUploadedFileTypesQuery({
    tenantName: tenant,
    body: outputTypes ? { items: outputTypes } : null,
  });
};

export const useLanguage = () =>
  useAppSelector((state) => state.settings.locale);

export const useShortLanguage = () =>
  useAppSelector(
    // NOTE: this is a bit of a bodge, and is required because the frontend uses
    // full language codes (eg. `en-US`), whereas the backend only uses their
    // shortened forms (eg. `EN`).
    (state) => state.settings.locale.split("-")[0]!.toUpperCase()
  );

export const useUserUid = () =>
  useAppSelector((state) => state.settings.userUid);

export const useTenantPermissions = () =>
  useAppSelector((state) => state.settings.tenantPermissions);

export const useIsAdmin = () =>
  useAppSelector((state) => state.settings.isAdmin);

export const useUserEmail = () =>
  useAppSelector((state) => state.settings.email);

export const useUsesDri = () => {
  const materials = useMaterials();

  return (
    mapLoadedUnpack(materials, ({ byIndex }) =>
      byIndex.some(
        (material) =>
          material.addition_location === "hopper" &&
          material.material_type === "iron"
      )
    ) ?? false
  );
};
