import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Label } from "./labelling";

export const LabelView = ({
  label,
  deleteLabel,
  seekTimestamp,
}: {
  label: Label;
  deleteLabel: (label: Label) => void;
  seekTimestamp: (timestamp: number) => void;
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Stack
      direction="row"
      alignItems="center"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Typography variant="body1Mono">{JSON.stringify(label)}</Typography>

      {hovered ? (
        <>
          <Button sx={{ height: 2 }} onClick={() => deleteLabel(label)}>
            Delete
          </Button>
          <Button sx={{ height: 2 }} onClick={() => seekTimestamp(label.start)}>
            Start
          </Button>
          <Button sx={{ height: 2 }} onClick={() => seekTimestamp(label.end)}>
            End
          </Button>
        </>
      ) : null}
    </Stack>
  );
};
