import { useTenant } from "hooks/settings";
import { useListAllMaterialsQuery } from "src/store/api/generatedApi";

export const useMaterialNames = () => {
  const tenant = useTenant();
  const { data: materials, isLoading } = useListAllMaterialsQuery({
    tenantName: tenant,
  });

  const loading = isLoading || materials === undefined;

  return [materials?.map((material) => material.name), loading] as const;
};
