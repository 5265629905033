import { Autocomplete, SxProps, TextField } from "@mui/material";
import { useState } from "react";

/**
 * Generate a searchable multiselect box for strings.
 */

const defaultSx: SxProps = {};

export const MultiSelect = <Value extends string>({
  options,
  values,
  label,
  setValues,
  getOptionLabel,
  disabled = false,
  small,
  sx = defaultSx,
}: {
  sx?: SxProps;
  options: Value[];
  values: Value[];
  label: string;
  getOptionLabel?: (option: string) => string;
  disabled?: boolean;
  setValues: (newValues: string[]) => void;
  small?: boolean;
}) => {
  const [inputValues, setInputValues] = useState("");

  const handleChange = (newValues: readonly string[]) => {
    setValues(newValues as string[]);
  };

  const handleInputChange = (newInputValues: string) => {
    setInputValues(newInputValues);
  };

  return (
    <Autocomplete
      sx={sx}
      disabled={disabled}
      options={options}
      value={values}
      onChange={(_, values) => handleChange(values)}
      inputValue={inputValues}
      onInputChange={(_, values) => handleInputChange(values)}
      multiple
      disableCloseOnSelect
      getOptionLabel={getOptionLabel ?? ((option) => option)}
      renderOption={(props, option) => (
        <li {...props}>
          {getOptionLabel === undefined ? option : getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
      size={small ? "small" : undefined}
    />
  );
};
