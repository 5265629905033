import { useServerIds } from "contexts/search/provider";
import { useTenant } from "./settings";
import {
  useUpdateSearchChefGroupBasketMaterialLimitsMutation,
  useUpdateSearchMixMaterialLimitSetMutation,
} from "src/store/api/generatedApi";
import {
  useHaveMixMaterialLimitsMutated,
  useMixMaterialLimitsArray,
} from "src/store/hooks/mixMaterialLimits";
import {
  useChefGroupBasketMaterialLimitsArray,
  useHaveChefGroupBasketMaterialLimitsMutated,
} from "src/store/hooks/chefGroupBasketMaterialLimits";
import React from "react";
import { groupFlattenedChefGroupBasketMaterialLimits } from "src/utils/groupChefGroupBasketMaterialLimitSetCreate";
import { ReduxMixMaterialLimit } from "src/store/slices/mixMaterialLimits";

const buildMixMaterialLimitCreate = (
  mixMaterialLimits: ReduxMixMaterialLimit[]
) => {
  return mixMaterialLimits.map((mixMaterialLimit) => ({
    ...mixMaterialLimit,
    reason: null,
  }));
};

export const useUpdateMaterialLimits = () => {
  const { searchId } = useServerIds();
  const tenantName = useTenant();
  const [
    doUpdateChefGroupBasketMaterialLimitsMutation,
    updateSearchChefGroupBasketMaterialLimitMutation,
  ] = useUpdateSearchChefGroupBasketMaterialLimitsMutation();
  const [
    doUpdateSearchMixMaterialLimitsMutation,
    updateSearchMixMaterialLimitsMutation,
  ] = useUpdateSearchMixMaterialLimitSetMutation();
  const hasMutatedMixMaterials = useHaveMixMaterialLimitsMutated();
  const hasMutatedChefGroupBasketMaterialLimits =
    useHaveChefGroupBasketMaterialLimitsMutated();
  const mixMaterialLimits = useMixMaterialLimitsArray();
  const chefGroupBasketMaterialLimits = useChefGroupBasketMaterialLimitsArray();

  const isLoading =
    updateSearchChefGroupBasketMaterialLimitMutation.isLoading ||
    updateSearchMixMaterialLimitsMutation.isLoading;

  const doUpdateMaterialLimits =
    React.useCallback(async (): Promise<number> => {
      const newSearchId = await (async () => {
        if (searchId !== null) {
          if (
            hasMutatedMixMaterials &&
            hasMutatedChefGroupBasketMaterialLimits
          ) {
            const basketUpdatedSearchId =
              await doUpdateChefGroupBasketMaterialLimitsMutation({
                searchId,
                tenantName,
                chefGroupBasketMaterialLimitSetCreate: {
                  chef_group_basket_material_limits:
                    groupFlattenedChefGroupBasketMaterialLimits(
                      Object.values(chefGroupBasketMaterialLimits)
                    ),
                },
              }).unwrap();
            const newSearchId = await doUpdateSearchMixMaterialLimitsMutation({
              searchId: basketUpdatedSearchId,
              tenantName,
              body: buildMixMaterialLimitCreate(mixMaterialLimits),
            }).unwrap();
            return newSearchId;
          } else if (hasMutatedMixMaterials) {
            const newSearchId = await doUpdateSearchMixMaterialLimitsMutation({
              searchId,
              tenantName,
              body: buildMixMaterialLimitCreate(mixMaterialLimits),
            }).unwrap();
            return newSearchId;
          } else if (hasMutatedChefGroupBasketMaterialLimits) {
            const newSearchId =
              await doUpdateChefGroupBasketMaterialLimitsMutation({
                searchId,
                tenantName,
                chefGroupBasketMaterialLimitSetCreate: {
                  chef_group_basket_material_limits:
                    groupFlattenedChefGroupBasketMaterialLimits(
                      Object.values(chefGroupBasketMaterialLimits)
                    ),
                },
              }).unwrap();
            return newSearchId;
          } else {
            throw new Error(
              "Mix material limits and basket material limits cannot both be null"
            );
          }
        } else {
          throw new Error("Search id from useServerIds is null");
        }
      })();
      return newSearchId;
    }, [
      doUpdateChefGroupBasketMaterialLimitsMutation,
      doUpdateSearchMixMaterialLimitsMutation,
      hasMutatedChefGroupBasketMaterialLimits,
      hasMutatedMixMaterials,
      mixMaterialLimits,
      chefGroupBasketMaterialLimits,
      searchId,
      tenantName,
    ]);

  return [doUpdateMaterialLimits, isLoading] as const;
};
