import { AppBar, Toolbar } from "@mui/material";
import { ScrapChefIcon } from "./ScrapChefIcon";

export const StaticNavbar = () => {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          backgroundColor: "#000000",
        }}
      >
        <ScrapChefIcon />
      </Toolbar>
    </AppBar>
  );
};
