import { useTenant } from "hooks/settings";
import { Loaded, loadedEndpoint, mapLoaded } from "models/loaded";
import { useMemo } from "react";
import {
  SearchSummary,
  SessionSummary,
  useListSearchesQuery,
  useListSessionsQuery,
} from "store/api/generatedApi";

export type SearchHistory = {
  /**
   * A top-level search that will appear in the change history without needing
   * to expand anything.  This is any search that either has a result, or has no
   * children.
   */
  leaf: SearchSummary;

  /**
   * A list of searches that you can trace back from one leaf to the next.  Eg.
   * if the search A has a result, then some changes are made to produce
   * searches B, C, and D, then D is optimised: for the leaf D, the trunk is C
   * and B.
   */
  trunk: SearchSummary[];

  /**
   * The leaf before another leaf.  Eg. in the above example, the root of D is
   * A. Some searches will have no root, because their trunk will just go back
   * to the beginning of time.
   */
  root: SearchSummary | null;
};

type SearchLookup = {
  [id: number]: { search: SearchSummary; hasChildren: boolean };
};

export const useSessions = (): Loaded<SessionSummary[]> =>
  loadedEndpoint(
    useListSessionsQuery(
      { tenantName: useTenant() },
      { refetchOnMountOrArgChange: true }
    )
  );

export const useSessionSearches = (
  sessionId: number
): Loaded<SearchHistory[]> => {
  const searches = loadedEndpoint(
    useListSearchesQuery({
      tenantName: useTenant(),
      sessionId,
      ordering: "desc",
    })
  );

  return useMemo(() => mapLoaded(searches, buildSearchTree), [searches]);
};

const buildSearchTree = (searches: SearchSummary[]): SearchHistory[] => {
  const lookup = buildLookup(searches);
  const leafSearches = searches.filter(
    (s) => s.status !== "not_started" || !lookup[s.search_id]?.hasChildren
  );

  return leafSearches.map((leaf) => buildHistory(leaf, lookup));
};

const buildLookup = (searches: SearchSummary[]): SearchLookup => {
  const childrenLookup: { [id: number]: boolean } = {};

  searches.forEach((search) => {
    if (search.parent_search_id != null) {
      childrenLookup[search.parent_search_id] = true;
    }
  });

  return Object.fromEntries(
    searches.map((search) => [
      search.search_id,
      {
        search,
        hasChildren: Boolean(childrenLookup[search.search_id]),
      },
    ])
  );
};

const buildHistory = (
  leaf: SearchSummary,
  lookup: SearchLookup
): SearchHistory => {
  const trunk: SearchSummary[] = [];

  let current: SearchSummary | null =
    leaf.parent_search_id == null
      ? null
      : lookup[leaf.parent_search_id]?.search ?? null;

  while (current != null && current.status === "not_started") {
    trunk.push(current);
    current =
      current.parent_search_id == null
        ? null
        : lookup[current.parent_search_id]?.search ?? null;
  }

  return { leaf, trunk, root: current };
};
