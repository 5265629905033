import { Stack } from "@mui/material";
import { useTenantTranslation } from "hooks/formatters";
import { SummaryStat } from "src/components/common/summaryStat";
import { PeriodMaterialSummary } from "store/api/generatedApi";

type Props = {
  summary?: PeriodMaterialSummary;
};

export const Overview = ({ summary }: Props) => {
  const { t } = useTenantTranslation();

  const {
    cost_per_tonne_material,
    initial_inventory,
    mass_consumed,
    final_inventory,
  } = (() => {
    if (summary) {
      return summary;
    } else {
      return {
        cost_per_tonne_material: null,
        initial_inventory: null,
        mass_consumed: null,
        final_inventory: null,
      };
    }
  })();

  return (
    <Stack direction="row" spacing={4} sx={{ pt: 2, pb: 1 }}>
      {cost_per_tonne_material !== null ? (
        <SummaryStat
          label={t("price")}
          value={cost_per_tonne_material}
          decimalPlaces={2}
          units="specific_cost"
        />
      ) : null}
      <SummaryStat
        label={`${t("initialInventory")}`}
        value={initial_inventory}
        decimalPlaces={0}
        units="mass"
      />
      <SummaryStat
        label={`${t("consumption")}`}
        value={mass_consumed}
        decimalPlaces={0}
        units="mass"
      />
      <SummaryStat
        label={`${t("inventoryRemaining")}`}
        value={final_inventory}
        decimalPlaces={0}
        units="mass"
      />
    </Stack>
  );
};
